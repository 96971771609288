import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NormalizedSelection, PdfViewer } from 'react-pdf-selection';
import { IApiCallStatus, IMappedFields } from '../../common/interfaces';
import ApTransactionSidebar from '../../components/apTransactionSidebar';
import { useParams } from 'react-router-dom';
// import { GetData } from '../../common/api';
import { readFileAsDataUrl, getPdfUrl } from '../../common/utility';
// import { PostData } from '../../common/api';

const TemplateDetails = () => {
	const [, setSelection] = useState<NormalizedSelection>();
	const fieldsRef = useRef<{ value: IMappedFields[] }>({ value: [] });
	const params: any = useParams();
	const [editMode] = useState(false);
	const [apiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});

	// for changing api statuses
	// const changeApiStatus = (progress: boolean, message: string) => {
	// 	setApiStatus({
	// 		inProgress: progress,
	// 		failed: !!message,
	// 		failMessage: message
	// 	});
	// };
	useEffect(() => {
		// setFile({});
		// if (params.id !== '0') {
		// 	setEditMode(true);
		// 	(async () => {
		// 		try {
		// 			changeApiStatus(true, '');
		// 			const templateRes = await GetData('templates/get?id=' + params.id);
		// 			if (templateRes.status === 200) {
		// 				changeApiStatus(false, '');
		// 				// setFields(templateRes.data);
		// 			} else {
		// 				throw new Error(templateRes.error);
		// 			}
		// 		}
		// 		catch (error: any) {
		// 			changeApiStatus(false, error.message);
		// 			showNotification('error', error.message);
		// 		}
		// 	})();
		// }
	}, []);

	const setAndLogSelection = useCallback(
		(highlightTip?: NormalizedSelection) => {
			console.log(
				highlightTip
			);

			// setSelection(highlightTip);
			console.log(JSON.stringify(highlightTip?.position));
			if (!highlightTip) {
				return;
			}
			if (newEntry) {
				return;
			}
			const position = highlightTip.position;
			const coords = position.normalized.boundingRect;
			if (fieldsRef.current.value.some(val => val.mappedFieldName === '')) {
				return;
			}
			setFields([...fieldsRef.current.value, { mappedFieldName: '', page: highlightTip.position.pageNumber, positionX: coords.left, positionY: coords.top, width: coords.right - coords.left, height: coords.bottom - coords.top }]);
			setNewEntry(true);
		},
		[setSelection],
	);
	const [fields, setFields] = useState<IMappedFields[]>([]);
	const [newEntry, setNewEntry] = useState(false);
	const [file, setFile] = useState<{ blob: any, url: string }>({ blob: null, url: '' });
	const onPdfChange = async (event: any) => {
		const [file] = event.target.files;
		let strRes = '';
		if (file.type.includes('image')) {
			strRes = await getPdfUrl(file);
		} else if (file.type.includes('pdf')) {
			strRes = await readFileAsDataUrl(file);
		}
		setFile({ blob: file, url: strRes });
	};
	const listenIncomingChange = (proceed: boolean, rawData: IMappedFields, name: string) => {
		const cloned = [...fields];
		if (proceed) {
			cloned.splice(fields.length - 1, 1, { ...rawData, mappedFieldName: name });
		} else {
			cloned.splice(fields.length - 1, 1);
		}

		setFields(cloned);
		fieldsRef.current.value = cloned;
		setNewEntry(false);
	};
	const removeField = (index: number) => {
		const cloned = [...fields];
		setFields(cloned.splice(index, 1));
	};
	// const commitPdfCode = () => {
	// 	// for pdf upload
	// };
	// const commitFields = () => {

	// }
	useEffect(() => {
		fieldsRef.current.value = fields;

	}, [fields.length]);
	const onAnchorChange = async (value: string, coords: any, index: number) => {
		// try {
		// const formData = new FormData();
		// formData.append('file', file.blob);
		// formData.append('anchorName', value);
		// const anchorResponse = await PostData('utility/vision/imageOCR', formData);
		// if (anchorResponse.status === 200) {
		// const {height, positionX, positionY, width} = anchorResponse.data;
		const clonedFields = [...fields];
		clonedFields[index].anchorName = value;
		clonedFields[index].anchorCoordinates = coords;
		// const newField = {
		// 	mappedFieldName: 'Anchor field',
		// 	positionX: positionX,
		// 	positionY: positionY,
		// 	width: width,
		// 	height: height,
		// 	page: 1,
		// };
		setFields([...clonedFields]);

		// 	} else {
		// 		throw new Error(anchorResponse.error);
		// 	}
		// }
		// catch (error: any) {
		// 	showNotification('error', error.message);
		// }
	};
	return apiStatus.inProgress ? <div>Loading</div> : <div className="row">
		<div className="col-md-8">

			{!editMode && <div className="pdf-main-container">
				<div className="pdf-preview" hidden={!file.url}>

					<PdfViewer
						url={file.url}
						selections={
							// [{'text':'','position':{'pageNumber':1,'boundingRect':{'left':9.132798643596216,'top':27.17592716217041,'right':94.31798173566905,'bottom':40.601853088096334},'rects':[{'left':9.132798643596216,'top':27.17592716217041,'right':94.31798173566905,'bottom':40.601853088096334}]}}]
							fields.map(val => {
								return {
									text: '',
									position: {
										pageNumber: val.page,
										boundingRect: { left: val.positionX, top: val.positionY, right: val.width + val.positionX, bottom: val.height + val.positionY },
										rects: [{ left: val.positionX, top: val.positionY, right: val.width + val.positionX, bottom: val.height + val.positionY }]
									}
								};
							})

						}
						enableAreaSelection={useCallback(() => true, [true])}
						scale={1}
						onAreaSelection={setAndLogSelection}
					>
						{({ document }) =>
							<div>
								<div className="apTransaction-pdf">{document}</div>
							</div>
						}
					</PdfViewer>;
				</div>
				{!file.url && <div className="pdf-uploader">
					<label htmlFor="up-file">Select Pdf or image</label>
					<input type="file" id="up-file" accept="application/pdf, image/*" onChange={onPdfChange} />
				</div>}
			</div>}
		</div>
		<div className="col-md-4">
			{(!editMode || (editMode && file.url)) && <ApTransactionSidebar file={file.blob} anchorChange={onAnchorChange} templateTypeId={params.type} supplierId={params.parent} onChange={listenIncomingChange} onDelete={removeField} fields={fields} newField={newEntry} />}

		</div>
	</div>;
};
export default TemplateDetails;