import React, { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
// import Pagination from '../../components/Pagination';
import { IApiCallStatus, IMappedFields } from '../../common/interfaces';

import { useParams, useHistory } from 'react-router-dom';
import { showNotification } from '../../common/utility';
import { DeleteData, GetData } from '../../common/api';
import { Button } from '@material-ui/core';
import InplaceConfirm from '../../components/InplaceConfirm';
// import { Button } from '@material-ui/core';


const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
const TemplateCoordinates = () => {
	const [statements, setStatements] = useState<{
        templateName: string,
        coordinates: IMappedFields[],
        _id: string
    }>({
    	templateName: '',
    	coordinates: [],
    	_id: ''
    });

	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	useEffect(() => {
		fetchData();

	}, []);
	const params: any = useParams();
	const history = useHistory();
	const fetchData = async () => {
		if (params.id !== '0') {
			{
				try {
					changeApiStatus(true, '');
					const statemnts = await GetData('templates/get?id=' + params.id);
					if (statemnts.status === 200) {
						changeApiStatus(false, '');
						setStatements(statemnts.data);
					} else {
						throw new Error(statemnts.error);
					}
				}
				catch (err: any) {
					changeApiStatus(false, err.message);
					showNotification('error', err.message);
				}
			}
		}
	};

	const classes = useStyles();

	// const handleSearch = (event: any) => {
	// 	const value = event.target.value;
	// 	if(distinctUntilChange) {
	// 		clearTimeout(distinctUntilChange);
	// 		distinctUntilChange = null;
	// 	}
	// 	distinctUntilChange = setTimeout(() => {
	// 		setQuery(value);
	// 		currentPagination = 1;
	// 		setPage({ ...page, current: currentPagination });
	// 		fetchData(1, value.trim());
	// 	}, DEBOUNCE_TIME);

	// };
	// const onPageChange = (e: any, currentPage: any) => {
	// 	currentPagination = currentPage;
	// 	setPage({ ...page, current: currentPagination });
	// 	// if (currentPage * page.pageSize > statements.length) {
	// 	// 	if (statements.length > (currentPage - 1) * page.pageSize) {
	// 	// 		return;
	// 	// 	}
	// 	// 	// setFetchCount(currentPage * page.pageSize + 90);
	// 	// }
	// 	fetchData(currentPage);
	// };
	const handleDelete = async () => {
		try {
			changeApiStatus(true, '');
			const userDetailsDelete = await DeleteData('templates/delete', params.id);
			if (userDetailsDelete.status === 200) {
				showNotification('success', userDetailsDelete.message);
				history.push('/user/suppliers/');
			} else {
				throw new Error(userDetailsDelete.error);
			}
		}
		catch (error: any) {
			// error found
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
	};
	return <div className="card-main-custom project-container">
		<div className="row header-row">
			<div className="col-md-6 col-lg-6 pl">
				<h2 className="projects-name-count">

                    Template: {statements.templateName}
					{/* <span>
							{
								`Total ${page.totalItems}`
							}
						</span> */}
				</h2>
			</div>
			{/* <div className="col-4 col-md-3 col-lg-3" style={{ textAlign: 'end' }}>
					<Button onClick={() => history.push('/user/templates/0')} disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.addButton }}>
                        Add
					</Button>
				</div> */}
			<div className="col-8 col-md-3 col-lg-3 search-container">
				<InplaceConfirm
					target={'tempDel'}
					Action={() => handleDelete()}
					HTMLComponent={<Button disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.addButton }}>
                    Delete &nbsp; <i className="fa fa-trash text-white"></i>
					</Button>}

				/>

			</div>

		</div>
		<div className="card-main-content">
			<TableContainer>
				<Table className={classes.table} aria-label="simple table">
					<TableHead classes={{ root: classes.tableHead }}>
						<TableRow>
							{/* <TableCell>Project</TableCell> */}
							<TableCell align="left">S.no</TableCell>
							<TableCell align="center">Field Name</TableCell>
							<TableCell align="center">Position X</TableCell>
							<TableCell align="center">Position Y</TableCell>
							<TableCell align="center">Width</TableCell>
							<TableCell align="center">Height</TableCell>

						</TableRow>
					</TableHead>
					<TableBody>
						{
							apiStatus.inProgress ?
								(<TableRow>
									<TableCell colSpan={6} classes={{ root: classes.tableRow }} >
										<div className="text-center">
                                            fetching...
										</div>
									</TableCell>
								</TableRow>
								) : (
									apiStatus.failed ? (
										<TableRow>
											<TableCell colSpan={4} classes={{ root: classes.tableRow }} >
												<div className="text-center text-danger">
													{
														apiStatus.failMessage
													}
												</div>
											</TableCell>
										</TableRow>
									) : (
										statements.coordinates.length ? (
											statements.coordinates.map((row, index) => (
												<TableRow key={index}>
													<TableCell classes={{ root: classes.tableRow }} align="left">{index + 1}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="center">{row.mappedFieldName}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="center">{row.positionX}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="center">{row.positionY}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="center">{row.width}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="center">{row.height}</TableCell>

												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={6} classes={{ root: classes.tableRow }}>
													<div className="text-center">
                                                        No Data
													</div>
												</TableCell>
											</TableRow>
										)


									)
								)
						}

					</TableBody>
				</Table>
			</TableContainer>
			{/* <div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div> */}
		</div>
	</div>;
};
export default TemplateCoordinates;