import React from 'react';
import { ClientList } from '../../components/client/ClientList';

const Clients = () => {
	return (
		<div>
			<ClientList/>
		</div>
	);
};

export default Clients;