import React from 'react';

export const StepOne = () => {
	return (
		<div className="stepper-content">
			 <label >
			Services

				 <input type="text" />
			 </label>
		</div>
	);
};
