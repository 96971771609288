import React from 'react';

export const StepSix = () => {
	return (
		<div className="stepper-content step-six step-five">
			<p className="heading">Select the applicable</p>
			<ul>
				<li>
					<p>Conclusion</p>
					<span>
						<label>
							<input type="checkbox"/>
							Recommended
						</label>
						<label>
							<input type="checkbox"/>
							Not recommended
						</label>
					</span>
				</li>
				
				<li className='recommded'>
					<p>
						Recommended by
					</p>
					<span>
						<label>
							<input type="text" className="text-type"/>
						</label>
					</span>
				</li>
				<li className='recommded'>
					<p>
					Approved by
					</p>
					<span>
						<label>
							<input type="text" className="text-type"/>
						</label>
					</span>
				</li>
			</ul>
			<div>
			</div>
		</div>
	);
};
