export const cpaAndClientDefaultValues = {
	id: '',
	_id: '',
	cpaId:'', // dropdown
	parentId: '',
	name: '',
	email:'',
	password:'',
	companyName:'',
	companyAddress:'',
	firstName:'',
	lastName:'',
	phoneNumber:'',
	verifyStatus: false,
};