import React, { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IPagination } from '../../common/interfaces';
import { useTranslation } from 'react-i18next';
import { useHistory, Prompt } from 'react-router-dom';
// import { Button } from '@material-ui/core';
import { DeleteData, GetData } from '../../common/api';
import { DEBOUNCE_TIME, dismissNotification, editNotification, showNotification, StandardDateFormat } from '../../common/utility';
import InplaceConfirm from '../../components/InplaceConfirm';
import { uploadFile } from '../../services/fileUpload';
import { Subject } from 'rxjs';


const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;
const StatementList = () => {

	const { t } = useTranslation();
	const history = useHistory();
	const [statements, setStatements] = useState<{
		fileName: string,
		createdAt: string,
		_id: string,
		bank: string
	}[]>([]);

	const [page, setPage] = useState<IPagination>({
		totalItems: statements.length,
		current: 1,
		pageSize: 10,
		pageCount: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	useEffect(() => {
		fetchData();

	}, []);

	const fetchData = async (pageNumber = page.current, filterFileName = querySearch) => {
		try {
			changeApiStatus(true, '');
			const statemnts = await GetData(`statements/getAll?pageNumber=${pageNumber}&pageSize=${page.pageSize}&filter=${filterFileName}`);
			if (statemnts.status === 200) {
				changeApiStatus(false, '');
				setPage({ ...page, totalItems: statemnts.data.totalItems });
				setStatements(statemnts.data.items);
			} else {
				throw new Error(statemnts.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();

	const handleSearch = (event: any) => {
		const value = event.target.value;
		if (distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, value.trim());
		}, DEBOUNCE_TIME);

	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		// if (currentPage * page.pageSize > statements.length) {
		// 	if (statements.length > (currentPage - 1) * page.pageSize) {
		// 		return;
		// 	}
		// 	// setFetchCount(currentPage * page.pageSize + 90);
		// }
		fetchData(currentPage);
	};
	const [deleteInProgress, setDeleteInProgress] = useState(false);
	const handleDelte = async (id: string) => {
		if (apiStatus.inProgress || deleteInProgress) {
			return;
		}
		setDeleteInProgress(true);
		try {
			const delResponse = await DeleteData('statements/delete', id);
			if (delResponse.status === 200) {
				fetchData(page.current);
				showNotification('success', delResponse.message);
				setDeleteInProgress(false);
			} else {
				throw new Error(delResponse.error);
			}
		}
		catch (err: any) {
			setDeleteInProgress(false);
			showNotification('error', err.message);
		}
	};
	const [uploadInProgress, setUploadProgress] = useState(false);
	const [typeSelector, setTypeSelector] = useState('');
	const onChange = async (event: any) => {
		const value = event.target.value;
		setTypeSelector(value);
		if(value) {
			document.getElementById('form-upload')?.click();
		}
	};
	const processFile = (event: any) => {
		const file = event.target.files;
		if (file.length) {
			uploadFileToServer(file, typeSelector);
		}
	};
	const uploadFileToServer = (files: Array<File>, endpoint: string) => {
		const observable = new Subject();
		const notiId = 'file-upload';
		showNotification('info', t("common:start_uploads"), notiId);
		let i = 0;
		observable.subscribe({
			next: () => {
				try {
					setUploadProgress(true);
					uploadFile(endpoint === 'HSBC' ? 'pdf/parse-hsbc' : 'pdf', { file: files[i] })
						.subscribe({
							next: (val: any) => {
								if (val.event === 'load') {
									editNotification(notiId, `Uploading ${val.value}% (${i + 1}/${files.length})`);
								}
								else {
									setUploadProgress(false);
									const data = val.value;
									if (data.status === 200) {
										if (i === files.length - 1) {
											fetchData(page.current);
											dismissNotification(notiId);
											setTypeSelector('');
											showNotification('success', `t("common:upload_process")${files.length}t("common:files")`);
											return;
										}
										i++;
										observable.next(i);
									} else {
										showNotification('error', data.error);
										dismissNotification(notiId);
										setUploadProgress(false);
									}
								}
							}
						});
				}
				catch (err: any) {
					showNotification('error', err.message);
					setUploadProgress(false);
				}
			}
		});
		observable.next(0);
	};
	return (
		<div className="card-main-custom project-container">
			<Prompt
				when={uploadInProgress}
				message="Are you sure you want to leave? The file upload process will continue in background still."
			/>

			<div className="row header-row">
				<div className="col-md-6 col-lg-6 pl">
					<h2 className="projects-name-count">

						Documents Uploaded
						{/* <span>
							{
								`Total ${page.totalItems}`
							}
						</span> */}
					</h2>
				</div>
				<div className="col-4 col-md-3 col-lg-3" style={{ textAlign: 'end' }}>
					<>
						<input accept="application/pdf" multiple={true} disabled={uploadInProgress} type="file" hidden={true} id="form-upload" onChange={processFile} />
						<div className="select-type">
							<select onChange={onChange} value={typeSelector}>
								<option value="">All Documents</option>
								{
									['Bank of China', 'HSBC'].map((val, index) => {
										return <option key={index} value={val}>{val}</option>;
									})
								}
							</select>
						</div>
					</>
				</div>
				<div className="col-8 col-md-3 col-lg-3 search-container">

					<input className="search-bar" type="text" placeholder="Search" onChange={handleSearch} />
					<i className="fas fa-search"></i>

				</div>

			</div>
			<div className="card-main-content">
				<TableContainer>
					<Table className={classes.table} aria-label="simple table">
						<TableHead classes={{ root: classes.tableHead }}>
							<TableRow>
								{/* <TableCell>Project</TableCell> */}
								<TableCell align="left">S.no</TableCell>
								<TableCell align="left">File Name</TableCell>
								<TableCell align="left">Bank</TableCell>
								<TableCell align="left">Added On</TableCell>
								<TableCell align="left">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								apiStatus.inProgress ? (
									<TableRow>
										<TableCell colSpan={5} classes={{ root: classes.tableRow }} >
											<div className="text-center">
												fetching...
											</div>
										</TableCell>
									</TableRow>
								) : (
									apiStatus.failed ? (
										<TableRow>
											<TableCell colSpan={5} classes={{ root: classes.tableRow }} >
												<div className="text-center text-danger">
													{
														apiStatus.failMessage
													}
												</div>
											</TableCell>
										</TableRow>
									) : (
										statements.length ? (
											statements.slice(((page.current - 1) * page.pageSize), page.current * page.pageSize).map((row, index) => (
												<TableRow key={index} onClick={() => { history.push('/user/accounts?statementId=' + row._id); }}>
													{/* <TableCell classes={{ root: classes.tableRow }} component="th" scope="row">
                            {row.name}
                          </TableCell> */}
													<TableCell classes={{ root: classes.tableRow }} align="left">{index + 1}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.fileName}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.bank || 'N/A'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{StandardDateFormat(row.createdAt)}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left" onClick={e => e.stopPropagation()}>
														<InplaceConfirm
															target={'stateDel' + index}
															Action={() => handleDelte(row._id)}
															HTMLComponent={<i className={`${deleteInProgress ? 'fa fa-spinner' : 'fas fa-trash-alt'}`}></i>}
														/>

													</TableCell>
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={6} classes={{ root: classes.tableRow }}>
													<div className="text-center">
														No Data
													</div>
												</TableCell>
											</TableRow>
										)


									)
								)
							}

						</TableBody>
					</Table>
				</TableContainer>
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};
export default StatementList;
