import React from 'react';
import { useTranslation } from 'react-i18next';

const DirectorExcutives = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className=" client-table-container tabs-content-main">
				<h1 className="client-content-heading mb-3 mb-md-5">{t("client_details:title")}</h1>
				<table>
					<thead>
						<tr>
							<th>{t("client_details:id")}</th>
							<th>{t("client_details:full_name")}</th>
							<th>{t("client_details:nationality")}</th>
							<th>{t("client_details:address")}</th>
							<th>{t("client_details:percent")}</th>
							<th>{t("client_details:position_")}</th>
							<th>{t("client_details:date_of_app")}</th>
							<th>{t("client_details:date_of_res")}</th>
							<th></th>{t("client_details:action")}
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="nodata" colSpan={9}> 

								<i className="fas fa-inbox"></i>
								{t("client_details:data")}
							</td>
						</tr>
					</tbody>
				</table>
				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#addDirector" >
						<i className="fas fa-plus"></i>
						{t("client_details:add_director")}
					</button>
					<div className="modal fade" id="addDirector"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">{t("client_details:add")}</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label><i className="fas fa-asterisk red-start"></i>{t("client_details:full_name")}:</label>
										<input type="text" placeholder='Enter Beneficial Owner' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:email")}:</label>
										<input type="text" placeholder='Enter Email' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:phone")}:</label>
										<input type="text" placeholder='Enter Phone' className='border' />
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:id_no")}</label>
										<input type="text" placeholder='Enter Id' className='border' />
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:nationality")}:</label>
										<input type="text" placeholder='Enter Nationality' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:id_no")}</label>
										<input type="text" placeholder='Enter Id ' className='border' />
									</div>
									<div className="d-flex  justify-content-between w-100 mb-4">
										<label>{t("client_details:address")}:</label>
										<div className="textarea">
											<textarea placeholder={t("client_details:enter_message")} className='border' ></textarea>
										</div>
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:select_pos")}:</label>
										<select name="" id="">
											<option value="">{t("client_details:pos_one")}</option>
										</select>
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:ownership")}:</label>
										
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:appointed_on")}:</label>
										<input type="date" className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:resigned_on")}:</label>
										<input type="date" className='border' />
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">{t("client_details:cancel")}</button>
									<button type="button" className="btn btn-primary blue-btn">{t("client_details:add")}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default DirectorExcutives;
