// Here we have added our translations

export default {
	// Form Fields
	field_require: 'Field is required',
	empty: 'Field can not be empty',
	select_option: 'Please select an option!',
	char_length: 'Must be 4 chars long', // Character Length
	select: 'select',
	no_clients: 'No clients available',
	max_length: 'Please enter a value of maxlength 30', // Maximum Length
	min_length: 'Please enter a value of minlength 3', // Minimum Length
	not_found: 'Not Found',
	phone_no: 'Phone Number',
	enter_phone_no: 'Enter Phone Number',
	enable_2fa: 'Enable 2FA',
	send_otp: 'Send OTP',
	otp_mobile: 'OTP will be sent to your mobile number ends with',
	get_otp: 'Get Your OTP',
	verify_otp: 'Verify OTP',
	otp_mobile_verify: 'Enter OTP recieved on mobile number ends with',
	valid_phone_no: 'Enter a valid phone number',
	// Delete
	are_you_sure: 'Are you sure',
	yes: 'Yes',
	no: 'No',

	// Globals
	dev_env: 'Development', // Development Environment
	version: 'Version',
	logout: 'Logout',
	success: 'Success',

	// Table Data
	fetch: 'fetching...',
	no_data: 'No Data',
	
	// Notifications
	file_upload: 'Starting file upload',
	start_uploads: 'Starting uploading your files...',
	upload_logo: 'Please upload logo',
	upload_process: 'Uploaded and processed',
	files: 'files',
	pdf_file: 'Please choose a pdf or image file',

	//Roles
	roles: {
		'FAKEROLE': 'FAKEROLE', // 0
		'SUPERADMIN': 'SUPERADMIN', // 1 Super Admin
		'LEGANCYADMIN': 'LEGANCYADMIN', // 2 User
		'CPAADMIN': 'CPAADMIN', // 3, This is CPA Admin
		'CPAAUDITOR': 'CPAAUDITOR', // 4, This is CPA User
		'CPACLERK': 'CPACLERK', // 5 This is CPA User
		'CLIENTADMIN': 'CLIENTADMIN', // 6 This is CPA Client
		'CLIENTTEAMMEMBER': 'CLIENTTEAMMEMBER', // 7, old user
		'BankAdmin': 'BankAdmin', // 8
		'BankUSER': 'BankUSER', // 9
		'BANKCLIENTADMIN': 'BANKCLIENTADMIN' // 10 This is Bank Client
	}

};
