import React from 'react';
import { Box, Stepper, Button,Step,StepLabel } from '@material-ui/core';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { StepFive } from './StepFive';
import { StepSix } from './StepSix';

const ClientStepper = () =>  {
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());

	const isStepOptional = (step: any) => {
		return step === 1;
	};

	const isStepSkipped = (step : any) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		const newSkipped = skipped;
		// if (isStepSkipped(activeStep)) {
		// 	newSkipped = new Set(newSkipped.values());
		// 	newSkipped.delete(activeStep);
		// }

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	// const handleBack = () => {
	// 	setActiveStep((prevActiveStep) => prevActiveStep - 1);
	// };

	// const handleSkip = () => {
	// 	if (!isStepOptional(activeStep)) {
	// 		// You probably want to guard against something like this,
	// 		// it should never occur unless someone's actively trying to break something.
	// 		throw new Error('You can\'t skip a step that isn\'t optional.');
	// 	}

	// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
	// 	setSkipped((prevSkipped) => {
	// 		const newSkipped = new Set(prevSkipped.values());
	// 		newSkipped.add(activeStep);
	// 		return newSkipped;
	// 	});
	// };

	// const handleReset = () => {
	// 	setActiveStep(0);
	// };
	const steps = [
		'Nature of services to be provided',
		'Customer Risk factors',
		'Geographical Risk Factors',
		'Transaction Risk Factors',
		'Rate the client Risk level and complexity',
		'Conclude on Acceptance'
	  ];

	  const RenderFootComponent=(_activeStep:any)=>{
	  switch(_activeStep){
		case 1:
			return <StepTwo />;
		case 2:
			return <StepThree/>;
		case 3:
			return <StepFour />;
		case 4:
			return <StepFive />;
		case 5:
			return <StepSix/>;
			
		default:
			return <StepOne />;
		}
	};

	return<>
    	<Box sx={{ width: '100%' }}>
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};
					if (isStepOptional(index)) {
						// labelProps.optional = (
						// 	<p>Optional</p>
						// );
					}
					if (isStepSkipped(index)) {
						// stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			{activeStep === steps.length ? (
				<React.Fragment>
					<p>
           					 All steps completed - you&apos;re finished
					</p>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Box sx={{ flex: '1 1 auto' }} />
						{/* <Button onClick={handleReset}>Reset</Button> */}
					</Box>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Box sx={{ display: 'block', flexDirection: 'row', pt: 2 }}>
						{/* <Button
									color="inherit"
									disabled={activeStep === 0}
									onClick={handleBack}
									sx={{ mr: 1 }}
								> */}
             				 {/* Back
								</Button>
								<Box sx={{ flex: '1 1 auto' }} />
								{isStepOptional(activeStep) && (
									<Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
									</Button>
								)} */}

						<div className="finishBtn" >

							<Button onClick={handleNext} className="finishBtn">
								{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
							</Button>
						</div>
					</Box>
					{
						RenderFootComponent(activeStep)
					}
				</React.Fragment>
			)}
		</Box>
	</>;
};


export default ClientStepper;
