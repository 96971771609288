import React, { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IPagination } from '../../common/interfaces';

import { GetData } from '../../common/api';
import { showNotification, StandardDateFormat, DEBOUNCE_TIME } from '../../common/utility';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';


const useStyles = makeStyles({
	table: {
		minWidth: '100%!important',
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;
const TemplateTypes = () => {

	const [statements, setStatements] = useState<{
        title: string,
        fields: string[],
        _id: string,
        createdAt: string
	}[]>([]);

	const [page, setPage] = useState<IPagination>({
		totalItems: statements.length,
		current: 1,
		pageSize: 10,
		pageCount: 0,
		total: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	const location = useLocation();
	useEffect(() => {
		fetchData();

	}, [location.search]);
	const fetchData = async (pageNumber = page.current, summary = querySearch) => {
		try {
			changeApiStatus(true, '');

			const statemnts = await GetData(`templateTypes/getAll?pageNumber=${pageNumber}&pageSize=${page.pageSize}&filter=${summary}`);
			if (statemnts.status === 200) {
				changeApiStatus(false, '');
				setPage({ ...page, totalItems: statemnts.data.totalItems, total: statemnts.data.total });
				setStatements(statemnts.data.items);
			} else {
				throw new Error(statemnts.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();

	const handleSearch = (event: any) => {
		const value = event.target.value;

		if (distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, value.trim());
		}, DEBOUNCE_TIME);
	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		fetchData(currentPage);
	};
	const history = useHistory();
	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-4 col-md-4 col-lg-4 pl">
					<h2 className="projects-name-count">

						Template Types
						{/* <span>
							{
								`Total $${page.total}`
							}
						</span> */}
					</h2>
				</div>
				<div className="col-3 col-md-3 col-lg-3 search-container">

					<input className="search-bar" type="text" placeholder="Search" onChange={handleSearch} />
					<i className="fas fa-search"></i>

				</div>
				<div className="col-2 col-md-2 col-lg-2" style={{ textAlign: 'end' }}>
					<Button onClick={() =>  history.push('/user/template-types-details/0')} disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.addButton }}>
                        Add
					</Button>
				</div>

			</div>
			<div className="card-main-content">
				<TableContainer>
					<Table className={classes.table} aria-label="simple table">
						<TableHead classes={{ root: classes.tableHead }}>
							<TableRow>
								{/* <TableCell>Project</TableCell> */}
								<TableCell align="left">S.no</TableCell>
								<TableCell align="left">Title</TableCell>
								<TableCell align="left">Fields</TableCell>
								<TableCell align="left">Added On</TableCell>

							</TableRow>
						</TableHead>
						<TableBody>
							{
								apiStatus.inProgress ? (
									<TableRow>
										<TableCell colSpan={9} classes={{ root: classes.tableRow }} >
											<div className="text-center">
												fetching...
											</div>
										</TableCell>
									</TableRow>
								) : (
									apiStatus.failed ? (
										<TableRow>
											<TableCell colSpan={9} classes={{ root: classes.tableRow }} >
												<div className="text-center text-danger">
													{
														apiStatus.failMessage
													}
												</div>
											</TableCell>
										</TableRow>
									) : (
										statements.length ? (
											statements.slice(((page.current - 1) * page.pageSize), page.current * page.pageSize).map((row, index) => (
												<TableRow key={index} onClick={() => history.push('/user/template-types-details/' + row._id)}>
													{/* <TableCell classes={{ root: classes.tableRow }} component="th" scope="row">
                            {row.name}
                          </TableCell> */}
													<TableCell classes={{ root: classes.tableRow }} align="left">{index + 1}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.title}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.fields.join(', ')}</TableCell>

													<TableCell classes={{ root: classes.tableRow }} align="left">{StandardDateFormat(row.createdAt)}</TableCell>

												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={9} classes={{ root: classes.tableRow }}>
													<div className="text-center">
														No Data
													</div>
												</TableCell>
											</TableRow>
										)


									)
								)
							}

						</TableBody>
					</Table>
				</TableContainer>
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};
export default TemplateTypes;
