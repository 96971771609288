import React, { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IPagination } from '../../common/interfaces';

import { useHistory } from 'react-router-dom';
import { DeleteData, GetData } from '../../common/api';
import { DEBOUNCE_TIME, getSearchQuery, showNotification, StandardDateFormat } from '../../common/utility';
import {useLocation} from 'react-router-dom';
import InplaceConfirm from '../../components/InplaceConfirm';


const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;
const AccountTypeList = () => {

	const history = useHistory();
	const [statements, setStatements] = useState<{
        accountName: string,
        createdAt: string,
        _id: string
    }[]>([]);

	const [page, setPage] = useState<IPagination>({
		totalItems: statements.length,
		current: 1,
		pageSize: 10,
		pageCount: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	useEffect(() => {
		fetchData();

	}, []);
	const location = useLocation();
	const fetchData = async (pageNumber = page.current, accountName = querySearch) => {
		try {
			changeApiStatus(true, '');
			// get id from query
			const query = getSearchQuery(location.search, 'statementId');
			const statemnts = await GetData(`accountType/getAll?pageNumber=${pageNumber}&pageSize=${page.pageSize}&statementId=${query || ''}&filter=${accountName}`);
			if (statemnts.status === 200) {
				changeApiStatus(false, '');
				setPage({...page, totalItems: statemnts.data.totalItems});
				setStatements(statemnts.data.items);
			} else {
				throw new Error(statemnts.error);
			}
		}
		catch(err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();

	const handleSearch = (event: any) => {
		const value = event.target.value;
		if(distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, value.trim());
		}, DEBOUNCE_TIME);

	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		// if (currentPage * page.pageSize > statements.length) {
		// 	if (statements.length > (currentPage - 1) * page.pageSize) {
		// 		return;
		// 	}
		// 	// setFetchCount(currentPage * page.pageSize + 90);
		// }
		fetchData(currentPage);
	};
	const [deleteInProgress, setDeleteInProgress] = useState(false);
	const handleDelte = async (id: string) => {
		if(apiStatus.inProgress || deleteInProgress) {
			return;
		}
		setDeleteInProgress(true);
		try {
			const delResponse = await DeleteData('accountType/delete', id);
			if (delResponse.status === 200) {
				fetchData(page.current);
				showNotification('success', delResponse.message);
				setDeleteInProgress(false);
			} else {
				throw new Error(delResponse.error);
			}
		}
		catch (err: any) {
			setDeleteInProgress(false);
			fetchData(page.current);
			showNotification('error', err.message);
		}
	};
	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-md-6 col-lg-6 pl">
					<h2 className="projects-name-count">

                        Accounts
						{/* <span>
							{
								`Total ${page.totalItems}`
							}
						</span> */}
					</h2>
				</div>
				{/* <div className="col-4 col-md-3 col-lg-3" style={{ textAlign: 'end' }}>
					<Button onClick={() => { handleUpload; }} disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.addButton }}>
                        Upload
					</Button>
				</div> */}
				<div className="col-8 col-md-3 col-lg-3 search-container">

					<input className="search-bar" type="text" placeholder="Search" onChange={handleSearch} />
					<i className="fas fa-search"></i>

				</div>

			</div>
			<div className="card-main-content">
				<TableContainer>
					<Table className={classes.table} aria-label="simple table">
						<TableHead classes={{ root: classes.tableHead }}>
							<TableRow>
								{/* <TableCell>Project</TableCell> */}
								<TableCell align="left">S.no</TableCell>
								<TableCell align="left">Account Name</TableCell>
								<TableCell align="left">Added On</TableCell>
								<TableCell align="left">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								apiStatus.inProgress ?
									(<TableRow>
										<TableCell colSpan={4} classes={{ root: classes.tableRow }} >
											<div className="text-center">
                                                fetching...
											</div>
										</TableCell>
									</TableRow>
									) : (
										apiStatus.failed ? (
											<TableRow>
												<TableCell colSpan={4} classes={{ root: classes.tableRow }} >
													<div className="text-center text-danger">
														{
															apiStatus.failMessage
														}
													</div>
												</TableCell>
											</TableRow>
										) : (
											statements.length ? (
												statements.slice(((page.current - 1) * page.pageSize), page.current * page.pageSize).map((row, index) => (
													<TableRow key={index} onClick={() => { history.push('/user/transactions?accountTypeId=' + row._id); }}>
														{/* <TableCell classes={{ root: classes.tableRow }} component="th" scope="row">
                            {row.name}
                          </TableCell> */}
														<TableCell classes={{ root: classes.tableRow }} align="left">{index + 1}</TableCell>
														<TableCell classes={{ root: classes.tableRow }} align="left">{row.accountName}</TableCell>
														<TableCell classes={{ root: classes.tableRow }} align="left">{StandardDateFormat(row.createdAt)}</TableCell>
														<TableCell classes={{ root: classes.tableRow }} align="left" onClick={e => e.stopPropagation()}>
															<InplaceConfirm
																target={'accDel' + index}
																Action={() => handleDelte(row._id)}
																HTMLComponent={<i className={`${deleteInProgress ? 'fa fa-spinner' : 'fas fa-trash-alt'}`}></i>}
															/>
														</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell colSpan={6} classes={{ root: classes.tableRow }}>
														<div className="text-center">
                                                        No Data
														</div>
													</TableCell>
												</TableRow>
											)


										)
									)
							}

						</TableBody>
					</Table>
				</TableContainer>
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};
export default AccountTypeList;
