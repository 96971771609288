// Here we have added our translations

export default {
	// Form Fields
	field_require: '欄位為必填項',
	empty: '欄位不能為空',
	select_option: '請選擇一個選項!',
	char_length: '必須為四個字符長', // Character Length
	select: '選擇',
	no_clients: '沒有可用的客戶',
	max_length: '請輸入最大長度 30 的值', // Maximum Length
	min_length: '請輸入最小長度 3 的值', // Minimum Length
	not_found: '未找到',
	phone_no: '電話號碼',
	enter_phone_no: '輸入電話號碼',
	enable_2fa: '啟用雙重認證', // Enable Two Factor Authentication
	send_otp: '發送一次性密碼',
	otp_mobile: 'OTP 將會發送至您以以下結尾的手機號碼',
	get_otp: '取得您的一次性密碼',
	verify_otp: '驗證一次性密碼',
	otp_mobile_verify: '輸入手機號碼收到的 OTP 結尾',
	valid_phone_no: '輸入有效的電話號碼',

	// Delete
	are_you_sure: '你確定嗎',
	yes: '是的',
	no: '不',

	// Globals
	dev_env: '開發環境',  // Development Environment ---- Google Translate: 'Development environment'  ***/ English Translation is: "Development" \**	version: '版本',
	logout: '登出',
	success: '成功',

	// Table Data
	fetch: '獲取中...', // fetching... --- Google Translate: 'Retrieving...'
	no_data: '無數據',

	// Notifications
	file_upload: '開始文件上傳',
	start_uploads: '開始上傳您的檔案...',
	upload_logo: '請上傳標誌',
	upload_process: '已上傳並處理',
	files: '文件。',
	pdf_file: '請選擇 pdf 或圖像文件',

	// Roles
	roles: {
		FAKEROLE: '假角色', // 0
		SUPERADMIN: '超級管理員', // 1
		LEGANCYADMIN: '舊版管理員', // 2
		CPAADMIN: '註冊會計師管理員', // 3, This is CPA Admin
		CPAAUDITOR: '註冊會計師審計師', // 4, This is CPA User
		CPACLERK: '會計師事務所職員', // 5 This is CPA User --- Google Translate: 'Accounting Firm Employees'
		CLIENTADMIN: '客戶管理員', // 6 This is CPA Client --- Google Translate: 'Client administrator'
		CLIENTTEAMMEMBER: '客戶團隊成員', // 7, old user
		BankAdmin: '銀行管理員', // 8
		BankUSER: '銀行用戶', // 9
		BANKCLIENTADMIN: '銀行客戶管理員' // 10 This is Bank Client
	}

};
