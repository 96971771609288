import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { IPieChartProps } from '../../common/interfaces';
ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend);

const PieChart = ({ title, labels, values, backgroundColor }: IPieChartProps) => {
	const total = values.reduce((accumulator, val) => {
		return accumulator + val;
	}, 0);
	// console.log(total)
	const data = {
		labels,
		plugins: [ChartDataLabels],
		datasets: [
			{
				label: '',
				active: false,
				data: values,
				backgroundColor: backgroundColor.map(item => `rgba(${item},0.2)`),
				borderColor: backgroundColor.map(item => `rgba(${item},1)`),
				borderWidth: 1
			}
		]
	};

	// Check if data is all 0s; if it is, add dummy data to end with empty label
	data.datasets.forEach(dataset => {
		if (dataset.data.every(el => el === 0)) {
			dataset.backgroundColor = ['rgba(255,255,255,0)'];
			dataset.borderColor = ['rgba(0,0,0,1)'];
			dataset.data = [0, 1];
		}
	});
	return (
		<div style={{marginLeft:'10%', marginBottom:'3%'}}><Pie
			options={{
				maintainAspectRatio: false,
				responsive: true,
				plugins: {
					title: {
						display: true,
						text: title,
						position: 'top',
						align: 'center',
						font: {
							size: 20
						}
					},
					legend: {
						display: true,
						position: 'right',
						align: 'center',
						labels: {
							padding: 10,
							font: {
								size: 16
							}
						}
					},
					datalabels: {
						formatter: function (value: number, context: { dataIndex: any; }) {
							const val = Math.round(((value / total) * 100));
							if(!total && context.dataIndex) {
								return '0%';
							}
							return total ? val + '%' : '';
						},
						font: {
							size: 18
						}
					}
				}
			}}
			height="380px"
			width="380px"
			data={data}
		/></div>
	);
};

export default PieChart;
