// Here we have added our translations

export default {
	// Input Fields
	empty_field: '欄位不能為空',
	max_length: '請輸入最大長度 100 的值',
	min_length: '請輸入最小長度 3 的值',
	email_min_length: '請輸入最小長度 4 的值',
	valid_email: '請輸入有效的電子郵件',
};
