import React, { Component } from 'react';
import { Controller } from 'react-hook-form';
import { IInputProps } from '../common/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';

interface InputCtrlProps extends IInputProps, WithTranslation {}
class InputCtrl extends Component<InputCtrlProps> {
	render() {
		const { t } = this.props;
		const rules: any = {
			required: this.props.required,
			pattern: {
				value: /[A-Za-z0-9]{1,20}/,
				message: t("input_ctrl:empty_field"),
			},
			maxLength: { value: 100, message: this.props.t("input_ctrl:max_length")},
			minLength: { value: 3, message: t("input_ctrl:min_length") },
		};
		if (this.props.type === 'phoneNumber') {
			// Pattern Message
			rules.pattern.value = /^\d{8,11}$/i;
			rules.pattern.message = t("common:valid_phone_no");
			//
		}
		if (this.props.type === 'email') {
			// MinLength
			rules.minLength.value = 4;
			rules.minLength.message = t("input_ctrl:email_min_length");
			//
			// Pattern Check
			rules.pattern.value = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			rules.pattern.message = t("input_ctrl:valid_email");
			//
		}

		if (this.props.type === 'tel') {
			// MinLength
			rules.minLength.value = 10;
			rules.minLength.message = 'validation.digit10';
			//
			// MaxLength
			rules.maxLength.value = 14;
			rules.maxLength.message = 'validation.digit14';
			//
			// Pattern Check
			rules.pattern.value = /[0-9]{10,14}/;
			rules.pattern.message = 'validation.invalidMob';
			//
		}

		if ((this.props.type === 'date')) {
			delete rules.minLength;
			delete rules.maxLength;
		}
		const { id, label, name, float, required, showStar, ctrlClasses, type, placeholder, className, disabled } = this.props;
		return (
			<>
				{!float && <label htmlFor={id}>
					{required && showStar  ? <i className="fas fa-asterisk red-start"></i> : null}
					{label}
				</label>}
				<div className={ctrlClasses ? ctrlClasses : 'controller-outer'}>
					<Controller
						render={({ onChange, onBlur, value }) => (
							float ? (
								<div className="form-floating">
									<input onBlur={onBlur}
										name={name}
										onChange={onChange}
										value={value}
										type={type}
										placeholder={placeholder}
										className={className}
										disabled={disabled}
										id={id}
									/>
									<label>{placeholder}</label>
								</div>
							) : <>
								<input
									id={this.props.id}
									onBlur={onBlur}
									name={this.props.name}
									onChange={onChange}
									value={value}
									type={type}
									placeholder={placeholder}
									className={className}
									disabled={disabled}
								/>
							</>
						)}
						name={name}
						control={this.props.control}
						rules={rules}
					/>
					{this.props.showError(name)}
				</div>
			</>
		);
	}
}

export default withTranslation()(InputCtrl);
