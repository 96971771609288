// Here we have added our translations

export default {
	// Table Headers
	name: '姓名',
	service: '服務',
	pf: 'PF',
	update: '最後更新於',
	kyc: 'KYC/CDD',
	industry: '產業',
	risk: '風險',
	jobs: '最近的工作',
	reminder: '提醒',
	contact: '接觸',
	lead: '帶領', 
	remarks: '評論',
	action: '行動',
	add: '添加',
	add_client: '新增客戶',

	// title
	title: '客戶',
	total: '全部的',
	title_add: '新增客戶端',
	title_edit: '編輯客戶',
	search: '搜尋',

	// Forms Data
	email: '電子郵件',
	contact_person: '聯絡人',
	phone: '電話',
	client_lead: '客戶主管',
	ref_no: '參考編號',
	services: '服務',
	enter_name: '輸入名字',
	enter_email: '輸入電子郵件',
	phone_no: '電話號碼',
	password: '密碼',
	enter_password: '輸入密碼',
	save: '節省',
	cancel: '取消',

	// common
	fetch: '正在獲取...',
	data: '沒有數據',
	all: '全部',

};