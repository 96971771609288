import AccountTypeList from './views/accountTypes/accountTypeList';
import CPAClients from './views/cpa/clients';
import CPAFirms from './views/cpa/cpa';
import UsersList from './views/admin/userList';
import TemplateCoordinates from './views/apTransaction/templateCoordinates';
import TemplateDetails from './views/apTransaction/templateDetails';
import ClientDetails from './views/clients/clientDetails';
import Clients from './views/clients/clients';
import TeamMembers from './views/cpa/teamMembers';
// import TemplateList from './views/apTransaction/templateList';
// import PdfUploadInput from './views/Pdf/PdfUploadInput';
import InProgress from './views/references/InProgress';
import StatementList from './views/statements/statementList';
import SupplierInvoiceParser from './views/supplierInvoiceParser/supplierInvoiceParser';
import SupplierInvoiceParserDetails from './views/supplierInvoiceParser/supplierInvoiceParserDetails';
import ViewInvoice from './views/supplierInvoiceParser/viewSupplierInvoice';
import SupplierDetails from './views/suppliers/supplierDetails';
import SupplierList from './views/suppliers/suppliers';
import TemplateTypesDetails from './views/templateTypes/templateTypeDetails';
import TemplateTypes from './views/templateTypes/templateTypesList';
import TransactionList from './views/transactions.tsx/transactionsList';
import BanksList from './views/banks/bank';
import BankMembers from './views/banks/bankMembers';
import BankClients from './views/banks/clients';
import BankClientDetails from './views/banks/bankClientDetails';
import i18n from 'i18next';

const updateRoutes = () => {
	const newLanguage = localStorage.getItem('selectedLanguage');
	const lang = newLanguage || 'en';
	const adminRoutes = [

		{
			path: '/admin/users',
			component: UsersList,
			exact: true,
			name: lang === 'en' ? 'Team Members' : '團隊成員',
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/admin/cpa/users',
			component: TeamMembers,
			exact: true,
			name: lang === 'en' ? 'CPA Team Members': '註冊會計師團隊成員',
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/admin/cpa-firms',
			component: CPAFirms,
			exact: true,
			name: lang === 'en' ? 'CPA Firms' : '會計師事務所',
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/admin/banks',
			component: BanksList,
			exact: true,
			name: lang === 'en' ? 'Banks': '銀行',
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/admin/bank/users',
			component: BankMembers,
			exact: true,
			name: lang === 'en' ?'Bank Users' : '銀行用戶',
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/admin/clients',
			component: CPAClients,
			exact: true,
			name: lang === 'en' ? 'Clients' : '客戶',
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		}   
	];

	const cpaAdminRoutes = [
		{
			path: '/cpa/users',
			component: TeamMembers,
			exact: true,
			name: lang === 'en' ? 'Team Members' : '團隊成員' ,
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/cpa/clients',
			component: CPAClients,
			exact: true,
			name: lang === 'en' ? 'Clients': '客戶' ,
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		} 
	];
	const bankAdminRoutes = [
		{
			path: '/bank/users',
			component: BankMembers,
			exact: true,
			name: lang === 'en' ? 'Bank Users': '銀行用戶' ,
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/bank/clients',
			component: BankClients,
			exact: true,
			name: lang === 'en' ? 'Clients': '客戶',
			icon: 'fa fa-users',
			hideInSidebar: false,
			disabled: false
		}
	];
	const bankAdminRoute = [
	
		{
			path: '/bank/clients/details/:id/:companyName',
			component: BankClientDetails,
			exact: true,
			name: lang === 'en' ? 'BankClientDetails': '銀行客戶詳情',
			icon: 'fa fa-users',
			hideInSidebar: true,
			disabled: false
		}
	];


	//

	const userRoutes = [
		{
			path: '/user/clients',
			component: Clients,
			exact: true,
			name: lang === 'en' ? 'Clients': '客戶',
			icon: 'fas fa-users',
			hideInSidebar: false,
			disabled: false
		}, 
		{
			path: '/',
			component: InProgress,
			exact: true,
			name: lang === 'en' ? 'Bank Transactions': '銀行交易',
			icon: '',
			hideInSidebar: false,
			disabled: true
		},
		{
			path: '/user/transactions',
			component: TransactionList,
			exact: true,
			name: lang === 'en' ? 'All Transactions' : '所有交易',
			icon: 'fa fa-file-invoice-dollar',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/user/statements',
			component: StatementList,
			exact: true,
			name: lang === 'en' ? 'Documents Uploaded' : '文件已上傳',
			icon: 'fas fa-file-invoice',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/',
			component: InProgress,
			exact: true,
			name: lang === 'en' ? 'Suppliers' : '供應商',
			icon: '',
			hideInSidebar: false,
			disabled: true
		},
		{
			path: '/user/supplier-invoice-parse-v3',
			component: SupplierInvoiceParser,
			exact: true,
			name: lang === 'en' ? 'All Transactions' : '所有交易',
			icon: 'fas fa-receipt',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/user/suppliers',
			component: SupplierList,
			exact: true,
			name: lang === 'en' ? 'All Suppliers' : '所有供應商',
			icon: 'fas fa-truck',
			hideInSidebar: false,
			disabled: false
		},  
		{
			path: '/',
			component: InProgress,
			exact: true,
			name: lang === 'en' ? 'Customers' : '顧客',
			icon: '',
			hideInSidebar: false,
			disabled: true
		},
		{
			path: '/user/supplier-invoice-parser-v2',
			component: SupplierInvoiceParser,
			exact: true,
			name: lang === 'en' ? 'All Transactions' : '所有交易',
			icon: 'fas fa-receipt',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/user/customers',
			component: SupplierList,
			exact: true,
			name: lang === 'en' ? 'All Customers' : '所有客戶',
			icon: 'fas fa-users',
			hideInSidebar: false,
			disabled: false
		},
		{
			path: '/',
			component: InProgress,
			exact: true,
			name: lang === 'en' ? 'Admin' : '行政',
			icon: '',
			hideInSidebar: false,
			disabled: true
		},
		{
			path: '/user/template-types',
			component: TemplateTypes,
			exact: true,
			name: lang === 'en' ? 'Template Types' : '模板類型',
			icon: 'fa fa-envelope',
			hideInSidebar: false,
			disabled: false
		},  
		// {
		// 	path: '/',
		// 	component: InProgress,
		// 	exact: true,
		// 	name: 'Reports',
		// 	icon: '',
		// 	hideInSidebar: false,
		// 	disabled: true
		// },
		// {
		// 	path: '/user/balance-sheet',
		// 	component: InProgress,
		// 	exact: true,
		// 	name: 'Balance Sheet',
		// 	icon: 'fa fa-file-invoice',
		// 	hideInSidebar: false,
		// 	disabled: false
		// },
		// {
		// 	path: '/user/profit-loss',
		// 	component: InProgress,
		// 	exact: true,
		// 	name: 'Profit & Loss',
		// 	icon: 'fa fa-file-invoice',
		// 	hideInSidebar: false,
		// 	disabled: false
		// },
		// {
		// 	path: '/user/ledgers',
		// 	component: InProgress,
		// 	exact: true,
		// 	name: 'Cashflow Statement',
		// 	icon: 'fa fa-file-invoice',
		// 	hideInSidebar: false,
		// 	disabled: false
		// },
		// {
		// 	path: '/user/ledgers',
		// 	component: InProgress,
		// 	exact: true,
		// 	name: 'Ledger Transactions',
		// 	icon: 'fa fa-file-invoice',
		// 	hideInSidebar: false,
		// 	disabled: false
		// }, 
		{
			path: '/user/accounts',
			component: AccountTypeList,
			exact: true,
			name: lang === 'en' ? 'Accounts' : '帳戶',
			icon: 'far fa-file-invoice',
			hideInSidebar: true,
			disabled: false
		},
		{
			path: '/user/templates/details/:id',
			component: TemplateCoordinates,
			exact: true,
			name: lang === 'en' ? 'Templates' : '範本',
			icon: 'fas fa-file-pdf',
			hideInSidebar: true,
			disabled: false
		}, 
		{
			path: '/user/templates/:parent/:type/',
			component: TemplateDetails,
			exact: true,
			name: lang === 'en' ? 'Templates' : '範本',
			icon: 'fas fa-file-pdf',
			hideInSidebar: true,
			disabled: false
		}, 
		// {
		// 	path: '/user/templates',
		// 	component: TemplateList,
		// 	exact: true,
		// 	name: 'Templates',
		// 	icon: 'fa fa-bookmark',
		// 	hideInSidebar: false,
		// },  
		{
			path: '/user/suppliers/:id',
			component: SupplierDetails,
			exact: true,
			name: lang === 'en' ? 'Suppliers' : '供應商',
			icon: 'fas fa-file-pdf',
			hideInSidebar: true,
			disabled: false
		}, 
		{
			path: '/user/template-types-details/:id',
			component: TemplateTypesDetails,
			exact: true,
			name: lang === 'en' ? 'Template Types' : '模板類型',
			icon: 'fas fa-truck',
			hideInSidebar: true,
			disabled: false
		},  
		{
			path: '/user/add-supplier-invoice',
			component: SupplierInvoiceParserDetails,
			exact: true,
			name: lang === 'en' ? 'Supplier Invoice Parser' : '供應商發票解析器',
			icon: 'fas fa-truck',
			hideInSidebar: true,
			disabled: false
		},
		{
			path: '/user/view-supplier-invoice/:id',
			component: ViewInvoice,
			exact: true,
			name: lang === 'en' ? 'Supplier Invoice Parser' : '供應商發票解析器',
			icon: 'fas fa-truck',
			hideInSidebar: true,
			disabled: false
		},
		// {
		// 	path: '/user/pdf',
		// 	component: PdfUploadInput,
		// 	exact: true,
		// 	name: 'Pdf Toolkit',
		// 	icon: 'fas fa-file-pdf',
		// 	hideInSidebar: false,
		// 	disabled: false
		// }, 
		{
			path: '/user/clients/:id',
			component: ClientDetails,
			exact: true,
			name: lang === 'en' ? 'Clients' : '客戶',
			icon: 'fas fa-users',
			hideInSidebar: true,
			disabled: false
		}, 
	];

	return { adminRoutes, userRoutes, cpaAdminRoutes, bankAdminRoutes, bankAdminRoute  };
};

let routes = updateRoutes();

i18n.on('languageChanged', () => {
	routes = updateRoutes();
});

export const getRoutes = () => {
	return routes;
};