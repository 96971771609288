import React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { IApiCallStatus, IPagination_, IPieChartProps } from '../../common/interfaces';
import { getDataWithQuery } from '../../common/api';
import PieChart from '../chartsComponent/pieChart';
// import ScrollToTop from 'react-scroll-to-top';
import Table from '../clientDetailTable/Table';
import { useTranslation } from 'react-i18next';

const ExpenseAnalysis=(props:any)=>{
	const { t } = useTranslation();
	const startDate: any = '';
	const endDate: any = '';
	// console.log('props.selectedOption',props.selectedOption);
	const dateRangeId = props.selectedOption==='12M' ?  '3492e5a1a4e932m2ce48d482':'6289e5b2a4e646f2wv48d4f0';
	// console.log('dateRangeId',dateRangeId);
	// console.log(props.selectedOption);
	const [uploadedTransactions, setUploadedTransactions] = useState<any[]>([]);
	const [chartItems, setChartItems] = useState<any[]>([]);
	
	const [amountChart, setAmountChart] = useState<IPieChartProps>({
		title: '', labels: [], values: [], backgroundColor: []
	});
	const [countChart, setCountChart] = useState<IPieChartProps>({
		title: '', labels: [], values: [], backgroundColor: []
	});
	const [chartSubTitle, setChartSubTitle] = useState<string>('');
    
	const DEFAULT_PAGE_SIZE = 100000;

	const [page, setPage] = useState<IPagination_>({
		totalItems: 0,
		current: 1,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNumber: 0,
		query: ''
	});

	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});

	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};
	const getAllTransaction = (data: any) => {
		return getDataWithQuery('trans/getAll', data);
	};

	const fetchData = async (
		pageNumber = page.current,
		query = page.query ?? '',
		pageSize = page.pageSize,
		downloadPdf = false,
        
		cb?: (data: any) => void
	) => {
		try {
			changeApiStatus(true, '');
			const data = {
				query,
				pageNumber,
				pageSize,
				dateRangeId: dateRangeId || undefined,
				startDate: !dateRangeId && startDate ? startDate : undefined,
				endDate: !dateRangeId && endDate ? endDate : undefined,
				downloadPdf,
				bankClientId: props?.id,

			};
			// console.log(data);
			const usersResponse = await getAllTransaction(data);
			// console.log(usersResponse);
			if (usersResponse.status === 200) {
				setPage({
					...page,
					current: pageNumber,
					totalItems: usersResponse.data.totalItems
				});
				const resData = usersResponse.data;
               
				
				if (resData.amountChart) {
					setAmountChart(resData.amountChart);
				}
				if (resData.countChart) {
					setCountChart(resData.countChart);
				}
				if (resData.chartItems) {
					setChartItems(resData.chartItems);
				}
				if (resData.chartTitle) {
					setChartSubTitle(resData.chartTitle);
				}
				if (cb) {
					cb(usersResponse.data);
				}
				setUploadedTransactions(resData.items);
				changeApiStatus(false, '');
			} else {
				throw new Error(usersResponse.error);
			}
		} catch (error: any) {
			changeApiStatus(false, error.message);
			// showNotification('error', error.message);
		}
	};
	useEffect(() => {
       
		fetchData();
        
	}, [dateRangeId]);
	const chartListColumns = useMemo(
		() => [
			{
				Header: t("tabs_view:cat_name"),
				accessor: 'category'
			},
			{
				Header: t("tabs_view:expense_count"),
				accessor: 'count'

			},
			{
				Header: t("tabs_view:amount"),
				accessor: 'amountValue'
			}
		],
		[]
	);
	return (
		<div className='transation-list ml-5 mx-5  '>
			{ apiStatus.inProgress
				? t("tabs_view:loading")
				: (<>
					<div className='page-body'>
						{/* <ScrollToTop
							smooth
							className='ScrollToTop'
							component={
								<i className='bi bi-arrow-up-circle'></i>
							}
						/> */}
						
						{uploadedTransactions.length
							? <>
								
								<span><h6 className='mt-3'> {t("tabs_view:latest")} {chartSubTitle.split(' ').slice(1).join(' ')}</h6></span>
							</>
							: ''}
						{uploadedTransactions.length ? <PieChart {...amountChart} /> : ''}
						{uploadedTransactions.length ? <PieChart {...countChart} /> : ''}
						<Table columns={chartListColumns} data={chartItems} />
						
						
						<br />
						<br />
                       
					</div>
				</>
				)}
		</div>
	);
    
}; 
export default ExpenseAnalysis;