import React from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	ul: {
		listStyle: 'none',
		padding: 0,
		margin: '1rem 0 2rem 0',
		display: 'flex', 
		float: 'right'
	}
});

const Pagination: React.FC<{
    count: number,
    onChange: any
}> = (props) => {
	const classes = useStyles();
	const { items } = usePagination({
		count: props.count,
		onChange: props.onChange
	});
	return (
		<nav>
			<ul className={classes.ul}>
				{items.map(({ page, type, selected, ...item }, index) => {
					let children;

					if (type === 'start-ellipsis' || type === 'end-ellipsis') {
						children = '…';
					} else if (type === 'page') {
						children = (
							<button
								style={{
									fontWeight: selected ? 'bold' : undefined,
									backgroundColor: selected ? '#243A76' : '#fff',
									color: selected ? '#fff' : '#444',
								}}
								{...item}
							>
								{page}
							</button>
						);
					} else {
						children = (
							<button {...item} className={type === 'previous' ? 'prev' : 'next'}>
								{type === 'previous' ? '<' : '>'}
							</button>
						);
					}
					return (
						<li key={index} style={{ margin: 'auto 0' }}>
							{children}
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

export default Pagination;
