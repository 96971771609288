import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IApiCallStatus } from '../../common/interfaces';
import InputCtrl from '../../controllers/InpuCtrl';
import { makeStyles } from '@material-ui/core/styles';
import InplaceConfirm from '../../components/InplaceConfirm';
import { useParams, useHistory } from 'react-router-dom';
import { showNotification } from '../../common/utility';
import { DeleteData, GetData, PostData } from '../../common/api';
import TemplateList from '../apTransaction/templateList';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	track: {
		background: '#fff',
		border: '2px solid #AAA',
		transform: 'scaleY(1.7) scaleX(1.3)',
		opacity: 1
	},
	cancelButton: {
		width: '151px',
		height: '64px',
		background: '#fff',
		borderRadius: '400px',
		color: '#000',
		border: '1px solid #263238',
		marginLeft: '20px',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			backgroundColor: '#FFF!important'
		}
	},
	saveButton: {
		width: '151px',
		height: '64px',
		background: '#263238',
		borderRadius: '400px'
	}
});

const SupplierDetails = () => {

	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	// controls at which state api is
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});

	// for changing api statuses
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	// react hook form destructing
	const { errors, control, handleSubmit, reset } = useForm({
		mode: 'onBlur'
	});

	const params: any = useParams();
	const [error, setError] = useState<string | boolean>(false);
	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				// check for the id
				if (params.id !== '0') {
					// set edit mode to true
					changeApiStatus(true, '');
					setEditMode(true);
					// run a global try catch and implement the functioning
					const getResult = await GetData('suppliers/get?id=' + params.id);
					if (getResult.status === 200) {
						const { name, templates } = getResult.data;
						reset({ name });
						changeApiStatus(false, '');
						setSelectedTemplates(templates.map((val: any) => { return { label: val.templateName, value: val._id }; }));
					} else {
						throw new Error(getResult.error);
					}
				}
			}
			// check for the errors
			catch (error: any) {
				setError(error.message);
				changeApiStatus(false, error.message);
				showNotification('error', error.message);
			}
		})();
	}, []);
	const showError = (_fieldName: string) => {
		// if error is there show on the screen
		const error = (errors as any)[_fieldName];
		return error ? (
			<div className="error-block">{error.message || t("common:field_require")}</div>
		) : null;
	};
	const [selectedTemplates, setSelectedTemplates] = useState<{
        label: string,
        value: string
    }[]>([]);
	const onSubmit = async (data: any) => {
		// run global try catch and save data via api
		changeApiStatus(true, '');
		try {
			// set id if in editMode
			editMode && (data.id = params.id);
			const supplierSave = await PostData('suppliers/save', { ...data, templates: selectedTemplates.map(val => val.value) });
			if (supplierSave.status === 200) {
				goBack();
				showNotification('success', supplierSave.message);
			} else {
				throw new Error(supplierSave.error);
			}
		}
		catch (error: any) {
			// error found
			setError(error.message);
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}

	};
	const goBack = () => {
		history.push('/user/suppliers');
	};
	const onDelete = async () => {
		// run global try catch and delte resource via api
		try {
			changeApiStatus(true, '');
			const supplierDelete = await DeleteData('suppliers/delete', params.id);
			if (supplierDelete.status === 200) {
				showNotification('success', supplierDelete.message);
				goBack();
			} else {
				throw new Error(supplierDelete.error);
			}
		}
		catch (error: any) {
			// error found
			setError(error.message);
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
	};
	return <>
		<div className="details-component container">
			<div className="details-header mb-5">
				<h2>
                    Supplier Details
				</h2>
				{/* {
					editMode ? (
						<h2 className="id">#{params.id}</h2>

					) : null
				} */}
			</div>
			<div className="detail-controls">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-md-6">
							<InputCtrl
								control={control}
								showError={showError}
								type="text"
								placeholder="Supplier Name"
								name="name"
								required={true}
								className="form-control"
								componentName="login"
								disabled={apiStatus.inProgress}
								float={true}
							/>
						</div>
					</div>

					{
						error ? (
							<div className="text-danger mb-2">
								{error}
							</div>
						) : null
					}

					<div className="row">
						<div className="col-md-11">
							<Button disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.saveButton }}>
                                Save
							</Button>
							<Button disabled={apiStatus.inProgress} type="button" onClick={() => history.goBack()} variant="contained" color="secondary" classes={{ containedSecondary: classes.cancelButton }}>
                                Cancel
							</Button>
						</div>
						{
							editMode ? (
								<div className="col-md-1">

									<InplaceConfirm
										HTMLComponent={<img alt="img" id="del-icon" src="/images/Group 1718.png" />}
										Action={onDelete}
										target="del-icon"
									/>
								</div>


							) : null
						}



					</div>

				</form>
			</div>
		</div>
		{
			editMode && <div className="my-5">
				<hr />
				<TemplateList supplierId={params.id} />
			</div>
		}
	</>;
};

export default SupplierDetails;
