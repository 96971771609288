import React, { Component } from 'react';
import { Controller } from 'react-hook-form';
import { ISelectProps } from '../common/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IISelectProps extends ISelectProps, WithTranslation {}
class SelectCtrl extends Component<IISelectProps> {
	render() {
		const name = this.props.name;
		const rules: any = {
			required: this.props.required,
			pattern: {
				value: /[A-Za-z0-9]{1,20}/,
				message: this.props.t("common:select_option"),
			},
		};

		if (this.props.componentName !== undefined) {
			if (this.props.componentName === 'login') {
				rules.minLength.value = 4;
				rules.minLength.message = this.props.t("common:char_length");
			}
		}

		const { defaultValue, required, showStar, id, label, ctrlClasses } = this.props;

		return (
			<>
				{label && <label htmlFor={id}>
					{required && showStar  ? <i className="fas fa-asterisk red-start"></i> : null}
					{label}
				</label>
				}
				<div className={ctrlClasses ? ctrlClasses : 'controller-outer'}>
					{/* {console.log(this.props.options)} */}
					<Controller
						render={({ onChange, value }) => (
							<>
								{/* {console.log(value, this.props.options)} */}
								<div className="form-floating">
									<select
										className="form-select"
										onChange={onChange}
										value={value}
										defaultValue={defaultValue}
										disabled={this.props.disabled}
									>
										{/* <option value="" selected hidden>{this.props.placeholder}</option> */}
										{this.props.options.map((option, idx) => {
											return (
												<option key={idx} value={option.value}>
													{option.label}
												</option>
											);
										})}
									</select>
									<label>{this.props.placeholder}</label>
								</div>

							</>
						)}
						name={this.props.name}
						control={this.props.control}
						rules={rules}
					/>
					{this.props.showError(name)}
				</div>
			</>
		);
	}
}

export default withTranslation()(SelectCtrl);
