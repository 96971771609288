import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { GetData } from '../common/api';
import { IsLoggedIn, ROLES, UserDataKey, ValueFromUserData } from '../common/utility';

const unprotectedRoutes = [ '/', '/login'];
const strictUnprotectedRoutes = ['/', '/login', '/send'];

const withAuth = (WrappedComponent: any) => {
	return (props: any) => {
		const Router = useHistory();
		const match = useRouteMatch();
		useEffect(() => {
			(async () => {
				const accessToken = IsLoggedIn();
				// if no accessToken was found,then we redirect to '/' page.
				const verifyStatus = ValueFromUserData('verifyStatus');
				const verify2FA = ValueFromUserData('verify2FA');
				const role = ValueFromUserData('role');
				if( verify2FA === true || role === ROLES.admin || verifyStatus === false || verifyStatus === null || verifyStatus === undefined ) {
					if(strictUnprotectedRoutes.includes(match.path) && accessToken) {
						// redirect based on role
						const userRole = ValueFromUserData('role');
						if(userRole === ROLES.admin) {
							Router.replace('/admin');
						} else if([ROLES.CPAAdmin, ROLES.clientAdmin].includes(userRole)) {
							Router.replace('/cpa/');
						} else if(userRole === ROLES.bankAdmin) {
							Router.replace('/bank/');
						}
	
						  else {
							Router.replace('/user');
						}
						return null;
					} 
					else if(unprotectedRoutes.includes(match.path)) {
						return <WrappedComponent {...props} />;
					}
					else if (!accessToken && !unprotectedRoutes.includes(match.path)) {
						Router.replace('/login');
						return null;
					}  else if (accessToken && !unprotectedRoutes.includes(match.path)) {
						// we call the api that verifies the token.
						const verifyTokenRes = await GetData('user/me');
						if (verifyTokenRes.status !== 200) {
							localStorage.removeItem(UserDataKey);
							Router.replace('/');
						}
					}
				} 
				else if (accessToken) {
					Router.replace('/send');
				}
				
				return <WrappedComponent {...props} />;
			})();
		}, []);
		return <WrappedComponent {...props} />;
	};
};

export default withAuth;