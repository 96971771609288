import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IColumn, IFile, IPagination } from '../../common/interfaces';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { DeleteData, getDataWithQuery, PostData } from '../../common/api';
import { DEBOUNCE_TIME, dismissNotification, editNotification, readFileAsDataUrl, showNotification, StandardDateFormat } from '../../common/utility';
import Button from '@material-ui/core/Button/Button';
import { useForm } from 'react-hook-form';
import CustomTable from '../../components/CustomTable';
import CustomDialog from '../../components/CustomDialog';
import InputCtrl from '../../controllers/InpuCtrl';
import { cpaAndClientDefaultValues } from '../../constants';
import InplaceConfirm from '../../components/InplaceConfirm';
import { uploadFile2 } from '../../services/fileUpload';
import { useTranslation } from 'react-i18next';
import ChekboxCtrl from '../../controllers/ChekboxCtrl';
import { countries } from '../../constants/countries';
import SelectCtrl from '../../controllers/SelectCtrl';
const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;

const UsersList = () => {
	const { t } = useTranslation();
	// 1) S No., 2) Company Name, 3) Company Address, 4) Contact Name, 5) Admin Email
	const columns: IColumn[] = [{
		header: t("cpa:s_no"),
		isIndex: true,
		accessor: 'index'
	},
	{
		header: t("cpa:company_name"),
		accessor: 'companyName'
	},
	{
		header:t("cpa:company_address"),
		accessor: 'companyAddress'
	},
	{
		header: t("cpa:admin_name"),
		accessor: 'name'
	},
	{
		header: t("cpa:admin_email"),
		accessor: 'email'
	},
	{
		header: t("cpa:added"),
		accessor: 'createdAt',
		render: (row: any) => StandardDateFormat(row.createdAt)
	}];

	const [verify2Fa, setVerify2Fa] = useState(false);
	const [cpaFirms, setCpaFirms] = useState<{
        name: string,
        email: string,
		phoneNumber: string,
		countryCode: string,
        createdAt: string,
        _id: string;
    }[]>([]);
	const [logo, setLogo] = useState<IFile>({
		blob: null,
		tempId: '',
		fileId: '',
		fileUrl: '',
		fileName: ''
	});
	const [page, setPage] = useState<IPagination>({
		totalItems: cpaFirms.length,
		current: 1,
		pageSize: 10,
		pageCount: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	useEffect(() => {
		fetchData();

	}, []);
	const fetchData = async (pageNumber = page.current, accountName = querySearch) => {
		try {
			changeApiStatus(true, '');
			const statemnts = await getDataWithQuery('user/getAll', {
				pageNumber: pageNumber,
				pageSize: page.pageSize,
				filter: accountName,
				roles: [3]
			});
			if (statemnts.status === 200) {
				changeApiStatus(false, '');
				setPage({ ...page, totalItems: statemnts.data.totalItems });
				const updatedFirms = statemnts.data.items.map((firm: any) => {
					if(firm?.phoneNumber && firm.phoneNumber[2]==='6') {
						const countryCode = firm.phoneNumber.slice(0,3);
						const phoneNumber=firm.phoneNumber.slice(3,firm.phoneNumber.length+1);
						firm.phoneNumber=phoneNumber;
						firm['countryCode']=countryCode;
						return firm;
					}
					else if(firm?.phoneNumber && firm.phoneNumber[2]==='5') {
						const countryCode = firm.phoneNumber.slice(0,4);
						const phoneNumber=firm.phoneNumber.slice(4,firm.phoneNumber.length+1);
						firm.phoneNumber=phoneNumber;
						firm['countryCode']=countryCode;
						return firm;
					}
					else {
						return firm;
					}
				});
				setCpaFirms([...updatedFirms]);
			} else {
				throw new Error(statemnts.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();

	const handleSearch = (event: any) => {
		const value = event.target.value;
		if (distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, value.trim());
		}, DEBOUNCE_TIME);

	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		if (currentPage * page.pageSize > cpaFirms.length) {
			if ((cpaFirms.length > (currentPage - 1) * page.pageSize) && currentPage !== 1) {
				return;
			}
			// setFetchCount(currentPage * page.pageSize + 90);
		}
		fetchData(currentPage);
	};
	const [cpaFirm, setCpaFirm] = useState<{ [x: string]: any; }>();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


	const { errors, control, handleSubmit, reset } = useForm({
		mode: 'onBlur',
		defaultValues: cpaAndClientDefaultValues
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setEditMode(false);
		reset({});
		setCpaFirm({});
		setLogo({
			blob: null,
			tempId: '',
			fileId: '',
			fileUrl: '',
			fileName: ''
		});
	};

	const editDetails = (data: any) => {
		reset({ ...data });
		setCpaFirm(data);
		setLogo({
			blob: null,
			tempId: '',
			fileId: data.logoId || '',
			fileUrl: data.logoFullUrl || '',
			fileName: data.logoOriginalName || ''
		});
		setEditMode(true);
		setVerify2Fa(data.verifyStatus);
		handleClickOpen();
	};

	const showError = (_fieldName: string) => {
		// if error is there show on the screen
		const error = (errors as any)[_fieldName];
		return error ? (
			<div className="error-block">{error.message || t("common:field_require")}</div>
		) : null;
	};

	const onDelete = async () => {
		// run global try catch and save data via api
		changeApiStatus(true, '');
		try {
			const result = await DeleteData('user/delete', cpaFirm?._id || '');
			if (result.status === 200) {
				changeApiStatus(false, '');
				showNotification('success', result.message);
				handleClose();
				setQuery('');
				currentPagination = 1;
				setPage({ ...page, current: currentPagination });
				fetchData(1, '');
			} else {
				throw new Error(result.error);
			}
		}
		catch (error: any) {
			// error found
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
	};

	const onSaveCpa = async (data: any) => {
		// run global try catch and save data via api
		changeApiStatus(true, '');
		try {
			if(data.countryCode && data.phoneNumber) {
				data.phoneNumber = data.countryCode + data.phoneNumber;
			} else data.phoneNumber = '';
			// set id if in editMode
			// editMode && (data.id = params.id);
			// !data.password && (data.password = '');
			const result = await PostData('user/save', { ...data, role: 3, id: cpaFirm?._id || undefined });
			if (result.status === 200) {
				changeApiStatus(false, '');
				handleClose();
				setQuery('');
				currentPagination = 1;
				setPage({ ...page, current: currentPagination });
				fetchData(1, '');
			} else {
				throw new Error(result.error);
			}
		}
		catch (error: any) {
			// error found
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
		setEditMode(false);

	};

	const upload = (file: any, cb: (data: any) => void) => {
		const notificationUid = 'SaveFileUpload';
		try {
			showNotification('info', t("common:file_upload"), notificationUid);
			return uploadFile2(file, (event: any) => {
				const uploadRes = event.data;
				if (event.event === 'load') {
					editNotification(
						notificationUid,
						`Uploading ${event.progressValue}%`,
					);
				} else if (event.event === 'complete') {
					dismissNotification(notificationUid);
					if (uploadRes.status === 200) {
						cb(uploadRes.data);
						return uploadRes.data;
					} else {
						throw new Error(uploadRes.error);
					}
				}
			});
		} catch (err: any) {
			showNotification('error', err.message);
		}
	};

	const onSubmit = async (data: any) => {
		if (!cpaFirm?.logoId && !logo.blob) {
			showNotification('error', t("common:upload_logo"));
			return false;
		}
		if (logo.blob) {
			changeApiStatus(true, '');
			upload(logo.blob, (result: any) => {
				onSaveCpa({ ...data, logoId: result._id });
			});
		} else {
			onSaveCpa({ ...data, logoId: cpaFirm?.logoId });
		}
	};

	const onChangeLogo = async (e: any) => {
		try {
			if (e.target.files && e.target.files.length) {
				const [blob] = e.target.files;
				const valueOf = new Date().valueOf();
				const tempId = '' + valueOf;
				const url = await readFileAsDataUrl(blob);
				setLogo({ tempId: tempId, blob: blob, fileUrl: url, fileName: blob.name, fileId: '' });
			}
		} catch (err: any) {
			console.log(err);
		}
	};

	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-md-6 col-lg-6 pl">
					<h2 className="projects-name-count">

						{t("cpa:title")}
						<span>
							{t("cpa:total")}{`${page.totalItems}`}
						</span>
					</h2>
				</div>
				<div className="col-8 col-md-3 col-lg-3 search-container">

					<input className="search-bar" type="text" placeholder={t("cpa:search")} onChange={handleSearch} />
					<i className="fas fa-search"></i>

				</div>
				<div className="col-4 col-md-3 col-lg-2" style={{ textAlign: 'end' }}>
					<Button type="submit" variant="contained" onClick={handleClickOpen} color="primary" classes={{ containedPrimary: classes.addButton }} data-bs-toggle="modal" data-bs-target="#addClient">
						{t("cpa:add")}
					</Button>
					<CustomDialog
						fullScreen={fullScreen}
						isOpen={open}
						handleClose={handleClose}
						title= {editMode ? t("cpa:title_edit") : t("cpa:title_add")}
						handleSubmit={handleSubmit(onSubmit)}
						actions={[<Button key={'cancel'} disabled={apiStatus.inProgress} autoFocus onClick={handleClose} className="btn btn-secondary bg-no">
							{t("cpa:cancel")}
						</Button>,
						<Button key={'Save'} disabled={apiStatus.inProgress} type="submit" className="btn btn-primary blue-btn" autoFocus>
							{t("cpa:save")}
						</Button>]}  >
						<div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("cpa:company_name")}
									placeholder={t("cpa:enter_company_name")}
									type='text'
									control={control}
									showError={showError}
									name="companyName"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("cpa:company_address")}
									placeholder={t("cpa:enter_company_address")}
									type='text'
									control={control}
									showError={showError}
									name="companyAddress"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<label htmlFor="uploadLogo" className='w-50'><i className="fas fa-asterisk red-start"></i>{t("cpa:logo")}</label>
								<label
									className="btn btn-outline-primary shadow-none text-primary text-center bg-transparent w-50"
									htmlFor='uploadLogo'>
									{logo?.fileName ? logo.fileName : t("cpa:upload_logo")}
								</label>
								<input
									type='file'
									accept='image/*'
									name='logo'
									id='uploadLogo'
									onChange={onChangeLogo}
									hidden
									multiple
								/>
							</div>
							<div className='mb-4'><h5>{t("cpa:admin")}</h5></div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("cpa:first_name")}
									placeholder={t("cpa:enter_first_name")}
									type='text'
									control={control}
									showError={showError}
									name="firstName"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("cpa:last_name")}
									placeholder={t("cpa:enter_last_name")}
									type='text'
									control={control}
									showError={showError}
									name="lastName"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={false}
									ctrlClasses="inp-err-wrap"
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<InputCtrl
									label={t("cpa:email")}
									placeholder={t("cpa:enter_name")}
									type='email'
									control={control}
									showError={showError}
									name="email"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<InputCtrl
									label={t("cpa:password")}
									placeholder={t("cpa:enter_password")}
									type='password'
									control={control}
									showError={showError}
									name="password"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={editMode ? false : true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className='d-flex align-items-center justify-content-between w-100 mb-4'>
								<SelectCtrl
									label={t("bank:country_code")}
									control={control}
									showError={showError}
									placeholder=''
									className="border"
									name="countryCode"
									required={verify2Fa}
									// className="form-control"
									disabled={apiStatus.inProgress}
									options={countries}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("cpa:phone_no")}
									placeholder='XXXXXXXXXXX'
									type='phoneNumber'
									control={control}
									showError={showError}
									name="phoneNumber"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={verify2Fa}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<ChekboxCtrl
									name="verifyStatus"
									control={control}
									label={t("common:enable_2fa")}
									showError={showError}
									className='border'
									required={false}
									disabled={apiStatus.inProgress}
									ctrlClasses="inp-err-wrap"
									onChange={() => setVerify2Fa(!verify2Fa)}
									// showStar
								/>
                        	</div>
						</div>
						{
							editMode ? (
								<div className="col-md-1">

									<InplaceConfirm
										HTMLComponent={<img alt="img" id="del-icon" src="/images/Group 1718.png" />}
										Action={onDelete}
										target="del-icon"
									/>
								</div>


							) : null
						}
					</CustomDialog>
				</div>
			</div>
			<div className="card-main-content">
				<CustomTable columns={columns} rows={cpaFirms} apiStatus={apiStatus} page={page} onRowClick={editDetails} />
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};


export default UsersList;
