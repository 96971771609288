
// import Button from '@material-ui/core/Button/Button';
import Popover from '@material-ui/core/Popover/Popover';
import React from 'react';

export const StepTwo = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event:any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	return (
		<div className="stepper-content">
			<p className="heading">Select the applicable</p>
			<ul>
				<li>
					<label>
						<input type="checkbox"/>
						New customer/client
					</label>
					<div className='popup-main'>
						<span onClick={handleClick} className="hand">
							<i className="fas fa-ellipsis-h"></i>
						</span>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							className="popup-btn"
						>
							<button type='button'><i className="fas fa-pencil-alt"></i>Edit</button>
							<button type='button'><i className='fas fa-trash-alt'></i>Delete</button>
						</Popover>
					</div>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						The company is listed on a stock exchange not subjected to disclosure requirements.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Company have nominee shareholders/Directors or shares in bearer form.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Nominee shareholders represents majority ownership.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						
						Customer with no discernable reason for using the firm’s service.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Legal persons or arrangements are personal asset-holding vehicles.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						
							Many of the clients/customers are foreigners or non-residents.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						The company is in a Cash-intensive businesses.	
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Unaccounted cash transactions to similar type of receipient.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						The proposed directors/partners/shareholders have prior criminal convictions involving fraud or dishonesty.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						
							The customer is a Politically Exposed Person (PEP), their family members or known associates.	
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						The customer/his family members or associates are known to have terrorist, criminal, sanctioned indivudual/entity profile.	
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
							There is adverse news or information related to the company or client or family members of the client or known associates or has been reported to be involved in any adverse activity.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Corporate vehicles that are unnecessarily and unjustifiably complex multi tiered entities/complex shareholding/business structure, given the nature of companys business.	
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Company has experienced frequent or continuous changes in its business structure/Management.	
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Problems obtaining the required information in the relevant form.	
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Unusual Information or suspicious identification documents have been provided that cannot be readily verified.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Reluctant to provide information needed to file reports or fails to proceed with transaction.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>

							Client is a shell company.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
							None
					</label>
				</li>
				<li>
					<button>Add an item</button>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						<strong>Enhanched DD applicable</strong>
					</label>
				</li>
			</ul>
		</div>
	);
};
