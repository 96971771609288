import common from './common';
import login from './login';
import users_list from './users_list';
import team_members from './team_members';
import cpa from './cpa';
import bank from './bank';
import bank_members from './bank_members';
import clients from './clients';
import bank_clients from './bank_clients';
import bank_client_details from './bank_client_details';
import client_list from './client_list';
import client_details from './client_details';
import input_ctrl from './input_ctrl';
import tabs_view from './tabs_view';

export default {
	common,
	login,
	users_list,
	team_members,
	cpa,
	bank,
	bank_members,
	clients,
	bank_clients,
	bank_client_details,
	client_list,
	client_details,
	input_ctrl,
	tabs_view

};
