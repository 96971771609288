import axios from 'axios';
import { BASE_URL as API_BASE, ValueFromUserData } from './utility';

//Axios Interceptor //
// Request
axios.interceptors.request.use(
	function (config) {
		console.log('intercept-req');
		const token = ValueFromUserData('token');

		config.headers['Content-Type'] = 'application/json';

		config.headers.Authorization = `${token}`;

		return config;
	},
	function (err) {
		return Promise.reject(err);
	}
);


export const responseHandler = (response:any) => {
	return  { data: response.data.data, message: response.data.message, error: response.data.error || response.data.message || 'Something went wrong, please try again', status: response.data.status };
};

// GET REQUEST
export const GetData = async (END_POINT: string, id?: any) => {
	let response: any;

	if (id) {
		response = await axios.get<any>(API_BASE + END_POINT, {
			params: { id },
		});
	} else {
		response = await axios.get<any>(API_BASE + END_POINT);
	}

	return { data: response.data.data, message: response.data.message, error: response.data.error, status: response.data.status };
};

// POST REQUEST for file upload
export const fileUpload = async (END_POINT: string, body: any, config?: any) => {
	return await axios.post<any>(API_BASE + END_POINT, body, config);
};

// POST REQUEST
export const PostData = async (END_POINT: string, body: any, config?: any) => {
	console.log(END_POINT, body);
	const response = await axios.post<any>(API_BASE + END_POINT, body, config);

	return { data: response.data.data, message: response.data.message, error: response.data.error, status: response.data.status };
};

//DELETE REQUEST
export const DeleteData = async (END_POINT: string, id: string) => {
	const response = await axios.delete<any>(`${API_BASE}${END_POINT}`, {
		params: { id },
	});
	return { data: response.data.data, message: response.data.message, error: response.data.error, status: response.data.status };
};

export const DeleteDataWithBody = async (END_POINT: string, body: any) => {
	console.log('body', body);

	const response = await axios.delete<any>(`${API_BASE}${END_POINT}`, {
		data: body
	});
	return { data: response.data, message: response.data.message, error: response.data.error, status: response.data.status };
};

// get request with support of query
export const getDataWithQuery = async (END_POINT: string, queries?: any) => {
	let response: any;

	if (queries) {
		response = await axios.get<any>(API_BASE + END_POINT, {
			params: queries,

		});
	} else {
		response = await axios.get<any>(API_BASE + END_POINT);
	}

	return { data: response.data.data, message: response.data.message, error: response.data.error, status: response.data.status };
};