import React, { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IPagination } from '../../common/interfaces';
import InplaceConfirm from '../../components/InplaceConfirm';


import { GetData, DeleteData } from '../../common/api';
import { showNotification, StandardDateFormat, DEBOUNCE_TIME } from '../../common/utility';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { pluck, groupBy, mapObject, find } from 'underscore';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;
let templatesArr:Array<any> = [];

const SupplierInvoiceParser = () => {

	const [statementsHeader, setStatementsHeader] = useState<Array<any>>([]);
	const [statements, setStatements] = useState<{
		key: string,
		value: string,
		supplierinvoice: {
			name: string
		},
		createdAt: string,
		_id: string,
	}[]>([]);

	const [page, setPage] = useState<IPagination>({
		totalItems: statements.length,
		current: 1,
		pageSize: 10000,
		pageCount: 0,
		total: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	const location = useLocation();
	const [suppliers, setSuppliers] = useState<{label: string, value: string}[]>([]);
	const [templates, setTemplates] = useState<{label: string, value: string}[]>([]);

	const [supplierFilter, setSupplierFilter] = useState('');
	const [templateFilter, setTemplateFilter] = useState('');

	useEffect(() => {
		(async () => {
			try {
				const suppliersResp = await GetData('suppliers/getAll');
				if(suppliersResp.status === 200) {
					setSuppliers(suppliersResp.data.items.map((val: any, idx: number) => {
						if(idx === 0 && !supplierFilter) {
							setSupplierFilter(val._id);
						}
						return { label: val.name, value: val._id };
					}));
				} else {
					throw new Error(suppliersResp.error);
				}
			}
			catch (err: any) {
				changeApiStatus(false, err.message);
				showNotification('error', err.message);
			}
		})();
		fetchData();
	}, [location.search]);
	const fetchData = async (pageNumber = page.current, summary = querySearch, selectedSupplier = supplierFilter, selectedTemplate = templateFilter) => {
		if(apiStatus.inProgress) {
			return;
		}
		try {
			changeApiStatus(true, '');
			const statemnts = await GetData(`supplierInvoice/coordinates/getAll?pageNumber=${pageNumber}&pageSize=${page.pageSize}&filter=${summary}&supplierId=${selectedSupplier}&templateId=${selectedTemplate}`);
			if (statemnts.status === 200) {
				changeApiStatus(false, '');
				setPage({ ...page, totalItems: statemnts.data.totalItems, total: statemnts.data.total });
				// setStatements(statemnts.data.items);
				const tempGroup = groupBy(statemnts.data.items, (element) => element.supplierinvoice['_id']);
				const tempArr:Array<any> = [];
				mapObject(tempGroup, (val, key) => {
					const tempObject:any = {
						_id: key
					};
					val.map(item => {
						tempObject[item.key] = item.value;
					});
					tempArr.push(tempObject);
				});
				setStatements(tempArr);

			} else {
				throw new Error(statemnts.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();

	const handleSearch = (event: any) => {
		const value = event.target.value;

		if (distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, value.trim());
		}, DEBOUNCE_TIME);
	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		fetchData(currentPage);
	};
	const history = useHistory();
	useEffect(() => {
		(async () => {
			const templateRes = await GetData(`templates/getAll?supplierId=${supplierFilter}`);
			if(templateRes.status === 200) {
				templatesArr = templateRes.data.items;
				setTemplates(templateRes.data.items.map((val: any) => {
					return { label: val.templateName, value: val._id };
				}));
				const one = templateRes.data.items.find((val: {_id: string}) => val._id === templateFilter);
				if(templateRes.data.items.length) {
					fetchData(1, querySearch, supplierFilter, one ? templateFilter : templateRes.data.items[0]._id);
					chnageHeader(templateRes.data.items[0] || {});
				}
				if(!one) {
					setTemplateFilter(templateRes.data.items[0]?._id || '');
					// setTemplateFilter('');
				}
			} else {
				throw new Error(templateRes.error);
			}
		})();
	}, [supplierFilter]);
	useEffect(() => {
		fetchData(1, querySearch, supplierFilter, templateFilter);
	}, [templateFilter]);
	const [deleteInProgress, setDeleteInProgress] = useState(false);
	const handleDelte = async (id: string) => {
		if (apiStatus.inProgress || deleteInProgress) {
			return;
		}
		setDeleteInProgress(true);
		try {
			const delResponse = await DeleteData('supplierInvoice/coordinates/delete', id);
			if (delResponse.status === 200) {
				fetchData(page.current);
				showNotification('success', delResponse.message);
				setDeleteInProgress(false);
			} else {
				throw new Error(delResponse.error);
			}
		}
		catch (err: any) {
			setDeleteInProgress(false);
			showNotification('error', err.message);
		}
	};

	const chnageHeader = (item:any) => {
		const tempHeader = pluck(item.coordinates, 'mappedFieldName') || [];
		setStatementsHeader(tempHeader);
	};

	const onChangeTemplateFilter = (val : string) => {
		const tempItem = find(templatesArr, (item) => item._id === val);
		chnageHeader(tempItem);
		setTemplateFilter(val);
	};
	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-3 col-md-3 col-lg-3 pl">
					<h2 className="projects-name-count">

						Transactions
						{/* <span>
							{
								`Total $${page.total}`
							}
						</span> */}
					</h2>
				</div>
				<div className="col-2 col-md-2 col-lg-2 search-container">

					<input className="search-bar" type="text" placeholder="Search" onChange={handleSearch} />
					<i className="fas fa-search"></i>

				</div>
				<div className="col-2 col-md-2 col-lg-2 search-container">
					<select value={supplierFilter} onChange={(e) => {setSupplierFilter(e.target.value);}}>
						<option value="">All Suppliers</option>
						{
							suppliers.map((val, index) => {
								return <option key={index} value={val.value}>{val.label}</option>;
							})
						}
					</select>
				</div>
				<div className="col-2 col-md-2 col-lg-2 search-container">
					<select value={templateFilter} onChange={(e) => {onChangeTemplateFilter(e.target.value);}}>
						<option value="" disabled>Choose Template</option>
						{
							templates.map((val, index) => {
								return <option key={index} value={val.value}>{val.label}</option>;
							})
						}
					</select>
				</div>
				<div className="col-2 col-md-2 col-lg-2" style={{ textAlign: 'end' }}>
					<Button onClick={() => history.push('/user/add-supplier-invoice')} disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.addButton }}>
						Add
					</Button>
				</div>

			</div>
			<div className="card-main-content">
				<TableContainer>
					<Table className={classes.table} aria-label="simple table">
						<TableHead classes={{ root: classes.tableHead }}>
							<TableRow>
								{/* <TableCell>Project</TableCell> */}
								<TableCell>S.no</TableCell>
								{statementsHeader.map((col:any, idx:number) => <TableCell key={idx}>{col}</TableCell>)}
								<TableCell>Added On</TableCell>
								<TableCell>Action</TableCell>

							</TableRow>
						</TableHead>
						<TableBody>
							{
								apiStatus.inProgress ? (
									<TableRow>
										<TableCell colSpan={6} classes={{ root: classes.tableRow }} >
											<div className="text-center">
												fetching...
											</div>
										</TableCell>
									</TableRow>
								) : (
									apiStatus.failed ? (
										<TableRow>
											<TableCell colSpan={6} classes={{ root: classes.tableRow }} >
												<div className="text-center text-danger">
													{
														apiStatus.failMessage
													}
												</div>
											</TableCell>
										</TableRow>
									) : (
										statements.length ? (
											statements.slice(((page.current - 1) * page.pageSize), page.current * page.pageSize).map((row:any, index:number) => (
												<TableRow key={index}>
													{/* <TableCell classes={{ root: classes.tableRow }} component="th" scope="row">
                            {row.name}
                          </TableCell> */}
													<TableCell classes={{ root: classes.tableRow }}>{index + 1}</TableCell>
													{statementsHeader.map((col:any, idx:number) => <TableCell classes={{ root: classes.tableRow }} key={idx}>{row[col]}</TableCell>)}								
													
													<TableCell classes={{ root: classes.tableRow }}>{StandardDateFormat(row.createdAt)}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left" onClick={e => e.stopPropagation()}>
														<InplaceConfirm
															target={'coordDel' + index}
															Action={() => handleDelte(row._id)}
															HTMLComponent={<i className={`${deleteInProgress ? 'fa fa-spinner' : 'fas fa-trash-alt'}`}></i>}
														/>

													</TableCell>
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={6} classes={{ root: classes.tableRow }}>
													<div className="text-center">
														No Data
													</div>
												</TableCell>
											</TableRow>
										)


									)
								)
							}

						</TableBody>
					</Table>
				</TableContainer>
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};
export default SupplierInvoiceParser;
