import React from 'react';
import CompanyDetail from './CompanyDetail';

const Particular = () => {
	return (
		<div>
			<CompanyDetail />
		</div>
	);
};

export default Particular;
