import React, { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IPagination } from '../../common/interfaces';

import { DeleteData, GetData } from '../../common/api';
import { getSearchQuery, showNotification, StandardDateFormat, SummarizeText, monthNames, DEBOUNCE_TIME, isValidYear } from '../../common/utility';
import { useLocation } from 'react-router-dom';
import InplaceConfirm from '../../components/InplaceConfirm';


const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;
const TransactionList = () => {

	const [statements, setStatements] = useState<{
		transactionFromDate: string,
		transactionToDate: string,
		transactionCreditAmount: number,
		transactionDebitAmount: number,
		transactionTotalAmount: number,
		transactionSummary: string,
		createdAt: string,
		accountName: string,
		_id: string,
		bank: string
	}[]>([]);

	const [page, setPage] = useState<IPagination>({
		totalItems: statements.length,
		current: 1,
		pageSize: 10,
		pageCount: 0,
		total: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	const location = useLocation();
	useEffect(() => {
		fetchData();

	}, [location.search]);
	const fetchData = async (pageNumber = page.current, summary = querySearch, month = filterMonth, year = filterYear) => {
		try {
			changeApiStatus(true, '');
			// get id from query
			const accountTypeId = getSearchQuery(location.search, 'accountTypeId');
			const statementId = getSearchQuery(location.search, 'statementId');

			const statemnts = await GetData(`transactions/getAll?pageNumber=${pageNumber}&pageSize=${page.pageSize}&accountTypeId=${accountTypeId || ''}&statementId=${statementId || ''}&filter=${summary}&filterMonth=${month}&filterYear=${isValidYear(year) ? year : ''}`);
			if (statemnts.status === 200) {
				changeApiStatus(false, '');
				setPage({ ...page, totalItems: statemnts.data.totalItems, total: statemnts.data.total });
				setStatements(statemnts.data.items);
			} else {
				throw new Error(statemnts.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();
	const [filterMonth, setFilterMonth] = useState('');
	const [filterYear, setFilterYear] = useState('');

	const handleSearch = (event: any) => {
		const value = event.target.value;

		if (distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, value.trim(), filterMonth);
		}, DEBOUNCE_TIME);
	};
	const handleMonthSearch = (event: any) => {
		const value = event.target.value;
		setFilterMonth(value);

		currentPagination = 1;
		setPage({ ...page, current: currentPagination });
		fetchData(1, querySearch, value.trim(), filterYear);

	};
	const handleYearSearch = (event: any) => {
		const value = event.target.value.trim();
		// if(value === '') {
		setFilterYear(value);
		// }
		// setFilterYear(value);
		if (isValidYear(value) || value === '') {
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, querySearch, filterMonth, value);
		}

	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		fetchData(currentPage);
	};
	const handleDelte = async (id: string) => {
		if (apiStatus.inProgress || deleteInProgress) {
			return;
		}
		setDeleteInProgress(true);
		try {
			const delResponse = await DeleteData('transactions/delete', id);
			if (delResponse.status === 200) {
				fetchData(page.current);
				setPage({ ...page, current: 1 });
				showNotification('success', delResponse.message);
				setDeleteInProgress(false);
			} else {
				throw new Error(delResponse.error);
			}
		}
		catch (err: any) {
			setDeleteInProgress(false);
			showNotification('error', err.message);
		}
	};
	const [deleteInProgress, setDeleteInProgress] = useState(false);
	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-4 col-md-4 col-lg-4 pl">
					<h2 className="projects-name-count">

						Bank Transactions
						<span>
							{
								`Total $${page.total}`
							}
						</span>
					</h2>
				</div>
				{/* <div className="col-4 col-md-3 col-lg-3" style={{ textAlign: 'end' }}>
					<Button onClick={() => { handleUpload; }} disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.addButton }}>
                        Upload
					</Button>
				</div> */}
				<div className="col-3 col-md-3 col-lg-3 search-container">

					<input className="search-bar" type="text" placeholder="Search" onChange={handleSearch} />
					<i className="fas fa-search"></i>

				</div>
				<div className="col-2 col-md-2 col-lg-2 search-container">

					<input style={{ color: filterYear.length > 0 && !isValidYear(filterYear) ? 'red' : '#232b36' }} className="search-bar" type="text" placeholder="Year" onChange={handleYearSearch} />
					<i className="fas fa-search"></i>

				</div>
				<div className="col-2 col-md-2 col-lg-2 search-container">

					<select value={filterMonth} onChange={handleMonthSearch}>
						<option value="">All</option>
						{
							monthNames.map((val, index) => {
								return <option key={index} value={(index + 1) + ''}>{val}</option>;
							})
						}
					</select>
				</div>

			</div>
			<div className="card-main-content">
				<TableContainer>
					<Table className={classes.table} aria-label="simple table">
						<TableHead classes={{ root: classes.tableHead }}>
							<TableRow>
								{/* <TableCell>Project</TableCell> */}
								<TableCell align="left">S.no</TableCell>
								<TableCell align="left">From Date</TableCell>
								<TableCell align="left">To Date</TableCell>
								<TableCell align="left">Summary</TableCell>
								<TableCell align="left">Credited</TableCell>
								<TableCell align="left">Debited</TableCell>
								<TableCell align="left">Total</TableCell>
								<TableCell align="left">Bank</TableCell>
								<TableCell align="left">Account</TableCell>
								<TableCell align="left">Actions</TableCell>

							</TableRow>
						</TableHead>
						<TableBody>
							{
								apiStatus.inProgress ? (
									<TableRow>
										<TableCell colSpan={10} classes={{ root: classes.tableRow }} >
											<div className="text-center">
												fetching...
											</div>
										</TableCell>
									</TableRow>
								) : (
									apiStatus.failed ? (
										<TableRow>
											<TableCell colSpan={10} classes={{ root: classes.tableRow }} >
												<div className="text-center text-danger">
													{
														apiStatus.failMessage
													}
												</div>
											</TableCell>
										</TableRow>
									) : (
										statements.length ? (
											statements.slice(((page.current - 1) * page.pageSize), page.current * page.pageSize).map((row, index) => (
												<TableRow key={index}>
													{/* <TableCell classes={{ root: classes.tableRow }} component="th" scope="row">
                            {row.name}
                          </TableCell> */}
													<TableCell classes={{ root: classes.tableRow }} align="left">{index + 1}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.transactionFromDate ? StandardDateFormat(row.transactionFromDate) : 'N/A'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.transactionToDate ? StandardDateFormat(row.transactionToDate) : 'N/A'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left" title={row.transactionSummary}>{SummarizeText(row.transactionSummary, 20)}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.transactionCreditAmount ? `$${row.transactionCreditAmount}` : 'N/A'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.transactionDebitAmount ? `$${row.transactionDebitAmount}` : 'N/A'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.transactionTotalAmount ? `$${row.transactionTotalAmount}` : 'N/A'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.bank || 'N/A'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} title={row.accountName} align="left">{row.accountName ? `${SummarizeText(row.accountName, 20)}` : 'N/A'}</TableCell>


													<TableCell classes={{ root: classes.tableRow }} align="left">
														<InplaceConfirm
															target={'transactionDel' + index}
															Action={() => handleDelte(row._id)}
															HTMLComponent={<i className={`${deleteInProgress ? 'fa fa-spinner' : 'fas fa-trash-alt'}`}></i>}
														/>
													</TableCell>
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={10} classes={{ root: classes.tableRow }}>
													<div className="text-center">
														No Data
													</div>
												</TableCell>
											</TableRow>
										)


									)
								)
							}

						</TableBody>
					</Table>
				</TableContainer>
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};
export default TransactionList;
