// Here we have added our translations

export default {
	// Table Headers
	s_no: 'S.no', // Serial Number
	name: 'Name',
	email: 'Email',
	added: 'Added On',

	// title
	team: 'Team Members',
	total: 'Total',
	title_add: 'Add Team Member',
	title_edit: 'Edit Team Member',
	search: 'Search',

	// Forms Data
	enter_email: 'Enter Email',
	password: 'Password',
	enter_name: 'Enter Name',
	enter_password: 'Enter Password',
	role: 'Role',
	firm: 'CPA Firm',
	select: 'Please Select',
	assign_clients: 'Assign Clients',
	save: 'Save',
	add: 'Add',
	cancel: 'Cancel',
	auditor: 'Auditor',
	clerk: 'Clerk',
	all: 'All',

	// common
	fetch: 'fetching...',
	data: 'No Data',

};
