import React from 'react';

const KeyRelation = () => {
	return (
		<>
			<div className=" client-table-container tabs-content-main ">
				<h1 className="client-content-heading mb-3 mb-md-5">Key Customers</h1>
				<table>
					<thead>
						<tr>
							<th>Full Name</th>
							<th>Country</th>
							<th>Percentage</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="nodata" colSpan={4}> 

								<i className="fas fa-inbox"></i>
									NO Data
							</td>
						</tr>
					</tbody>
				</table>
						
				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#addCustomer">
						<i className="fas fa-plus"></i>
									Add Key Customer
					</button>
					<div className="modal fade" id="addCustomer"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">Add</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label><i className="fas fa-asterisk red-start"></i>Full Name:</label>
										<input type="text" placeholder='Enter Name' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Country:</label>
										<input type="text" placeholder='Enter Country' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Percentage:</label>
										<input type="range" />
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary blue-btn">Add</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*  */}

			<div className=" client-table-container tabs-content-main shareholder-container  benificial-table t-dot-border">
				<h1 className="client-content-heading mb-3 mb-md-5">Key Suppliers</h1>
				<table>
					<thead>
						<tr>
							<th>Full Name</th>
							<th>Country</th>
							<th>Percentage</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="nodata" colSpan={4}> 

								<i className="fas fa-inbox"></i>
									NO Data
							</td>
						</tr>
					</tbody>
				</table>
				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#addSupplier">
						<i className="fas fa-plus"></i>
									Add Key Supplier
					</button>
					<div className="modal fade" id="addSupplier"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">Add</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label><i className="fas fa-asterisk red-start"></i>Full Name:</label>
										<input type="text" placeholder='Enter Name' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Country:</label>
										<input type="text" placeholder='Enter Country' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Percentage:</label>
										<input type="range" />
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary blue-btn">Add</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*  */}
			<div className=" client-table-container tabs-content-main shareholder-container  benificial-table t-dot-border">
				<h1 className="client-content-heading mb-3 mb-md-5">Bank Accounts</h1>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Account Number</th>
							<th>Account Type</th>
							<th>Authorised Person</th>
							<th>Signing Instructions</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="nodata" colSpan={6}> 

								<i className="fas fa-inbox"></i>
									NO Data
							</td>
						</tr>
					</tbody>
				</table>

				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#addSBankAccount" >
						<i className="fas fa-plus"></i>
									Add Bank Account
					</button>
					<div className="modal fade" id="addSBankAccount"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">Add</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label><i className="fas fa-asterisk red-start"></i>Full Name:</label>
										<input type="text" placeholder='Enter Name' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Account Number:</label>
										<input type="text" placeholder='Enter Account Number' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Account Type:</label>
										<select>
											<option value="">Type 1</option>
										</select>
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Authorised Person:</label>
										<input type="text" placeholder='Enter Authorised Person' className='border' />
									</div>
									<div className="d-flex justify-content-between w-100 mb-4">
										<label>Signing Instructions:</label>
										<div className="textarea">
											<textarea  placeholder='Enter Signing Instructions' className='border'></textarea>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary blue-btn">Add</button>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			{/*  */}
			<div className=" client-table-container tabs-content-main shareholder-container  benificial-table t-dot-border">
				<h1 className="client-content-heading mb-3 mb-md-5">Loan Agreements</h1>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Amount</th>
							<th>Interest Rate</th>
							<th>Address</th>
							<th>Loan Period</th>
							<th>Details</th>
							<th>Convenants</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="nodata" colSpan={8}> 

								<i className="fas fa-inbox"></i>
									NO Data
							</td>
						</tr>
					</tbody>
				</table>
				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#LoanAgreement">
						<i className="fas fa-plus"></i>
									Add Loan Agreement
					</button>
					<div className="modal fade" id="LoanAgreement"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLabel">Add</h5>
										<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
									</div>
									<div className="modal-body">
										<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
											<label><i className="fas fa-asterisk red-start"></i>Name:</label>
											<input type="text" placeholder='Enter Name' className='border' />
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<label>Details:</label>
											<input type="text" placeholder='Loan Details' className='border' />
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<label>Amount:</label>
											<input type="text" placeholder='Amount' className='border' />
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<label>Interest Rate:</label>
											<input type="text" placeholder='Interest Rate' className='border' />
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<label>Period:</label>
											<input type="range" />
										</div>
										<div className="d-flex  justify-content-between w-100 mb-4">
											<label>Convenants:</label>
											<div className="textarea">
												<textarea></textarea>
											</div>
										</div>
										<div className="d-flex  justify-content-between w-100 mb-4">
											<label>Address:</label>
											<div className="textarea">
												<textarea></textarea>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
										<button type="button" className="btn btn-primary blue-btn">Add</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*  */}
			<div className=" client-table-container tabs-content-main shareholder-container  benificial-table t-dot-border">
				<h1 className="client-content-heading mb-3 mb-md-5">Related Parties</h1>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Nature of RelationShip</th>
							<th>Transaction Type</th>
							<th>Amount</th>
							<th>Country</th>
							<th>Auditors</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="nodata" colSpan={7}> 

								<i className="fas fa-inbox"></i>
									NO Data
							</td>
						</tr>
					</tbody>
				</table>

				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#AddRelatedParty">
						<i className="fas fa-plus"></i>
									Add Related Party
					</button>
					<div className="modal fade" id="AddRelatedParty"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLabel">Add</h5>
										<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
									</div>
									<div className="modal-body">
										<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
											<label><i className="fas fa-asterisk red-start"></i>Name:</label>
											<input type="text" placeholder='Enter Name' className='border' />
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<label>Nature Of RelationShip:</label>
											<input type="text" placeholder='Nature Of RelationShip' className='border' />
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<label>Transaction type:</label>
											<input type="text" placeholder='Transaction typet' className='border' />
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<label>Amount:</label>
											<input type="text" placeholder='Amount' className='border' />
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<label>Country of incorporation:</label>
											<input type="text" placeholder='Please input Country of incorporation' className='border' />
										</div>
										<div className="d-flex  justify-content-between w-100 mb-4">
											<label>Name of Auditors/Accountants:</label>
											<div className="textarea">
												<textarea placeholder='Name of Auditors/Accountants'></textarea>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
										<button type="button" className="btn btn-primary blue-btn">Add</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default KeyRelation;
