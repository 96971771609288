// Here we have added our translations

export default {
	welcome: '歡迎',
	login_note: '請使用您的帳戶登錄',
	email: '電子郵件',
	enter_email: '輸入電子郵件',
	password: '密碼',
	enter_password: '輸入密碼',
	button: '記住我',
	login: '登錄',
	forgot_password: '忘記密碼',
};
 