// We will not use these translations.

export default {
	particular: 'Particulars',
	dd: 'CDD &38 KYC',
	shareholders: 'Shareholders',
	director: 'Directors and Executives',
	key: 'Key Relationships',
	docs: 'Important Documents',

	// Cdd KYC
	kyc: 'KYC Screening',
	s_no: 'S No',
	name: 'Name',
	position: 'Position Held',
	date: 'Date of Screening',
	output: 'Output',
	observations: 'Observations',
	pep: 'PEP',
	santions_list: 'Santions List',
	action: 'Action',
	client: 'client',
	add_kyc: 'Add KYC Screening',
	add_screening: 'Add Screening',
	name_: 'Name:',
	screening_on: 'Screening on:',
	enter_observations: 'Enter Observations',
	diligence: 'Client Due Diligence',

	// Director Executives
	title: 'Directors and Executives',
	id: 'Id',
	full_name: 'Full Name',
	nationality: 'Nationality',
	address: 'Address',
	percent: 'Percent',
	position_: 'Position',
	date_of_app: 'Date of appointment',
	date_of_res: 'Date of Resignation',
	add_director: 'Add Director',
	id_no: 'Id No:',
	enter_message: 'Enter message',
	select_pos: 'Select Position',
	pos_one: 'position one',
	ownership: 'Ownership',
	appointed_on: 'Appointed on',
	resigned_on: 'Resigned on',
		
	// common
	cancel: 'Cancel',
	save: 'Save',
	data: 'NO Data',
	fetch: 'fetching...',

};
