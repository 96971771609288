import React from 'react';

const CompanyDetail = () => {
	return (
		<>
			<div className=" client-table-container tabs-content-main company-details">
				<h1 className="client-content-heading mb-3 mb-md-5">Company Details</h1>
                
				<form>
					<div className='row'>

						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Entity Type</label>
							<select className="form-select form-select" aria-label=".form-select-lg example">
								<option selected>Open this select menu</option>
								<option value="1">Type One</option>
								<option value="2">Type Two</option>
								<option value="3">Type Three</option>
							</select>
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Industry</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Licensing Authority</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Registration number</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">License Number</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Tax Registration Number</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Parent Company</label>
							<input type="text" className="form-control" />
						</div>
					</div>
				</form>
				<hr/>
				<form>
					<div className='row'>

						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Date of Incorporation</label>
							<input type="date" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Country of Incorporation</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">City of Incorporation</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Principal Place of Business</label>
							<textarea></textarea>
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Registered Address</label>
							<textarea></textarea>
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Postal Address</label>
							<textarea></textarea>
						</div>
					</div>
				</form>
				<form>
					<div className='row'>

						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Reporting Currency</label>
							<select className="form-select form-select" aria-label=".form-select-lg example">
								<option selected> Currency</option>
								<option value="1">India(Rupees)</option>
								<option value="2">United State(USD)</option>
							</select>
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Financial Year End</label>
							<input type="date" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Business description</label>
							<textarea></textarea>
						</div>
					</div>
				</form>
			</div>
			{/*  */}
			
			<div className=" client-table-container tabs-content-main company-details">
				<h1 className="client-content-heading mb-3 mb-md-5">Applicable standards and regulations</h1>
				<form>
					<div className='row'>

						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Auditing Standards</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Accounting Standards</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Company Act</label>
							<input type="text" className="form-control" />
						</div>
						<div className="mb-4 col-md-4 col-12 px-0 px-md-5">
							<label className="form-label d-block">Regulations</label>
							<textarea></textarea>
						</div>
					</div>
				</form>
			</div>
			{/*  */}
			<div className=" client-table-container tabs-content-main company-details pb-3" >
				<h1 className="client-content-heading mb-3 mb-md-5 add-btn">Client Contacts 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#addContact">
						<i className="fas fa-plus"></i>
                            Add Contact
					</button>
					<div className="modal fade" id="addContact"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">Add contact</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label>Person:</label>
										<input type="text" placeholder='Enter Person Name' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Email:</label>
										<input type="text" placeholder='Enter email' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Phone</label>
										<input type="text" placeholder='Enter Phone' className='border' />
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary blue-btn">Add contact</button>
								</div>
							</div>
						</div>
					</div>


				</h1>
				<div className='edit-main'>
					<ul className='p-0 ml-0'>
						<li>Name: Client Name</li>
						<li>mail: EmailAdress@icloud.com</li>
						<li>Phone: 0123456789</li>
					</ul>
					<div className='icons'> 
						<i className="fa fa-pen blue"></i>
						<i className="fas fa-trash-alt delete-icon red"></i>
					</div>
				</div>
			</div>
			{/*  */}
			<div className=" client-table-container tabs-content-main company-details pb-3 border-0" >
				<h1 className="client-content-heading mb-3 mb-md-5 add-btn">Other Notes
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#addNote">
						<i className="fas fa-plus"></i>
                        Add notes
					</button>
					<div className="modal fade" id="addNote"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">Add note</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label>Heading</label>
										<input type="text" placeholder='Enter Heading' className='border' />
									</div>
									<div className="d-flex justify-content-between w-100 mb-4">
										<label><i className="fas fa-asterisk red-start"></i>Note:</label>
										<div className="textarea">
											<textarea placeholder='Enter messages' className='border' ></textarea>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary blue-btn">Add note</button>
								</div>
							</div>
						</div>
					</div>
				</h1>
				<div className='edit-main'>
					<ul className='p-0 ml-0'>
						<li>Client Name</li>
						<li className='disable'>Client</li>
					</ul>
					<div className='icons'> 
						<i className="fa fa-pen blue"></i>
						<i className="fas fa-trash-alt delete-icon red"></i>
					</div>
				</div>
			</div>
		</>
	);
};

export default CompanyDetail;
