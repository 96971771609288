import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import SideBar from './Sidebar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Badge } from '@material-ui/core';
import {RemoveFromLocalStorage, UserDataKey, ValueFromUserData } from '../common/utility';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getRoutes} from '../routes';
import { Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import { useTranslation } from 'react-i18next';

const routes = getRoutes();
const adminRoutes = routes.adminRoutes;

const useStyles = makeStyles(() => ({
	toolbar: {
		height: '75px',
		background: '#fff',
		top: '0',
		left: '0',
		right: '0',
		position: 'absolute',
	},
	badge: {
		borderRadius: 0
	}
}));

export default function MainAppBar(props: any) {
	const { t } = useTranslation();
	const classes = useStyles();
	const location = useLocation();
	const [open, setOpen] = React.useState<boolean>(true);
	const [logo, setLogo] = useState<string>('/images/logo.png');
	const history = useHistory();

	useEffect(() => {
		props.onSidebarToggle(open);
	}, [open, props]);

	useEffect(() => {
		const tempLogo =  ValueFromUserData('logoFullUrl') || '/images/logo.png';
		setLogo(tempLogo);
	}, []);
	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};
	const logout = () => {
		RemoveFromLocalStorage(UserDataKey);
		localStorage.clear();
		history.push('/login');
	};

	const role = ValueFromUserData('roleName');
	const translatedRole = t(`common:roles.${role}`);


	return (
		<>
			<div className="bar">

				<AppBar className={classes.toolbar}>
					<Toolbar className={classes.toolbar}>
						<div className="toolbar-main">
							<div className="toolbar-left">

								<div>
									<img alt="img" className="headerLogo" src={logo} />
								</div>
								<div className="bigScreenToggle" style={{ marginLeft: '7rem' }}>
									<IconButton
										onClick={open === false ? handleDrawerOpen : handleDrawerClose}
									>
										<MenuIcon color={'primary'} />
										{/* {open === false ? : <MenuOpenIcon color={'primary'} />} */}
									</IconButton>
								</div>

								<div className="fillSideNavBar">
									<nav className="navbar ">
										<IconButton
											onClick={open === false ? handleDrawerOpen : handleDrawerClose}
											className="navbar-toggler fullNavToggle" style={{ marginLeft: '6rem' }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation"
										>
											<MenuIcon color={'primary'} />
											{/* {open === false ? : <MenuOpenIcon color={'primary'} />} */}
										</IconButton>
									</nav>
									<div className="collapse smallScreenNav" id="navbarToggleExternalContent">
										<div style={{height: '100vh',position: 'relative'}}>
											<List className="p-2 " >
												{adminRoutes.map((adminRoute, index) => {
													return (

														<ListItem
															button
															key={index}
															component={Link}
															to={adminRoute.path}
															selected={location.pathname.includes(adminRoute.path)}
															classes={{ selected: 'list-item-selected' }}
                              
														>
															{/* <ListItemIcon></ListItemIcon> */}
															<img alt="img" src={location.pathname.includes(adminRoute.path) ? adminRoute.icon + '-selected.svg' : adminRoute.icon + '.svg'} />
															<ListItemText primary={adminRoute.name.toUpperCase()} />
														</ListItem>
													);
												})}

												<ListItem
													component={Button}
													onClick={logout}
												>
													{/* <ListItemIcon></ListItemIcon> */}
													<img alt="img" src="/images/Vector.svg" />
													<ListItemText primary="Logout" />
												</ListItem>
											</List>
											<div className="fullSidebar">
												<div className="app-version">
													{t("common:version")}: 1.0.0
												</div>
											</div>

										</div>
									</div>
								</div>

							</div>


							<div className="toolbar-right">
								<div>
									{ValueFromUserData('name')}		
								</div>
								<Badge badgeContent={translatedRole} style={{
									minWidth: 'unset',
									width: 'auto',
									padding: '0 8px',
									whiteSpace: 'nowrap', // Prevent text from wrapping
									// overflow: 'hidden', // Hide overflowing content
									// textOverflow: 'ellipsis' // Show ellipsis for overflowed content
								}} color="primary"></Badge>
								<div className='mb-2'>{ValueFromUserData('companyName') || ''}</div>
							</div>
						</div>
					</Toolbar>
				</AppBar>
			</div>
			<div className="sidebar">

				<SideBar open={open} />
			</div>
		</>
	);
}