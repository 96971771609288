import React, { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IPagination } from '../../common/interfaces';

import { getDataWithQuery } from '../../common/api';
import { DEBOUNCE_TIME, showNotification, StandardDateFormat } from '../../common/utility';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;
const UsersList = () => {
	const { t } = useTranslation();
	const [statements, setStatements] = useState<{
        name: string,
        email: string,
        createdAt: string,
        _id: string
    }[]>([]);

	const [page, setPage] = useState<IPagination>({
		totalItems: statements.length,
		current: 1,
		pageSize: 10,
		pageCount: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	useEffect(() => {
		fetchData();

	}, []);
	const fetchData = async (pageNumber = page.current, accountName = querySearch) => {
		try {
			changeApiStatus(true, '');
			const statemnts = await getDataWithQuery('user/getAll', {
				pageNumber: pageNumber,
				pageSize: page.pageSize,
				filter: accountName,
				roles: [1, 2]
			});
			if (statemnts.status === 200) {
				changeApiStatus(false, '');
				setPage({...page, totalItems: statemnts.data.totalItems});
				setStatements(statemnts.data.items);
			} else {
				throw new Error(statemnts.error);
			}
		}
		catch(err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();

	const handleSearch = (event: any) => {
		const value = event.target.value;
		if(distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, value.trim());
		}, DEBOUNCE_TIME);

	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		if (currentPage * page.pageSize > statements.length) {
			if ((statements.length > (currentPage - 1) * page.pageSize) && currentPage !== 1) {
				return;
			}
			// setFetchCount(currentPage * page.pageSize + 90);
		}
		fetchData(currentPage);
	};
	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-md-6 col-lg-6 pl">
					<h2 className="projects-name-count">

						{t("users_list:team")}
						<span>
							{t("users_list:total")}{`${page.totalItems}`}
						</span>
					</h2>
				</div>
				{/* <div className="col-4 col-md-3 col-lg-3" style={{ textAlign: 'end' }}>
					<Button onClick={() => { handleUpload; }} disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.addButton }}>
                        Upload
					</Button>
				</div> */}
				<div className="col-8 col-md-3 col-lg-3 search-container">

					<input className="search-bar" type="text" placeholder={t("users_list:search")} onChange={handleSearch} />
					<i className="fas fa-search"></i>

				</div>

			</div>
			<div className="card-main-content">
				<TableContainer>
					<Table className={classes.table} aria-label="simple table">
						<TableHead classes={{ root: classes.tableHead }}>
							<TableRow>
								{/* <TableCell>Project</TableCell> */}
								<TableCell align="left">{t("users_list:s_no")}</TableCell>
								<TableCell align="center">{t("users_list:name")}</TableCell>
								<TableCell align="center">{t("users_list:email")}</TableCell>
								<TableCell align="center">{t("users_list:added")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								apiStatus.inProgress ?
									(<TableRow>
										<TableCell colSpan={4} classes={{ root: classes.tableRow }} >
											<div className="text-center">
												{t("users_list:fetch")}
											</div>
										</TableCell>
									</TableRow>
									) : (
										apiStatus.failed ? (
											<TableRow>
												<TableCell colSpan={4} classes={{ root: classes.tableRow }} >
													<div className="text-center text-danger">
														{
															apiStatus.failMessage
														}
													</div>
												</TableCell>
											</TableRow>
										) : (
											statements.length ? (
												statements.slice(((page.current - 1) * page.pageSize), page.current * page.pageSize).map((row, index) => (
													<TableRow key={index}>
														{/* <TableCell classes={{ root: classes.tableRow }} component="th" scope="row">
                            {row.name}
                          </TableCell> */}
														<TableCell classes={{ root: classes.tableRow }} align="left">{index + 1}</TableCell>
														<TableCell classes={{ root: classes.tableRow }} align="center">{row.name}</TableCell>
														<TableCell classes={{ root: classes.tableRow }} align="center">{row.email}</TableCell>
														<TableCell classes={{ root: classes.tableRow }} align="center">{StandardDateFormat(row.createdAt)}</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell colSpan={6} classes={{ root: classes.tableRow }}>
														<div className="text-center">
															{t("users_list:data")}
														</div>
													</TableCell>
												</TableRow>
											)


										)
									)
							}

						</TableBody>
					</Table>
				</TableContainer>
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};
export default UsersList;
