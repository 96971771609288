// Here we have added our translations

export default {
	// Table Headers
	s_no: 'S.no', // Serial Number
	company_name: 'Company Name',
	company_address: 'Company Address',
	admin_name: 'Admin Name',
	admin_email: 'Admin Email',
	relationship_balance: 'Relationship Balance',
	details: 'Details',
	view_details: 'View Details',

	// title
	title: 'Clients',
	total: 'Total',
	title_add: 'Add Client',
	title_edit: 'Edit Client',
	search: 'Search',
	add: 'Add',
	all: 'All',

	// Forms Data
	logo: 'Logo',
	upload_logo: 'Upload Logo',
	admin: 'Admin',
	first_name: 'First Name',
	last_name: 'Last Name',
	phone_no: 'Phone Number',
	email: 'Email',
	enter_company_name: 'Enter Company Name',
	enter_company_address: 'Enter Company Address',
	enter_first_name: 'Enter First Name',
	enter_last_name: 'Enter Last Name',
	enter_email: 'Enter Email',
	enter_phone_no: 'Enter Phone Number',
	password: 'Password',
	enter_password: 'Enter Password',
	firm: 'CPA Firm',
	select: 'Please select',
	save: 'Save',
	cancel: 'Cancel',

	// common
	fetch: 'fetching...',
	data: 'No Data',

};
