// Here we have added our translations

export default {
	s_no: '序號', // Serial Number --- Google Translate: 'serial number'
	company_name: '公司名稱',
	company_address: '公司地址',
	avg_sale_revenue: '最近6個月（L6M）平均銷售收入', // Average Sales Revenue --- Google Translate: 'Average sales revenue in the last 6 months (L6M)'
	avg_bank_revenue: '最近6個月（L6M）平均銀行餘額', // Average Bank Revenue --- Google Translate: 'Average bank balance in the last 6 months (L6M)'
	title: '客戶',
	total: '總計',
	search: '搜尋',
	all: '全部',

};
