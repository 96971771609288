// Here we have added our translations

export default {
	// Table Headers
	s_no: 'S.no', // Serial Number
	company_name: 'Company Name',
	company_address: 'Company Address',
	admin_name: 'Admin Name',
	admin_email: 'Admin Email',
	added: 'Added On',

	// title
	title: 'CPA Firms',
	total: 'Total',
	title_add: 'Add CPA Firm',
	title_edit: 'Edit CPA Firm',
	search: 'Search',
	add: 'Add',

	// Forms Data
	logo: 'Logo',
	upload_logo: 'Upload Logo',
	admin: 'Admin',
	first_name: 'First Name',
	last_name: 'Last Name',
	phone_no: 'Phone No',
	email: 'Email',
	enter_name: 'Enter Name',
	enter_company_name: 'Enter Company Name',
	enter_company_address: 'Enter Company Address',
	enter_first_name: 'Enter First Name',
	enter_last_name: 'Enter Last Name',
	enter_email: 'Enter Email',
	enter_phone_no: 'Enter Phone No',
	password: 'Password',
	enter_password: 'Enter Password',
	save: 'Save',
	cancel: 'Cancel',

	// common
	fetch: 'fetching...',
	data: 'No Data',
	all: 'All',

};
