
// SASS Import
import './App.scss';
import './index.scss';
import { HashRouter, Route, Switch } from 'react-router-dom';
import React, { createRef, useEffect } from 'react';
import NotificationSystem from 'react-notification-system';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { $dismissNotification, $editNotification, $showNotification, ENVIRONMENT } from './common/utility';
import CPAAdminLayout from './containers/CPAAdminLayout';
import Landing from './views/Landing';
import BankAdminLayout from './containers/BankAdminLayout';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from './constants/languages';

// Started Development on Multi-Language Feature
const AdminLayout = React.lazy(() => import('./containers/AdminLayout'));
const Pdf = React.lazy(() => import('./containers/UserLayout'));
const AdminLogin = React.lazy(() => import('./views/Login'));
const SendOTP = React.lazy(() => import('./views/sendOTP'));

const theme = createTheme({
	palette: {
		primary: {
			main: '#447edf',

		}
	}
});

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);


function App() {
	const notificationSystem = createRef<any>();
	const { i18n, t } = useTranslation();

	const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const langCode = e.target.value;
		localStorage.setItem('selectedLanguage', langCode); 
		window.location.reload(); 
		i18n.changeLanguage(langCode);
	  };
	  
	useEffect(() => {
		const selectedLanguage = localStorage.getItem('selectedLanguage');
		if (selectedLanguage) {
			i18n.changeLanguage(selectedLanguage);
		}
		$showNotification.subscribe((val: any) => {
			const notification = notificationSystem.current;
			if (notification) {
				notification.addNotification({
					...val,
					position: 'tr',
					autoDismiss: val.uid ? 0 : 4,
					dismissible: val.uid ? 'none' : 'both',
				});
			}
		});
		$dismissNotification.subscribe((val: any) => {
			const notification = notificationSystem.current;
			if (notification) {
				notification.removeNotification(val);
			}
		});
		$editNotification.subscribe((val: any) => {
			const notification = notificationSystem.current;
			if (notification) {
				notification.editNotification(val.uid, {
					message: val.message,
				});
			}
		});
	}, []);
	return (
		<>
			<MuiThemeProvider theme={theme}>
				<div className="App">
					<HashRouter>
						<React.Suspense fallback={loading}>
							<Switch>
								<Route
									path='/'
									exact={true}
									render={(props) => <Landing {...props} />}
								/>
								<Route
									path="/login"
									exact={true}
									render={(props) => <AdminLogin {...props} />}
								/>
								<Route
									path="/send"
									exact={true}
									render={(props) => <SendOTP {...props} />}
								/>
								<Route
									path="/user"
									exact={false}
									render={(props) => <Pdf {...props} />}
								/>
								<Route
									path="/admin"
									exact={false}
									render={(props) => <AdminLayout {...props} />}
								/>
								<Route
									path="/cpa"
									exact={false}
									render={(props) => <CPAAdminLayout {...props} />}
								/>
								<Route
									path="/bank"
									exact={false}
									render={(props) => <BankAdminLayout {...props} />}
								/>

								{/* <Redirect from='/' to='/login' /> */}
							</Switch>
						</React.Suspense>
					</HashRouter>
					{ENVIRONMENT && <div className="environment">{t("common:dev_env")}</div>}
					<div className='multi-language'>
						<select value={i18n.language} onChange={onChangeLang}>
							{LANGUAGES.map(({ code, label }) => (
								<option key={code} value={code}>
									{label}
								</option>
							))}
              			</select>
            		</div>		
				</div>
			</MuiThemeProvider>
			<NotificationSystem ref={notificationSystem} />
		</>
	);
}
export default App;
