import jsPDF from 'jspdf';
import moment from 'moment';
import { Subject } from 'rxjs';
declare global {
	interface Window {
		runConfig: any,
		reloadHeader: any
	}
}
//
export const BASE_URL = window.runConfig.BaseURL;
export const ROLES = window.runConfig.roles || {
	fakeRole: 0,
	admin: 1,
	user: 2,
	CPAAdmin: 3,
	CPAAuditor: 4,
	CPAClerk: 5,
	clientAdmin: 6,
	ClientTeamMember: 7,
	bankAdmin:8,
	bankTeamMember:9

};
export const ENVIRONMENT  = window.runConfig.environment || '';
export const CLIENT_PORTAL_URL = window.runConfig.ClientPortalURL;
export const DEBOUNCE_TIME = 700;
export const UserDataKey = window.runConfig.Keys.UserData;
export const LANDING_VIDEO_URL = window.runConfig.LandingVideourl || 'https://api1.teampro.dev/public/upload/7emtqpc3q8Image_from_iOS.MP4';
//

export const IsLoggedIn = () => {
	return ReadLocalStorage(UserDataKey) !== undefined;
};
export const $showNotification = new Subject();
export const $dismissNotification = new Subject();
export const $editNotification = new Subject();

type notificationSystemLevel = 'success' | 'error' | 'warning' | 'info';
export const showNotification = (
	level: notificationSystemLevel,
	message: string,
	uid?: string
) => {
	$showNotification.next({ level, message, uid });
};

export const editNotification = (uid: string, message: string) => {
	$editNotification.next({ uid, message });
};

export const dismissNotification = (uid: string) => {
	$dismissNotification.next(uid);
};

export const ValueFromUserData = (key: string) => {
	let UserData: any = ReadLocalStorage(UserDataKey);

	if (UserData !== undefined && UserData !== null) {
		UserData = JSON.parse(UserData);
		// console.log(UserData, key)
		return UserData[key];
	} else {
		return '';
	}
};

export const ReduceString = (text: string) => {
	return text
		.trim()
		.replace(/ /g, '')
		.toLowerCase();
};

export const SetLocalStorage = (key: string, value: string) => {
	localStorage.setItem(key, value);
};

export const ReadLocalStorage = (key: string) => {
	const result = localStorage.getItem(key);
	if (result === undefined || result === null) {
		return undefined;
	} else {
		return result;
	}
};

export const RemoveFromLocalStorage = (key: string) => [
	localStorage.removeItem(key)
];

export const StandardDateFormat = (Date: any) => {
	return moment(Date).format('MMM DD, YYYY');
};

export const FormatCurrency = (value: string | number) => {
	if (value === undefined || value === null) return '$0';
	return `$${value}`;
};

export const FilterRecords = async (
	Records: any[] = [],
	Keys: string[] = [],
	SearchString = ''
): Promise<any[]> => {
	const FilteredArray: any = [];
	return new Promise<any[]>(resolve => {
		Records.forEach((val: any, idx: number) => {
			for (let iterator = 0; iterator < Keys.length; iterator++) {
				if (
					val[Keys[iterator]] !== null &&
					val[Keys[iterator]] !== undefined &&
					ReduceString(val[Keys[iterator]].toString()).includes(
						// eslint-disable-next-line no-mixed-spaces-and-tabs
						ReduceString(SearchString)
					)
				) {
					FilteredArray.push(val);
					break;
				}
			}
			//   return obj.VAL.indexOf(value) !=== -1;

			if (Records.length === idx + 1) {
				resolve(FilteredArray);
			}
		});
	});
};

export const SummarizeText = (text: string, count: number) => {
	if (text === undefined || text === null) {
		return '';
	}

	if (text.length <= count) {
		return text;
	}

	return text.substr(0, count) + '...';
};

export const fullDateFormat = (date: string) => {
	return moment(date).format('MMMM DD YYYY, h:mm a');
};


export const getMonthDateYear = (date: string) => {
	return moment(date).format('MMMM DD, YYYY');
};

export const downloadFile = (dataurl: string, filename: string) => {
	const a = document.createElement('a');
	a.href = dataurl;
	a.setAttribute('download', filename);
	a.click();
};


export const readFileAsDataUrl = (file: any): Promise<string> => {
	return new Promise(res => {
		const reader = new FileReader();
		reader.onload = () => {
			res(reader.result as string);
		};
		reader.readAsDataURL(file);
	});
};

export const disableScrollOnBody = (canDisable: boolean) => {
	const overflow = canDisable ? 'hidden' : 'auto';
	document.getElementsByTagName('body')[0].style.overflow = overflow;
};

export const createCookie = (name: string, value: string, domain = 'npit.at', days = 7) => { // 1 week valid
	const d = new Date();
	d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
	const expires = 'expires=' + d.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';domain=' + domain + ';path=/';
};
export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December'
];
export const getLocaleMonthDateYear = (dateString: string) => {
	const gmtDateTime = moment.utc(dateString, '');
	const local = gmtDateTime.local().format('MMMM DD, YYYY');
	return local;

};

export const getLocaleTime = (dateString: string) => {
	const gmtDateTime = moment.utc(dateString, '');
	const local = gmtDateTime.local().format('hh:mm A');
	return local;
};

export const getSearchQuery = (query: string, name: string) => {
	const urlSearcher = new URLSearchParams(query);
	return urlSearcher.get(name);
};

export const isValidYear = (year: string) => {
	return /^(19|20)\d{2}$/.test(year);
};

// pdf utilities
export const getPdfUrl = async (file: Blob): Promise<string> => {
	const fileUrl = await readFileAsDataUrl(file);
	return new Promise(res => {

		const image = new Image();
		image.src = fileUrl;
		image.onload = () => {
			const width = image.width, height = image.height;
			const doc = new jsPDF(width > height ? 'l' : 'p', 'px', [width > height ? width: height, width < height ? width: height]);
			doc.addImage(fileUrl, 'JPEG', 0, 0, width, height);
			res(doc.output('datauristring'));
		};
	});
};

export const getPercent = (fraction: number, total: number) => {
	return Math.ceil((fraction * 100) / total);
};