// Here we have added our translations

export default {
	// Table Headers
	s_no: 'S.no', // Serial Number
	name: 'Name',
	email: 'Email',
	added: 'Added On',

	// title
	title: 'Bank Users',
	total: 'Total',
	title_add: 'Add Bank User', // Tite to add Bank User 
	title_edit: 'Edit Bank User', // Title to edit Bank User
	search: 'Search',
	add: 'Add',
	all: 'All',

	// Forms Data
	password: 'Password',
	bank: 'Bank',
	enter_name: 'Enter Name',
	enter_email: 'Enter Email',
	enter_password: 'Enter Password',
	select: 'Please select',
	assign_clients: 'Assign Clients',
	save: 'Save',
	cancel: 'Cancel',

	// common
	fetch: 'fetching...',
	data: 'No Data',

};
