// Here we have added our translations

export default {
	sales: 'Sales Analysis',
	liquidity: 'Liquidity Analysis',
	expense: 'Expense Analysis',
	sale_analytics: 'Sales Analytics',
	liquidity_analytics: 'Liquidity Analytics',
	expense_analytics: 'Expense Analytics',
	six_months: '6M',
	twelve_months: '12M',

};
