import { Observable } from 'rxjs';
import { fileUpload, PostData, responseHandler } from '../common/api';

export const uploadFile = (endpoint: string, data: any) => {
	return new Observable(subscriber => {
		const formData = new FormData();
		for(const item in data) {
			formData.append(item, data[item]);
		}
		PostData(endpoint, formData, {
			onUploadProgress: (progressEvent: any) => {
				subscriber.next({event: 'load', value: `${Math.ceil((progressEvent.loaded/progressEvent.total) * 100)}`});
			}
		}).then((val) => {
			subscriber.next({event: 'complete', value: val});
		});
	});
};

export const uploadFile2 = (file: any, onUploadProgress: any) => {
	const formData = new FormData();
	formData.append('file', file);
	return fileUpload('/file/save', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		onUploadProgress: (progressEvent: any) => {
			onUploadProgress({ event: 'load', progressValue: `${Math.ceil((progressEvent.loaded / progressEvent.total) * 100)}`, data: progressEvent  });
		},
	}).then((val) => {
		console.log(val);
		onUploadProgress({ event: 'complete', data: responseHandler(val) });
	});
};