// Here we have added our translations

export default {
	// Table Headers
	s_no: '序號', // Serial Number
	company_name: '公司名稱',
	company_address: '公司地址',
	admin_name: '管理員名稱',
	admin_email: '管理員電子郵件', // Admin Email --- Google Translate: 'Administrator email'
	relationship_balance: '關係餘額',
	details: '詳細信息',
	view_details: '查看詳細信息', // View Details --- Google Translate: 'check the detail information'

	// title
	title: '客戶',
	total: '總計',
	title_add: '新增客戶', // Add Client Google Translate: 'Add new customer'
	title_edit: '編輯客戶',
	search: '搜尋',
	add: '新增', // Add --- Google Translate: 'New'
	all: '全部',

	// Forms Data
	logo: '標誌',
	upload_logo: '上傳標誌',
	admin: '管理員', // Admin --- Google Translate: 'administrator'
	first_name: '名字', // First Name --- Google Translate: 'name'
	last_name: '姓氏',
	phone_no: '電話號碼',
	email: '電子郵件',
	enter_company_name: '輸入公司名稱',
	enter_company_address: '輸入公司地址',
	enter_first_name: '輸入名字',
	enter_last_name: '輸入姓氏',
	enter_email: '輸入電子郵件',
	enter_phone_no: '輸入電話號碼',
	password: '密碼',
	enter_password: '輸入密碼',
	firm: '註冊會計師事務所',
	select: '請選擇',
	save: '保存', // Save --- Google Translate: 'Keep'
	cancel: '取消',

	// common
	fetch: '獲取中...', // fetching... --- Google Translate: 'Retrieving...'
	data: '無數據',

};
