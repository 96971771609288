import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IApiCallStatus } from '../../common/interfaces';
// import InputCtrl from '../../controllers/InpuCtrl';
import { makeStyles } from '@material-ui/core/styles';
import InplaceConfirm from '../../components/InplaceConfirm';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { dismissNotification, showNotification, editNotification } from '../../common/utility';
// import Select from 'react-select';
import { DeleteData, GetData } from '../../common/api';
import SelectCtrl from '../../controllers/SelectCtrl';
import { Subject } from 'rxjs';
import { uploadFile } from '../../services/fileUpload';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	track: {
		background: '#fff',
		border: '2px solid #AAA',
		transform: 'scaleY(1.7) scaleX(1.3)',
		opacity: 1
	},
	cancelButton: {
		width: '151px',
		height: '64px',
		background: '#fff',
		borderRadius: '400px',
		color: '#000',
		border: '1px solid #263238',
		marginLeft: '20px',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			backgroundColor: '#FFF!important'
		}
	},
	saveButton: {
		width: '151px',
		height: '64px',
		background: '#263238',
		borderRadius: '400px'
	}
});

const SupplierInvoiceParserDetails = () => {

	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	// controls at which state api is
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});

	// for changing api statuses
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	// react hook form destructing
	const { errors, control, handleSubmit, watch } = useForm({
		mode: 'onBlur'
	});

	const params: any = useParams();
	const [error, setError] = useState<string | boolean>(false);
	const [editMode] = useState(false);
	const [templates, setTemplates] = useState<{
		label: string,
		value: string
	}[]>([]);
	const [suppliers, setSuppliers] = useState<{ _id: string, name: string }[]>([]);

	useEffect(() => {
		(async () => {
			changeApiStatus(true, '');
			try {
				// const templateRes = await GetData('templates/getAll?pageSize=1000');
				// if (templateRes.status === 200) {
				// 	changeApiStatus(false, '');
				// 	setTemplates(templateRes.data.items.map((val: any) => { return { label: val.templateName, value: val._id }; }));
				// } else {
				// 	throw new Error(templateRes.error);
				// }

				const supplierRes = await GetData('suppliers/getAll?pageSize=1000');
				if (supplierRes.status === 200) {
					changeApiStatus(false, '');
					const items = supplierRes.data.items;
					setSuppliers(items);
				} else {
					throw new Error(supplierRes.error);
				}

				// check for the id
				// if (params.id !== '0') {
				// 	// set edit mode to true
				// 	changeApiStatus(true, '');
				// 	setEditMode(true);
				// 	// run a global try catch and implement the functioning
				// 	const getResult = await GetData('suppliers/get?id=' + params.id);
				// 	if (getResult.status === 200) {
				// 		const { name, templates } = getResult.data;
				// 		reset({ name });
				// 		changeApiStatus(false, '');
				// 		// setSelectedTemplates(templates.map((val: any) => { return { label: val.templateName, value: val._id }; }));
				// 	} else {
				// 		throw new Error(getResult.error);
				// 	}
				// }
			}
			// check for the errors
			catch (error: any) {
				setError(error.message);
				changeApiStatus(false, error.message);
				showNotification('error', error.message);
			}
		})();
	}, []);
	const showError = (_fieldName: string) => {
		// if error is there show on the screen
		const error = (errors as any)[_fieldName];
		return error ? (
			<div className="error-block">{error.message || t("common:field_require")}</div>
		) : null;
	};
	// const [selectedTemplates, setSelectedTemplates] = useState<{
	//     label: string,
	//     value: string
	// }[]>([]);
	const onSubmit = async (data: any) => {
		changeApiStatus(true, '');
		try {
			// set id if in editMode
			// editMode && (data.id = params.id);
			if (files.length === 0) {
				throw new Error('Please choose atleast one PDF file');
			}
			uploadFileToServer(files, data.templateId);
		}
		catch (error: any) {
			// error found
			setError(error.message);
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}

	};
	const goBack = () => {
		history.push('/user/supplier-invoice-parser');
	};
	const onDelete = async () => {
		// run global try catch and delte resource via api
		try {
			changeApiStatus(true, '');
			const supplierDelete = await DeleteData('suppliers/delete', params.id);
			if (supplierDelete.status === 200) {
				showNotification('success', supplierDelete.message);
				goBack();
			} else {
				throw new Error(supplierDelete.error);
			}
		}
		catch (error: any) {
			// error found
			setError(error.message);
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
	};
	const [files, setFile] = useState<Array<any>>([]);
	const onChange = (e: any) => {
		const file = e.target.files;
		if (file.length) {
			setFile(file);
		}
	};
	const watchSupplier = watch('supplierId');
	useEffect(() => {
		if (watchSupplier) {
			// fetch the templates
			(async () => {
				try {
					const supplierRes = await GetData(`templates/getAll?pageSize=1000&supplierId=${watchSupplier}`);
					if (supplierRes.status === 200) {
						changeApiStatus(false, '');
						const items = supplierRes.data.items;
						setTemplates(items.map((val: any) => { return { label: val.templateName, value: val._id }; }));
					} else {
						throw new Error(supplierRes.error);
					}
				}
				catch(error: any) {
					// error found
					setError(error.message);
					changeApiStatus(false, error.message);
					showNotification('error', error.message);
				}
			})();
		}
	}, [watchSupplier]);
	// const processFile = (event: any) => {
	// 	const file = event.target.files;
	// 	if (file.length) {
	// 		uploadFileToServer(file);
	// 	}
	// };
	const [uploadInProgress, setUploadProgress] = useState(false);
	const uploadFileToServer = (files: Array<File>, templateId: string) => {
		const observable = new Subject();
		const notiId = 'file-upload';
		showNotification('info', t("common:start_uploads"), notiId);
		let i = 0;
		observable.subscribe({
			next: () => {
				try {
					setUploadProgress(true);
					uploadFile('supplierInvoice/save', { file: files[i], templateId })
						.subscribe({
							next: (val: any) => {
								if (val.event === 'load') {
									editNotification(notiId, `Uploading ${val.value}% (${i + 1}/${files.length})`);
								}
								else {
									setUploadProgress(false);
									const data = val.value;
									if (data.status === 200) {
										if (i === files.length - 1) {
											changeApiStatus(false, '');
											dismissNotification(notiId);
											showNotification('success', `t("common:upload_process")${files.length}t("common:files")`);
											goBack();
											return;
										}
										i++;
										observable.next(i);
									} else {
										changeApiStatus(false, data.error);
										showNotification('error', data.error);
										dismissNotification(notiId);
										setUploadProgress(false);
									}
								}
							}
						});
				}
				catch (err: any) {
					changeApiStatus(false, err.message);
					showNotification('error', err.message);
					setUploadProgress(false);
				}
			}
		});
		observable.next(0);
	};
	return (
		<div className="details-component container">
			<Prompt
				when={uploadInProgress}
				message="Are you sure you want to leave? The file upload process will continue in background still."
			/>
			<div className="details-header mb-5">
				<h2>
					Supplier Invoice Details
				</h2>
				{/* {
					editMode ? (
						<h2 className="id">#{params.id}</h2>

					) : null
				} */}
			</div>
			<div className="detail-controls">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row mb-5">
						<div className="col-md-6">
							<SelectCtrl
								control={control}
								showError={showError}
								placeholder="Supplier Name"
								name="supplierId"
								required={true}
								className="form-control"
								disabled={apiStatus.inProgress}
								options={[{ label: 'Select a Supplier', value: '' }, ...suppliers.map((val) => { return { label: val.name, value: val._id }; })]}
							/>
						</div>
						<div className="col-md-6">
							<SelectCtrl
								control={control}
								showError={showError}
								placeholder="Template"
								name="templateId"
								required={true}
								className="form-control"
								disabled={apiStatus.inProgress}
								options={[{ label: 'Select a Template', value: '' }, ...templates]}
							/>
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-md-6">
							<label htmlFor="ch-file">Choose PDF</label> <br />
							<input multiple={true} id="ch-file" type="file" onChange={onChange} accept="application/pdf, image/*" />
						</div>
					</div>



					{
						error ? (
							<div className="text-danger mb-2">
								{error}
							</div>
						) : null
					}

					<div className="row">
						<div className="col-md-11">
							<Button disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.saveButton }}>
								Save
							</Button>
							<Button disabled={apiStatus.inProgress} type="button" onClick={() => history.goBack()} variant="contained" color="secondary" classes={{ containedSecondary: classes.cancelButton }}>
								Cancel
							</Button>
						</div>
						{
							editMode ? (
								<div className="col-md-1">

									<InplaceConfirm
										HTMLComponent={<img alt="img" id="del-icon" src="/images/Group 1718.png" />}
										Action={onDelete}
										target="del-icon"
									/>
								</div>


							) : null
						}



					</div>

				</form>
			</div>
		</div>
	);
};

export default SupplierInvoiceParserDetails;
