// Here we have added our translations

export default {
	// Table Headers
	s_no: '序號', // Serial Number
	company_name: '公司名稱',
	company_address: '公司地址',
	admin_name: '管理員名稱',
	admin_email: '管理員電子郵件',  // Admin Email --- Google Translate: 'Administrator email'
	added: '添加於', // Added On --- Google Translate: 'Added to'

	// title
	title: '註冊會計師事務所',
	total: '總計',
	title_add: '新增註冊會計師事務所',
	title_edit: '編輯註冊會計師事務所',
	search: '搜尋',
	all: '全部',
	add: '新增', // Add --- Google Translate: 'New'

	// Forms Data
	logo: '標誌',
	upload_logo: '上傳標誌',
	admin: '管理員',  // Admin --- Google Translate: 'administrator'
	first_name: '名字',  // First Name --- Google Translate: 'name'
	last_name: '姓氏',
	phone_no: '電話號碼',
	email: '電子郵件',
	enter_name: '輸入名字',
	enter_company_name: '輸入公司名稱',
	enter_company_address: '輸入公司地址',
	enter_first_name: '輸入名字',
	enter_last_name: '輸入姓氏',
	enter_email: '輸入電子郵件',
	enter_phone_no: '輸入電話號碼',
	password: '密碼',
	enter_password: '輸入密碼',
	save: '保存',  // Save --- Google Translate: 'Keep'
	cancel: '取消',

	// common
	fetch: '獲取中...', // fetching... --- Google Translate: 'Retrieving...'
	data: '無數據',

};
