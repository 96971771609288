// Here we have added our translations

export default {
	cat_name: '類別名稱', // Category Name
	expense_count: '費用計數',
	amount: '金額',
	loading: '加載中...',
	latest: '最新',
	avg_closing_balance: '平均收盤餘額',
	no_data_found: '未找到數據',
	avg_monthly_sales: '平均每月銷售',

	// Messages
	messages: {
		'Not Found': '未找到', 
	}

};
