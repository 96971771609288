import React from 'react';
import ClientStepper from '../../clientstepper/ClientStepper';
import { useTranslation } from 'react-i18next';

const CddKyc = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className=" client-table-container tabs-content-main shareholder-container cdd-kyc">
				<h1 className="client-content-heading mb-3 mb-md-5">{t("client_details:kyc")}</h1>
				<table>
					<thead>
						<tr>
							<th>{t("client_details:s_no")}</th>
							<th>{t("client_details:name")}</th>
							<th>{t("client_details:position")}</th>
							<th>{t("client_details:date")}</th>
							<th>{t("client_details:output")}</th>
							<th>{t("client_details:observations")}</th>
							<th>{t("client_details:pep")}</th>
							<th>{t("client_details:santions_list")}</th>
							<th>{t("client_details:action")}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							{/* <td className="nodata"> 

								<i className="fas fa-inbox"></i>
									NO Data
							</td> */}
							<td>1</td>
							<td>{t("client_details:client")}</td>
							<td>
							</td>
							<td>
                                12/12/2020
							</td>
							<td></td>
							<td></td>
							<td><i className='fas fa-check blue'></i></td>
							<td><i className='fas fa-check blue'></i></td>
							<td><i className='fas fa-pen blue'></i>
								<i className='fas fa-trash-alt delete-icon red'></i>
							</td>
						</tr>
					</tbody>
				</table>
				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#addKyc">
						<i className="fas fa-plus"></i>
						{t("client_details:add_kyc")}
					</button>
					<div className="modal fade" id="addKyc"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">{t("client_details:add_screening")}</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label><i className="fas fa-asterisk red-start"></i>{t("client_details:name_")}</label>
										<input type="text" placeholder='Enter Name' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:position")}:</label>
										<input type="text" placeholder='Enter Position Held' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:s_no")}:</label>
										<input type="text" placeholder='Enter S.No' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:screening_on")}</label>
										<input type="Date"  className='border' />
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:santions_list")}:</label>
										<div className='textarea'>
											<input type="checkbox" className='border checkbox' />

										</div>
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:pep")}:</label>
										<div className='textarea'>
											<input type="checkbox" className='border checkbox' />

										</div>
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:observations")}:</label>
										<div className="textarea">
											<textarea placeholder='Enter Observations'></textarea>
										</div>
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>{t("client_details:observations")}:</label>
										<div className="textarea">
											<textarea placeholder={t("client_details:enter_observations")}></textarea>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">{t("client_details:cancel")}</button>
									<button type="button" className="btn btn-primary blue-btn">{t("client_details:add")}</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*  */}
				<div className=" client-table-container tabs-content-main shareholder-container cdd-kyc">
					<h1 className="client-content-heading mb-3 mb-md-5">{t("client_details:diligence")}</h1>
					<ClientStepper />
				</div>

			</div>
			{/*  */}
			
		</>
	);
};
export default CddKyc;