import React from 'react';
import { Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { CheckboxCtrlProps } from '../common/interfaces';

const CheckboxCtrl: React.FC<CheckboxCtrlProps> = ({
	name,
	control,
	label,
	required,
	disabled,
	showError,
	className,
	ctrlClasses,
	onChange,
	// showStar
}) => {
	return (
		<>
			<label>
				{/* { showStar ? <i className="fas fa-asterisk red-start"></i> : null} */}
			    {label}
			</label>
			<div className={ctrlClasses ? ctrlClasses : 'controller-outer'}>
				<Controller
					name={name}
					control={control}
					rules={{ required }}
					render={({  onChange: onChangeFormo, onBlur, value, ref }) => (
						<input
							type="checkbox"
							checked={!!value} // Ensure it's a boolean value
							onChange={(e) => {
								onChangeFormo(e.target.checked);
								onChange && onChange(e); // Call the passed onChange prop if it exists
							  }}
				
							// onChange={(e) => onChange(e.target.checked)} // Correctly handle onChange event
							onBlur={onBlur} // Handle onBlur event for form validation
							ref={ref} // Attach ref for proper functionality
							disabled={disabled}
							className={className}
							style={{width:"25px"}}
						/>
					)}
				/>
				{showError(name)}
			</div>
		</>
	);
};

export default withTranslation()(CheckboxCtrl);
