// Here we have added our translations

export default {

	// Title
	team: '團隊成員',
	total: '總計',
	added: '添加於',  // Added On --- Google Translate: 'Added to'
	search: '搜尋',

	// Table Headers
	s_no: '序號', // Serial Number
	name: '名稱',
	email: '電子郵件',

	// Common
	fetch: '獲取中...',   // fetching... --- Google Translate: 'Retrieving...'
	data: '無數據',

};
