import React from 'react';
import CddKyc from '../../components/client/cddKyc/CddKyc';
import DirectorExcutives from '../../components/client/directorsExecutives/DirectorExcutives';
import ImportantDocument from '../../components/client/importantDocument/ImportantDocument';
import KeyRelation from '../../components/client/keyRelations/KeyRelation';
import Particular from '../../components/client/particular/Particular';
import Shareholder from '../../components/client/shareholder/Shareholders';
import { useTranslation } from 'react-i18next';

const ClientDetails = () => {
	const { t } = useTranslation();
	return (
		<>
			{/* <Particular /> */}
			<nav className="Client-tabs">
				<div className="nav nav-tabs" id="nav-tab" role="tablist">
					<button className="nav-link active" id="nav-Particulars-tab" data-bs-toggle="tab" data-bs-target="#nav-Particulars" type="button" role="tab" aria-controls="nav-Particulars" aria-selected="true">{t("client_details:particular")}</button>
					<button className="nav-link" id="nav-CDDKYC-tab" data-bs-toggle="tab" data-bs-target="#nav-CDDKYC" type="button" role="tab" aria-controls="nav-CDDKYC" aria-selected="false">CDD &#38; KYC</button>
					<button className="nav-link" id="nav-Shareholders-tab" data-bs-toggle="tab" data-bs-target="#nav-Shareholders" type="button" role="tab" aria-controls="nav-Shareholders" aria-selected="false">{t("client_details:shareholders")}</button>
					<button className="nav-link" id="nav-DirectorsExecutives-tab" data-bs-toggle="tab" data-bs-target="#nav-DirectorsExecutives" type="button" role="tab" aria-controls="nav-DirectorsExecutives" aria-selected="true">{t("client_details:director")}</button>
					<button className="nav-link" id="nav-KeyRelationships-tab" data-bs-toggle="tab" data-bs-target="#nav-KeyRelationships" type="button" role="tab" aria-controls="nav-KeyRelationships" aria-selected="false">{t("client_details:key")}</button>
					<button className="nav-link" id="nav-ImportantDocuments-tab" data-bs-toggle="tab" data-bs-target="#nav-ImportantDocuments" type="button" role="tab" aria-controls="nav-ImportantDocuments" aria-selected="false">{t("client_details:docs")}</button>
				</div>
			</nav>
			<div className="tab-content" id="nav-tabContent">
				<div className="tab-pane fade show active" id="nav-Particulars" role="tabpanel" aria-labelledby="nav-Particulars-tab">
					<Particular/>
				</div>
				<div className="tab-pane fade" id="nav-CDDKYC" role="tabpanel" aria-labelledby="nav-CDDKYC-tab">
					<CddKyc/>
				</div>
				<div className="tab-pane fade" id="nav-Shareholders" role="tabpanel" aria-labelledby="nav-Shareholders-tab">
					<Shareholder/>
				</div>
				<div className="tab-pane fade" id="nav-DirectorsExecutives" role="tabpanel" aria-labelledby="nav-DirectorsExecutives-tab">
					<DirectorExcutives/>
				</div>
				<div className="tab-pane fade" id="nav-KeyRelationships" role="tabpanel" aria-labelledby="nav-KeyRelationships-tab">
					<KeyRelation/>
				</div>
				<div className="tab-pane fade" id="nav-ImportantDocuments" role="tabpanel" aria-labelledby="nav-ImportantDocuments-tab">
					<ImportantDocument/>
				</div>

			</div>
		</>
	);
};


export default ClientDetails;