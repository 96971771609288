import React from 'react';

export const StepThree = () => {
	return (
		<div className="stepper-content">
			<p className="heading">Select the applicable</p>
			<ul>
				<li>
					<label>
					The client deals with or have connection with :
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Countries without adequate Anti-Money Laundering/Counter Financing Terrorism systems as identified by credible sources.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Countries under sanctions and embargoes or similar measures.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						
						Countries identified by credible sources as having significant levels of corruption or other criminal activity.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						
						Countries blacklisted for financing or supporting terrorist activities.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						
							Locations that have designated terrorist organisations operating within their country.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Countries that are not members of the FATF and its partners.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Country or a territory that have dealings with high risk jurisdiction.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
							None
					</label>
				</li>
				<li>
					<button>Add an item</button>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						<strong>Enhanched DD applicable</strong>
					</label>
				</li>
			</ul>
		</div>
	);
};
