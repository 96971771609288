import React, { useState } from 'react';
import { Route, Switch, useLocation ,Redirect } from 'react-router-dom';
import { getRoutes } from '../routes';
import MainAppBar from './AppBar';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withAuth from '../hoc/withAuth';

const routes = getRoutes();
const bankAdminRoute = routes.bankAdminRoute;
const bankAdminRoutes = routes.bankAdminRoutes;

const BankAdminLayout = () => {
	const useStyles = makeStyles(() => ({
		root: {
			height: 'calc(100vh - 7.5rem)',
			width: `calc(100vw - ${isSidebarOpen ? '18rem' : '3rem'})`,
			overflow: 'auto',
			padding: '1rem',
		}
	}));
	const [isSidebarOpen, setSideBarOpen] = useState(false);
	const classes = useStyles();
	const location=useLocation();
	
	return (
		<div>
			{!(location.pathname).includes('/bank/clients/details')  && <div>
			
				<MainAppBar onSidebarToggle={(opened: boolean) => { setSideBarOpen(opened); }} />
			
				<div className="admin-routes-container" style={{ left: isSidebarOpen ? '265px' : '25px' }}>
					<Card className={classes.root}> 
						<CardContent>
							<Switch>
								{
									bankAdminRoutes.map((val, index) => {
										return (
											!val.disabled?<Route
												key={index}
												path={val.path}
												exact={val.exact}
												component={val.component}
											/>:null
										);
									})
								}
								<Redirect from='/bank' to='/bank/users' />
							</Switch>
						</CardContent>
					</Card>
				</div>
			</div>}
			<div>
				<Switch>
					{
						bankAdminRoute.map((val, index) => {
							return (
								!val.disabled?<Route
									key={index}
									path={val.path}
									exact={val.exact}
									component={val.component}
								/>:null
							);
						})
					}
							
				</Switch>
			</div>
		</div>
		
	);
};

export default withAuth(BankAdminLayout);
