// Here we have added our translations

export default {
	// Title
	team: 'Team Members',
	total: 'Total',
	added: 'Added On',
	search: 'Search',

	// Table Headers
	s_no: 'S.no', // Serial Number
	name: 'Name',
	email: 'Email',

	// Common
	fetch: 'fetching...',
	data: 'No Data',

};