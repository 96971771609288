// Here we have added our translations

export default {
	s_no: 'S.no', // Serial Number
	company_name: 'Company Name',
	company_address: 'Company Address',
	avg_sale_revenue: 'Last 6 Months (L6M) Average Sales Revenue', // Average Sales Revenue
	avg_bank_revenue: 'Last 6 Months (L6M) Average Bank Balance', // Average Bank Revenue
	title: 'Clients',
	total: 'Total',
	search: 'Search',
	all: 'All',

};
