// Here we have added our translations

export default {
	// Table Headers
	s_no: '序號', // Serial Number
	name: '名稱',
	email: '電子郵件',
	added: '添加於',  // Added On --- Google Translate: 'Added to'

	// title
	team: '團隊成員',
	total: '總計',
	title_add: '新增團隊成員',
	title_edit: '編輯團隊成員',
	search: '搜尋',

	// Forms Data
	enter_email: '輸入電子郵件',
	password: '密碼',
	enter_name: '輸入名稱',
	enter_password: '輸入密碼',
	role: '角色',
	firm: '註冊會計師事務所',
	select: '請選擇',
	assign_clients: '分配客戶',
	save: '保存',
	add: '新增',
	cancel: '取消',
	auditor: '審計員',
	clerk: '職員', // Clerk --- Google Translate: 'Staff'
	all: '全部',

	// common
	fetch: '獲取中...',  // fetching... --- Google Translate: 'Retrieving...'
	data: '無數據',

};
