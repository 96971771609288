import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getRoutes } from '../routes';
import MainAppBar from './AppBar';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withAuth from '../hoc/withAuth';

const routes = getRoutes();
const cpaAdminRoutes = routes.cpaAdminRoutes;

const CPAAdminLayout = () => {
	const useStyles = makeStyles(() => ({
		root: {
			height: 'calc(100vh - 7.5rem)',
			width: `calc(100vw - ${isSidebarOpen ? '18rem' : '3rem'})`,
			overflow: 'auto',
			padding: '1rem',
		}
	}));
	const [isSidebarOpen, setSideBarOpen] = useState(false);
	const classes = useStyles();
	return (
		<div>
			{/* routes */}
			<MainAppBar onSidebarToggle={(opened: boolean) => { setSideBarOpen(opened); }} />
			{/* <BreadCrumb left={isSidebarOpen ? '240px' : '0px'} /> */}
			<div className="admin-routes-container" style={{ left: isSidebarOpen ? '265px' : '25px' }}>
				<Card className={classes.root}> 
					<CardContent>
						<Switch>
							{
								cpaAdminRoutes.map((val, index) => {
									return (
										!val.disabled?<Route
											key={index}
											path={val.path}
											exact={val.exact}
											component={val.component}
										/>:null
									);
								})
							}
							<Redirect from='/cpa' to='/cpa/users' />
						</Switch>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default withAuth(CPAAdminLayout);
