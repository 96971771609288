import React from 'react';

const ImportantDocument = () => {
	return (
		<>
			<div className="import-doc-container">
				<div className="doc-main">
					<h2>Constitution(MOA/AOA)</h2>
					<div className="file-bg">
						<input type="file" className="hand" />
						<p>
							<i className="fas fa-plus"></i>
						    Upload File
						</p>
					</div>
				</div>
				<hr />
				<div className="doc-main">
					<h2>Certificate Of Incorporation</h2>
					<div className="file-bg">
						<input type="file" className="hand" />
						<p>
							<i className="fas fa-plus"></i>
						Upload File
						</p>
					</div>
				</div>
				<hr />
				<div className="doc-main">
					<h2>Biz Profile</h2>
					<div className="file-bg">
						<input type="file" className="hand" />
						<p>
							<i className="fas fa-plus"></i>
						Upload File
						</p>
					</div>
				</div>
				<hr />
				<div className="doc-main">
					<h2>Group Structure</h2>
					<div className="file-bg">
						<input type="file" className="hand" />
						<p>
							<i className="fas fa-plus"></i>
						Upload File
						</p>
					</div>
				</div>
				<hr />
				<div className="doc-main">
					<h2>Annual Returns</h2>
					<div className="file-bg">
						<input type="file" className="hand" />
						<p>
							<i className="fas fa-plus"></i>
						Upload File
						</p>
					</div>
				</div>
				<hr />
				<div className="doc-main">
					<h2>Others</h2>
					<div className="file-bg">
						<input type="file" className="hand" />
						<p>
							<i className="fas fa-plus"></i>
						Upload File
						</p>
					</div>
				</div>
			</div></>
	);
};
export default ImportantDocument;
