import React ,{useState}from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import { IoArrowBackCircle } from 'react-icons/io5';
// import SalesBarChart from './salesBarChart';
import ExpenseAnalysis from '../../components/tabsView/expenseAnalysis';
import SalesAnalytics from '../../components/tabsView/salesAnalytics';
import LiquidityAnalysis from '../../components/tabsView/liquidityAnalytics';
import { useTranslation } from 'react-i18next';
  
interface LocationObject  {
		
	companyName: string;
	id: string;
	
  }
const BankClientDetails = () => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState('1');
	
	const params:LocationObject=useParams();
	
	const history = useHistory();
	const navigateBack=()=>{
		history.goBack();
        
	};
	

	const [selectedOption, setSelectedOption] = useState('6M');

	const handleOptionChange = (event:any) => {
		
		setSelectedOption(event.target.value);
	};
	return (
		<div className="container-fluid print-hide bg-white border-">
			<Nav tabs>
				<div className="container-fluid print-hide bg-white border-">
					
					<div className="d-flex w-100">
						
						<IoArrowBackCircle  onClick={navigateBack} style={{width:'5%',color:'#243a76'}} className="flex-1 h2 w2 mt-3 mb-5"/>
						<div className='d-flex w-100 justify-content-center'>
							<h3 className='mt-3' style={{color:'black'}}>{params.companyName.toLocaleUpperCase()}</h3>
						</div>
					
					</div>
					
					<div className="d-flex">
						
						
						<NavItem className='flex-fill' style={{ width: '30%' }}>
							<NavLink className={activeTab === '1' ? 'active text-center' : 'border-0 text-center'} onClick={() => setActiveTab('1')}>
								<p className={activeTab === '1' ? 'text-white mt-3 hand' : 'text-info mt-3 hand'} style={activeTab === '1' ? { fontWeight: 'bold', fontSize: '20px' } : {}}>{t("bank_client_details:sales")}</p>
							</NavLink>
						</NavItem>
						<NavItem className='flex-fill' style={{ width: '30%' }}>
							<NavLink className={activeTab === '2' ? 'active text-center' : 'border-0 text-center'} onClick={() => setActiveTab('2')}>
								<p className={activeTab === '2' ? 'text-white mt-3 hand' : 'text-info mt-3 hand'} style={activeTab === '2' ? { fontWeight: 'bold', fontSize: '20px' } : {}}>{t("bank_client_details:liquidity")}</p>
							</NavLink>
						</NavItem>
						<NavItem className='flex-fill' style={{ width: '30%' }}>
							<NavLink className={activeTab === '3' ? 'active text-center' : 'border-0 text-center'} onClick={() => setActiveTab('3')}>
								<p className={activeTab === '3' ? 'text-white mt-3 hand' : 'text-info mt-3 hand'} style={activeTab === '3' ? { fontWeight: 'bold', fontSize: '20px' } : {}}>{t("bank_client_details:expense")}</p>
							</NavLink>
						</NavItem>
					
					</div>
					
				</div>
				<div className="container-fluid print-hide m-4">
					<div className='d-flex justify-content-between'>
						{ activeTab==='1' ? <div className='mx-3'><h3>{t("bank_client_details:sale_analytics")}</h3></div>: activeTab==='2' ? <div className='mx-3'><h3>{t("bank_client_details:liquidity_analytics")}</h3></div>:<div className='mx-3'><h3>{t("bank_client_details:expense_analytics")}</h3></div>}
						<div className="d-flex ">
							
							<div className="btn-group btn-group-toggle rounded" data-toggle="buttons" style={{backgroundColor:'#243a76', pointerEvents:'none'}}>
								<div className='rounded px-3' style={{backgroundColor:'#243a76', color:'white'}}>
									<input
										type="radio"
										name="options"
										id="option1"
										autoComplete="off"
										checked={selectedOption === '6M'}
										value="6M"
										onClick={handleOptionChange}
										style={{pointerEvents:'auto',cursor:'pointer'}}
									/>
									<label className="btn active" style={{backgroundColor:'#243a76', color:'white'}} htmlFor='option1'>
									
										{t("bank_client_details:six_months")}
								 
									</label>
								</div>
								<div className='rounded px-1' style={{backgroundColor:'#243a76', color:'white'}}>
									<input
										type="radio"
										name="options"
										id="option2"
										autoComplete="off"
										checked={selectedOption === '12M'}
										value="12M"
										onClick={handleOptionChange}
										style={{pointerEvents:'all',cursor:'pointer'}}
									/>
									<label className="btn" style={{backgroundColor:'#243a76', color:'white'}} htmlFor='option2'>
									
										{t("bank_client_details:twelve_months")}
									</label>
								</div>
								
							</div>
					
						</div>
						
					</div>
					
					
				</div>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">{activeTab==='1' && <SalesAnalytics {...params} selectedOption={selectedOption} activeTab={activeTab}></SalesAnalytics>}</TabPane>
				<TabPane tabId="2">{activeTab==='2' && <LiquidityAnalysis {...params} selectedOption={selectedOption} activeTab={activeTab}></LiquidityAnalysis>}</TabPane>
				<TabPane tabId="3">{activeTab==='3' && <ExpenseAnalysis {...params} selectedOption={selectedOption} activeTab={activeTab}></ExpenseAnalysis>}</TabPane>

			</TabContent>

		</div>
	);


};
export default BankClientDetails;