import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IColumn, IFile, IPagination, ISelectOption } from '../../common/interfaces';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { DeleteData, getDataWithQuery, PostData } from '../../common/api';
import { CLIENT_PORTAL_URL, DEBOUNCE_TIME, dismissNotification, editNotification, readFileAsDataUrl, ROLES, showNotification, ValueFromUserData } from '../../common/utility';
import Button from '@material-ui/core/Button/Button';
import { useForm } from 'react-hook-form';
import CustomTable from '../../components/CustomTable';
import CustomDialog from '../../components/CustomDialog';
import InputCtrl from '../../controllers/InpuCtrl';
import SelectCtrl from '../../controllers/SelectCtrl';
import { cpaAndClientDefaultValues } from '../../constants';
import InplaceConfirm from '../../components/InplaceConfirm';
import { uploadFile2 } from '../../services/fileUpload';
import { useTranslation } from 'react-i18next';
import ChekboxCtrl from '../../controllers/ChekboxCtrl';
import { countries } from '../../constants/countries';
const useStyles = makeStyles({
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;


const CPAClients = () => {
	const { t } = useTranslation();
	// const getRandomLink = () => {
	// 	const links = [
	// 	  '/images/green-dot.png',
	// 	  '/images/orange-dot.png'
	// 	];
	  
	// 	const randomIndex = Math.floor(Math.random() * links.length);
	// 	return links[randomIndex];
	//   };
	const [pageCurrent,setPageCurrent]=useState(1);
	const columns: IColumn[] = [{
		header: t("clients:s_no"),
		isIndex: true,
		accessor: 'index'
	},
	{
		header: t("clients:company_name"),
		accessor: 'companyName'
	},
	{
		header: t("clients:company_address"),
		accessor: 'companyAddress'
	},
	{
		header: t("clients:admin_name"),
		accessor: 'name'
	},
	{
		header: t("clients:admin_email"),
		accessor: 'email'
	},
	{
		header: t("clients:relationship_balance"),
		accessor: 'relationshipBalance',
		render: (row: any) => <img src = { row?.relationshipBalance === true ? '/images/green-dot.png' :  '/images/orange-dot.png'} alt="Green Dot" width="16" height="16"></img>
        	
	},
	{
		header: t("clients:details"),
		accessor: 'details',
		render: (row: any) => {
			return <>
				<div className='d-flex justify-content-center' >
					<button className='btn p-0' onClick={(e)=>{handleLinkOpen(e,row);}}>
						<img src="/images/external-link-icon.png" width={30} height={30} alt={t("clients:view_details")}/>
					</button>
				</div>
			</>;
		}
	},
	];
	const [currentUserRole, setCurrentUserRole] = useState<number>();
	const [verify2Fa, setVerify2Fa] = useState(false);
	const [clients, setClients] = useState<{
        name: string,
        email: string,
		phoneNumber: string,
		countryCode: string,
        createdAt: string,
        _id: string;
    }[]>([]);
	const [logo, setLogo] = useState<IFile>({
		blob: null,
		tempId: '',
		fileId: '',
		fileUrl: '',
		fileName: ''
	});
	const [optionsCpa, setOptionsCpa] = useState<ISelectOption[]>([]);
	const [parentId, setParentId] = useState<string>('');
	const [page, setPage] = useState<IPagination>({
		totalItems: clients.length,
		current: 1,
		pageSize: 10,
		pageCount: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	useEffect(() => {
		const role = ValueFromUserData('role') || 0;
		setCurrentUserRole(role);
		if (role === 1) {
			fetchData(1, 1000, '', [3], '', '', (data: any) => {
				console.log(data);
				const tempList: ISelectOption[] = data.items.filter((item: any) => item.companyName).map((item: any) => ({
					label: item.companyName,
					value: item._id
				}));
				setOptionsCpa(tempList);
				fetchData();
			});
		} else {
			let cId = '';
			if(ValueFromUserData('role') === ROLES.CPAClerk){
				 cId = ValueFromUserData('_id');
			}
			const pId = (ValueFromUserData('role')=== 4 || ValueFromUserData('role')=== 5) ? ValueFromUserData('parentId') || '' : ValueFromUserData('_id') || '';
			fetchData(1, undefined, '',undefined,pId, cId);
			setParentId(pId);
		}


	}, []);
	const fetchData = async (pageNumber = page.current, pageSize = page.pageSize, accountName = querySearch, roles = [6], pId = '', cId = '', cb?: (resData: any) => void) => {
		try {
			changeApiStatus(true, '');
			const usersRes = await getDataWithQuery('user/getAll', {
				pageNumber: pageNumber,
				pageSize: pageSize,
				filter: accountName,
				roles: roles,
				parentId: pId,
				clerkId: cId
			});
			if (usersRes.status === 200) {
				changeApiStatus(false, '');
				if (cb) {
					cb(usersRes.data);
				} else {
					setPage({ ...page, totalItems: usersRes.data.totalItems });
					const updatedClients = usersRes?.data?.items?.map((client: any) => {
						if(client?.phoneNumber && client.phoneNumber[2]==='6') {
							const countryCode = client.phoneNumber.slice(0,3);
							const phoneNumber=client.phoneNumber.slice(3,client.phoneNumber.length+1);
							client.phoneNumber=phoneNumber;
							client['countryCode']=countryCode;
							return client;
						}
						else if(client?.phoneNumber && client.phoneNumber[2]==='5') {
							const countryCode = client.phoneNumber.slice(0,4);
							const phoneNumber=client.phoneNumber.slice(4,client.phoneNumber.length+1);
							client.phoneNumber=phoneNumber;
							client['countryCode']=countryCode;
							return client;
						}
						else {
							return client;
						}
					});
					setClients([...updatedClients]);

				}
			} else {
				throw new Error(usersRes.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();

	const handleSearch = (event: any) => {
		const value = event.target.value;
		if (distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, undefined, value.trim());
		}, DEBOUNCE_TIME);

	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPageCurrent(currentPage);
		setPage({ ...page, current: currentPagination });
		if (currentPage * page.pageSize > clients.length) {
			if ((clients.length > (currentPage - 1) * page.pageSize) && currentPage !== 1) {
				return;
			}
			// setFetchCount(currentPage * page.pageSize + 90);
		}
		if(ValueFromUserData('role')===1) {
			fetchData(currentPage);
		} else {
			let cId = '';
			if(ValueFromUserData('role') === ROLES.CPAClerk){
				cId = ValueFromUserData('_id');
			}
			const pId = (ValueFromUserData('role')=== 4 || ValueFromUserData('role')=== 5) ? ValueFromUserData('parentId') || '' : ValueFromUserData('_id') || '';
			fetchData(currentPage, undefined, '',undefined,pId,cId);
		}
	};

	const [clientDetails, setClientDetails] = useState<{ [x: string]: any; }>();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


	const { errors, control, handleSubmit, reset, watch } = useForm({
		mode: 'onBlur',
		defaultValues: cpaAndClientDefaultValues
	});

	const watchCpaId = watch('cpaId');

	useEffect(() => {
		if (watchCpaId || watchCpaId === '') {
			// setValue('parentId', watchCpaId);
			setParentId(watchCpaId);
			// fetchData(1, undefined, undefined, [6], watchCpaId);
		}
	}, [watchCpaId]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		reset({});
		setClientDetails({});
		setLogo({
			blob: null,
			tempId: '',
			fileId: '',
			fileUrl: '',
			fileName: ''
		});
	};

	const editDetails = (data: any) => {
		reset({ ...data });
		setClientDetails(data);
		setLogo({
			blob: null,
			tempId: '',
			fileId: data.logoId || '',
			fileUrl: data.logoFullUrl || '',
			fileName: data.logoOriginalName || ''
		});
		setEditMode(true);
		setVerify2Fa(data.verifyStatus);
		handleClickOpen();
	};

	const showError = (_fieldName: string) => {
		// if error is there show on the screen
		const error = (errors as any)[_fieldName];
		return error ? (
			<div className="error-block">{error.message || t("common:field_require")}</div>
		) : null;
	};

	const onDelete = async () => {
		// run global try catch and save data via api
		changeApiStatus(true, '');
		try {
			const result = await DeleteData('user/delete', clientDetails?._id || '');
			if (result.status === 200) {
				changeApiStatus(false, '');
				showNotification('success', result.message);
				handleClose();
				setQuery('');
				currentPagination = 1;
				setPage({ ...page, current: currentPagination });
				fetchData(1);
			} else {
				throw new Error(result.error);
			}
		}
		catch (error: any) {
			// error found
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
	};

	const onSaveClient = async (data: any) => {
		// run global try catch and save data via api
		changeApiStatus(true, '');
		try {
			if(data.countryCode && data.phoneNumber) {
				data.phoneNumber = data.countryCode + data.phoneNumber;
			} else data.phoneNumber = '';
			// set id if in editMode
			// editMode && (data.id = params.id);
			// !data.password && (data.password = '');
			const result = await PostData('user/save', { ...data, relationStatus: 'green', role: 6, id: clientDetails?._id });
			if (result.status === 200) {
				changeApiStatus(false, '');
				handleClose();
				setQuery('');
				currentPagination = 1;
				setPage({ ...page, current: currentPagination });
				// fetchData(1, page.pageSize, '');
				fetchData(pageCurrent, undefined, '', [6], parentId);
			} else {
				throw new Error(result.error);
			}
		}
		catch (error: any) {
			// error found
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}

	};

	const upload = (file: any, cb: (data: any) => void) => {
		const notificationUid = 'SaveFileUpload';
		try {
			showNotification('info', t("common:file_upload"), notificationUid);
			return uploadFile2(file, (event: any) => {
				const uploadRes = event.data;
				if (event.event === 'load') {
					editNotification(
						notificationUid,
						`Uploading ${event.progressValue}%`,
					);
				} else if (event.event === 'complete') {
					dismissNotification(notificationUid);
					if (uploadRes.status === 200) {
						cb(uploadRes.data);
						return uploadRes.data;
					} else {
						throw new Error(uploadRes.error);
					}
				}
			});
		} catch (err: any) {
			showNotification('error', err.message);
		}
	};

	const onSubmit = async (data: any) => {
		if (!clientDetails?.logoId && !logo.blob) {
			showNotification('error', t("common:upload_logo"));
			return false;
		}
		if (logo.blob) {
			changeApiStatus(true, '');
			upload(logo.blob, (result: any) => {
				onSaveClient({ ...data, logoId: result._id,bankIds:clientDetails?.bankIds });
			});
		} else {
			onSaveClient({ ...data, logoId: clientDetails?.logoId, bankIds:clientDetails?.bankIds });
		}
		setEditMode(false);
	};


	const onChangeLogo = async (e: any) => {
		try {
			if (e.target.files && e.target.files.length) {
				const [blob] = e.target.files;
				const valueOf = new Date().valueOf();
				const tempId = '' + valueOf;
				const url = await readFileAsDataUrl(blob);
				setLogo({ tempId: tempId, blob: blob, fileUrl: url, fileName: blob.name, fileId: '' });
			}
		} catch (err: any) {
			console.log(err);
		}
	};

	const handleLinkOpen=async (e:any,row:any)=>{
		e.stopPropagation();
		const result=await getTokenForRedirection(row._id);
		// Redirection
		if(result?.token){
			const url = `${CLIENT_PORTAL_URL}/#/login?data=${result.token}`;
			window.open(url, '_blank');
		}
	};

	const getTokenForRedirection=async (id:string)=>{
		try {
			const myLang = localStorage.getItem('selectedLanguage');
			const lang = myLang ? myLang : 'en';
			const result = await PostData('user/validateAdminForRedirection', {_id: id, lang: lang });
			if (result.status === 200) {
				return result.data;
			} else {
				throw new Error(result.error);
			}
		}
		catch (error: any) {
			// error found
			showNotification('error', error.message);
		}

	};

	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-md-4 col-lg-4 pl">
					<h2 className="projects-name-count">
						{t("clients:title")}
						<span>
							{t("clients:total")}{`${page.totalItems}`}
						</span>
					</h2>
				</div>
				<div className="col-4 col-md-3 col-lg-3 search-container">
					<input className="search-bar" type="text" placeholder={t("clients:search")} onChange={handleSearch} />
					<i className="fas fa-search"></i>
				</div>
				{currentUserRole === 1 ? <div className="col-4 col-md-3 col-lg-3 search-container">
					<SelectCtrl
						placeholder=''
						control={control}
						showError={showError}
						name="cpaId"
						className="border"
						componentName="client"
						disabled={apiStatus.inProgress}
						required={false}
						options={[{ label: t("clients:all"), value: '' }, ...optionsCpa]}
					/>
				</div> : ''}

				<div className="col-4 col-md-3 col-lg-2" style={{ textAlign: 'end' }}>
					<Button type="submit" variant="contained" onClick={handleClickOpen} color="primary" classes={{ containedPrimary: classes.addButton }} data-bs-toggle="modal" data-bs-target="#addClient">
						{t("clients:add")}
					</Button>
					<CustomDialog
						fullScreen={fullScreen}
						isOpen={open}
						handleClose={handleClose}
						title={editMode ? t("clients:title_edit") : t("clients:title_add")}
						handleSubmit={handleSubmit(onSubmit)}
						actions={[<Button key={'cancel'} disabled={apiStatus.inProgress} autoFocus onClick={handleClose} className="btn btn-secondary bg-no">
							{t("clients:cancel")}
						</Button>,
						<Button key={'Save'} disabled={apiStatus.inProgress} type="submit" className="btn btn-primary blue-btn" autoFocus>
							{t("clients:save")}
						</Button>]}  >
						<div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("clients:company_name")}
									placeholder={t("clients:enter_company_name")}
									type='text'
									control={control}
									showError={showError}
									name="companyName"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("clients:company_address")}
									placeholder={t("clients:enter_company_address")}
									type='text'
									control={control}
									showError={showError}
									name="companyAddress"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<label htmlFor="uploadLogo" className='w-50'><i className="fas fa-asterisk red-start"></i>{t("clients:logo")}</label>
								<label
									className="btn btn-outline-primary shadow-none text-primary text-center bg-transparent w-50"
									htmlFor='uploadLogo'>
									{logo?.fileName ? logo.fileName : t("clients:upload_logo")}
								</label>
								<input
									type='file'
									accept='image/*'
									name='logo'
									id='uploadLogo'
									onChange={onChangeLogo}
									hidden
									multiple
								/>
							</div>
							<div className='mb-4'><h5>{t("clients:admin")}</h5></div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("clients:first_name")}
									placeholder={t("clients:enter_first_name")}
									type='text'
									control={control}
									showError={showError}
									name="firstName"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("clients:last_name")}
									placeholder={t("clients:enter_last_name")}
									type='text'
									control={control}
									showError={showError}
									name="lastName"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={false}
									ctrlClasses="inp-err-wrap"
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<InputCtrl
									label={t("clients:email")}
									placeholder={t("clients:enter_email")}
									type='email'
									control={control}
									showError={showError}
									name="email"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<InputCtrl
									label={t("clients:password")}
									placeholder={t("clients:enter_password")}
									type='password'
									control={control}
									showError={showError}
									name="password"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={editMode ? false : true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className='d-flex align-items-center justify-content-between w-100 mb-4'>
								<SelectCtrl
									label={t("bank:country_code")}
									control={control}
									showError={showError}
									placeholder=''
									className="border"
									name="countryCode"
									required={verify2Fa}
									// className="form-control"
									disabled={apiStatus.inProgress}
									options={countries}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("clients:phone_no")}
									placeholder='XXXXXXXXXXX'
									type='phoneNumber'
									control={control}
									showError={showError}
									name="phoneNumber"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={verify2Fa}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							{currentUserRole === 1 ? <div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<SelectCtrl
									label={t("clients:firm")}
									placeholder=''
									control={control}
									showError={showError}
									name="parentId"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									options={[{ label: t("clients:select"), value: '' }, ...optionsCpa]}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div> : ''}
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<ChekboxCtrl
									name="verifyStatus"
									control={control}
									label={t("common:enable_2fa")}
									showError={showError}
									className='border'
									required={false}
									disabled={apiStatus.inProgress}
									ctrlClasses="inp-err-wrap"
									onChange={() => setVerify2Fa(!verify2Fa)}
									// showStar
								/>
                        	</div>
						</div>
						{
							editMode ? (
								<div className="col-md-1">
									<InplaceConfirm
										HTMLComponent={<img alt="img" id="del-icon" src="/images/Group 1718.png" />}
										Action={onDelete}
										target="del-icon"
									/>
								</div>


							) : null
						}
					</CustomDialog>
				</div>
			</div>
			<div className="card-main-content">
				<CustomTable columns={columns} rows={clients} apiStatus={apiStatus} page={page} onRowClick={editDetails}/>
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default CPAClients;
