// Here we have added our translations

export default {
	// Table Headers
	s_no: '序號', // Serial Number
	bank_name: '銀行名稱',
	bank_address: '銀行地址',
	admin_name: '管理員名稱',
	admin_email: '管理員電子郵件', // Admin Email --- Google Translate: 'Administrator email'
	added: '添加於', // Added On --- Google Translate: 'Added to'
	country_code: '國家代碼',

	// title
	title: '銀行',
	total: '總計',
	title_add: '新增銀行', // Title: Add Bank
	title_edit: '編輯銀行', // Title Edit Bank
	search: '搜尋',
	add: '新增',  // Add --- Google Translate: 'New'

	// Forms Data
	logo: '標誌',
	upload_logo: '上傳標誌',
	admin: '管理員', // Admin --- Google Translate: 'administrator'
	first_name: '名字', // First Name --- Google Translate: 'name'
	last_name: '姓氏',
	email: '電子郵件',
	password: '密碼',
	phone_no: '電話號碼',
	enter_bank_name: '輸入銀行名稱',
	enter_bank_address: '輸入銀行地址',
	enter_first_name: '輸入名字',
	enter_last_name: '輸入姓氏',
	enter_email: '輸入電子郵件',
	enter_password: '輸入密碼',
	enter_phone_no: '輸入電話號碼',
	assign_clients: '分配客戶',
	save: '保存', // Save --- Google Translate: 'Keep'
	cancel: '取消',

	// common
	fetch: '獲取中...', // fetching... --- Google Translate: 'Retrieving...'
	data: '無數據',
	all: '全部',

};
