import React, { useEffect, useState } from 'react';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IColumn, IPagination } from '../../common/interfaces';
import { getDataWithQuery } from '../../common/api';
import { DEBOUNCE_TIME, ROLES, showNotification, ValueFromUserData } from '../../common/utility';
import CustomTable from '../../components/CustomTable';
import { useHistory,useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

let currentPagination = 1;
let distinctUntilChange: any = null;


const BankClients = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	const columns: IColumn[] = [{
		header: t("bank_clients:s_no"),
		isIndex: true,
		accessor: 'index'
	},
	{
		header: t("bank_clients:company_name"),
		accessor: 'companyName'
	},
	{
		header: t("bank_clients:company_address"),
		accessor: 'companyAddress'
	},
	{
		header: t("bank_clients:avg_sale_revenue"),
		accessor: 'averageMonthlySalesValue'
	},
	{
		header: t("bank_clients:avg_bank_revenue"),
		accessor: 'averageClosingBalanceValue'
	}
	// {
	// 	header: 'Bank Balance to STO Ratio (BAL/STO)',
	// 	accessor: 'relationshipBalance',
	// 	// render: (row: any) => <img src = { row?.relationshipBalance === true ? '/images/green-dot.png' :  '/images/orange-dot.png'} alt="Green Dot" width="16" height="16"></img>
        	
	// }
	];
	const [clients, setClients] = useState<{
        name: string,
        email: string,
        createdAt: string,
        _id: string;
    }[]>([]);

	const [page, setPage] = useState<IPagination>({
		totalItems: clients.length,
		current: 1,
		pageSize: 10,
		pageCount: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});

	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	useEffect(() => {
		if(ValueFromUserData('role') === ROLES.bankAdmin){
			const bId = ValueFromUserData('_id');
			fetchData(1, undefined, '', undefined, bId);
		} else if ( ValueFromUserData('role') === ROLES.bankTeamMember){
			const bUId = ValueFromUserData('_id');
			fetchData(1, undefined, '', undefined, '', bUId);
		}
		
	}, []);

	const fetchData = async (pageNumber = page.current, pageSize = page.pageSize, accountName = querySearch, roles = [6], bId = '', bUId = '', cb?: (resData: any) => void) => {
		try {
			changeApiStatus(true, '');
			const usersRes = await getDataWithQuery('user/getAll', {
				pageNumber: pageNumber,
				pageSize: pageSize,
				filter: accountName,
				roles: roles,
				bankId: bId,
				bankUserId: bUId,
			});
			if (usersRes.status === 200) {
				changeApiStatus(false, '');
				if (cb) {
					cb(usersRes.data);
				} else {
					setPage({ ...page, totalItems: usersRes.data.totalItems });
					setClients(usersRes.data.items);
				}
			} else {
				throw new Error(usersRes.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};
	useEffect(() => {
		// const currentPath = location;
		// console.log('currentPath', currentPath);
	}, [location]);
	// const classes = useStyles();
	const handleClickOpen = (e:any) => {
		
		if(ValueFromUserData('role') === 8 || ValueFromUserData('role') === 9){
			// history.push('/banks/clients/client-details',{userId:true});
			history.push({
				pathname: `/bank/clients/details/${e._id}/${e.companyName}`,
				state: { userId: e._id, clientName:e.companyName  }
			});
		}
		// if(ValueFromUserData('role') !== 9)
	};
	const handleSearch = (event: any) => {
		const value = event.target.value;
		if (distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			if(ValueFromUserData('role') === ROLES.bankAdmin){
				fetchData(1, undefined, value.trim(),undefined,ValueFromUserData('_id'));
			}else if (ValueFromUserData('role') === ROLES.bankTeamMember){
				fetchData(1, undefined, value.trim(), undefined, '', ValueFromUserData('_id'));
			}
			// fetchData(1, undefined, value.trim());
		}, DEBOUNCE_TIME);

	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		if (currentPage * page.pageSize > clients.length) {
			// if (clients.length > (currentPage - 1) * page.pageSize) {
			// 	return;
			// }
			// setFetchCount(currentPage * page.pageSize + 90);
		}
		if(ValueFromUserData('role')=== ROLES.bankAdmin){
			const bId = ValueFromUserData('_id');
			fetchData(currentPage, undefined, querySearch, undefined, bId);
		} else if ( ValueFromUserData('role') === ROLES.bankTeamMember){
			const bUId = ValueFromUserData('_id');
			fetchData(currentPage, undefined, querySearch, undefined, '', bUId);
		}
		// fetchData(currentPage,undefined, querySearch, undefined,ValueFromUserData('_id'));
	};

	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-md-4 col-lg-4 pl">
					<h2 className="projects-name-count">
						{t("bank_clients:title")}
						<span>
							{t("bank_clients:total")}{`${page.totalItems}`}
						</span>
					</h2>
				</div>
				<div className="col-4 col-md-3 col-lg-3 search-container">
					<input className="search-bar" type="text" placeholder={t("bank_clients:search")} onChange={handleSearch} />
					<i className="fas fa-search"></i>
				</div>

			</div>
			<div className="card-main-content">
				<CustomTable columns={columns} rows={clients} apiStatus={apiStatus} page={page} onRowClick={handleClickOpen} />
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default BankClients;
