import React from 'react';
import { useEffect, useState  } from 'react';
import { IApiCallStatus, IPagination_, IPieChartProps } from '../../common/interfaces';
import { getDataWithQuery } from '../../common/api';
import PieChart from '../chartsComponent/pieChart';
import SalesBarChart from '../chartsComponent/salesBarChart';
import { useTranslation } from 'react-i18next';

const salesAnalysis=(props:any)=>{
	const { t } = useTranslation();
	const startDate: any = '';
	const endDate: any = '';
	
	const dateRangeId = props.selectedOption==='12M' ?  '3492e5a1a4e932m2ce48d482':'6289e5b2a4e646f2wv48d4f0';
	
	const [uploadedTransactions, setUploadedTransactions] = useState<any[]>([]);
	const [countChart, setCountChart] = useState<IPieChartProps>({
		title: '', labels: [], values: [], backgroundColor: []
	});
	// const [chartSubTitle, setChartSubTitle] = useState<string>('');
	// const [sumMonthlySales, setSumMonthlySales] = useState<string>('');
	const [averageMonthlySales, setAverageMonthlySales] = useState<string>('');
	const [invalidData, setInvalidData] = useState(false);
	const [res, setRes] = useState({});
    
	const DEFAULT_PAGE_SIZE = 100000;

	const [page, setPage] = useState<IPagination_>({
		totalItems: 0,
		current: 1,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNumber: 0,
		query: ''
	});

	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});

	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};
	const getAllTransaction = (data: any) => {
		return getDataWithQuery('trans/getSalesData', data);
	};

	const fetchData = async (
		pageNumber = page.current,
		query = page.query ?? '',
		pageSize = page.pageSize,
		downloadPdf = false,
        
		cb?: (data: any) => void
	) => {
		try {
			changeApiStatus(true, '');
			const data = {
				query,
				pageNumber,
				pageSize,
				dateRangeId: dateRangeId || undefined,
				startDate: !dateRangeId && startDate ? startDate : undefined,
				endDate: !dateRangeId && endDate ? endDate : undefined,
				downloadPdf,
				id: props?.id,

			};
			
			const usersResponse = await getAllTransaction(data);
			
			if (usersResponse.status === 200) {
				setPage({
					...page,
					current: pageNumber,
					totalItems: usersResponse.data.totalItems
				});
				setRes(usersResponse.data);
				const resData = usersResponse.data;
				
               
				if(resData.countChart.length===0){
					setInvalidData(true);
				}
				
				if (resData.countChart) {
				

					setCountChart(resData.countChart);
				}
				if (resData.averageMonthlySalesValue) {
					setAverageMonthlySales(resData.averageMonthlySalesValue);
				}
				// if (resData.sumMonthlySalesValue) {
				// 	setSumMonthlySales(resData.sumMonthlySalesValue);
				// }
				
				// if (resData.chartTitle) {
				// 	setChartSubTitle(resData.chartTitle);
				// }
				if (cb) {
					cb(usersResponse.data);
				}
				setUploadedTransactions(resData.items);
				changeApiStatus(false, '');
			} else if(usersResponse.status !== 200){
				setInvalidData(true);
				

			} else {
				throw new Error(usersResponse.error);
			}
		} catch (error: any) {
			changeApiStatus(false, error.message);
			// showNotification('error', error.message);
		}
	};
	useEffect(() => {
       
		fetchData();
        
	}, [dateRangeId]);
	
	return (
		<>
			{invalidData===false ?<div className='transation-list ml-5 mx-5  '>
				{ apiStatus.inProgress
					? t("tabs_view:loading")
					: (<>
						<div className='page-body'>
							{/* <ScrollToTop
							smooth
							className='ScrollToTop'
							component={
								<i className='bi bi-arrow-up-circle'></i>
							}
						/> */}
						
							{uploadedTransactions.length
								? <>
									<span><h6 className='mt-3'>{t("tabs_view:avg_monthly_sales")}: {averageMonthlySales}</h6></span>
								</>
								: ''}
						
						
							{uploadedTransactions.length ? <SalesBarChart {...res} {...countChart} activeTab={props.activeTab} /> : ''}
							{uploadedTransactions.length ? <PieChart {...countChart} /> : ''}
						
							<br />
							<br />
                       
						</div>
					</>
					)}
			</div>:<div className='mx-1' style={{height:'100vh'}}><h6 className='mx-5 mt-3'>{t("tabs_view:no_data_found")}</h6></div>}

		</>
		
	);
    
}; 
export default salesAnalysis;