// Here we have added our translations

export default {
	sales: '銷售分析',
	liquidity: '流動性分析',
	expense: '費用分析',
	sale_analytics: '銷售數據分析',
	liquidity_analytics: '流動性數據分析', // Liquidity Analytics --- Google Transalte: 'Lob like data analysis'
	expense_analytics: '費用數據分析', // Expense Analytics --- Google Translate: 'Fee data analysis'
	six_months: '6個月',
  	twelve_months: '12個月',
};
