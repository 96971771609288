import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../components/Pagination';
import { IApiCallStatus, IColumn, IPagination, ISelectOption } from '../../common/interfaces';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { DeleteData, getDataWithQuery, PostData } from '../../common/api';
import { DEBOUNCE_TIME, ROLES, showNotification, StandardDateFormat, ValueFromUserData } from '../../common/utility';
import Button from '@material-ui/core/Button/Button';
import { useForm } from 'react-hook-form';
import InplaceConfirm from '../../components/InplaceConfirm';
import CustomTable from '../../components/CustomTable';
import InputCtrl from '../../controllers/InpuCtrl';
import CustomDialog from '../../components/CustomDialog';
import SelectCtrl from '../../controllers/SelectCtrl';
import MultipleSelect from '../../controllers/MultiSelectCtrl';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChekboxCtrl from '../../controllers/ChekboxCtrl';
import { countries } from '../../constants/countries';
const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});
let currentPagination = 1;
let distinctUntilChange: any = null;

const BankMembers = () => {
	const { t } = useTranslation();
	const columns: IColumn[] = [{
		header: t("bank_members:s_no"),
		isIndex: true,
		accessor: 'index'
	},
	// {
	// 	header: 'Company Name',
	// 	accessor: 'companyName'
	// },
	{
		header: t("bank_members:name"),
		accessor: 'name'
	},
	{
		header: t("bank_members:email"),
		accessor: 'email'
	},
	{
		header: t("bank_members:added"),
		accessor: 'createdAt',
		render: (row: any) => StandardDateFormat(row.createdAt)
	}];
	
	const [verify2Fa, setVerify2Fa] = useState(false);
	const location = useLocation();
	const [currentUserRole, setCurrentUserRole] = useState<number>();
	const [optionsBank, setoptionsBank] = useState<ISelectOption[]>([]);
	const [users, setUsers] = useState<{
        name: string,
        email: string,
		phoneNumber: string,
		countryCode: string,
        createdAt: string,
        _id: string;
    }[]>([]);

	const [page, setPage] = useState<IPagination>({
		totalItems: users.length,
		current: 1,
		pageSize: 10,
		pageCount: 0
	});
	const [querySearch, setQuery] = useState('');
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	const [parentId, setParentId] = useState<string>('');
	console.log(parentId);
	// const [fetchCount, setFetchCount] = useState(100);
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	useEffect(() => {
		const currentPath = location;
		console.log('currentPath', currentPath);
	}, [location]);

	useEffect(() => {
		const role = ValueFromUserData('role') || 0;
		// const pId = ValueFromUserData('parentId') || '';
		setCurrentUserRole(role);
		if (role === 1) {
			fetchData(1, 1000, '', [8], '', (data: any) => {
				const tempList: ISelectOption[] = data.items.filter((item: any) => item.companyName).map((item: any) => ({
					label: item.companyName,
					value: item._id
				}));
				console.log(tempList);
				setoptionsBank(tempList);
				fetchData();
			});
		} else {
			const pId = (ValueFromUserData('role')===9 )? ValueFromUserData('parentId') || '':ValueFromUserData('_id') || '';
			fetchData(1, undefined, '', undefined, pId);
			setParentId(pId);
		}
	}, []);
	const fetchData = async (pageNumber = page.current, pageSize = page.pageSize, accountName = querySearch, roles = [9], pId = '', cb?: (resData: any) => void) => {
		try {
			changeApiStatus(true, '');
			const usersRes = await getDataWithQuery('user/getAll', {
				pageNumber: pageNumber,
				pageSize: pageSize,
				filter: accountName,
				roles: roles,
				parentId: pId
			});
			console.log(usersRes);
			if (usersRes.status === 200) {
				changeApiStatus(false, '');
				if (cb) {
					cb(usersRes.data);
				} else {
					setPage({ ...page, totalItems: usersRes.data.totalItems });
					const updatedUsers = usersRes?.data?.items?.map((bankUser: any) => {
						if(bankUser?.phoneNumber && bankUser?.phoneNumber[2]==='6') {
							const countryCode = bankUser.phoneNumber.slice(0,3);
							const phoneNumber=bankUser.phoneNumber.slice(3,bankUser.phoneNumber.length+1);
							bankUser.phoneNumber=phoneNumber;
							bankUser['countryCode']=countryCode;
							return bankUser;
						}
						else if(bankUser?.phoneNumber && bankUser.phoneNumber[2]==='5') {
							const countryCode = bankUser.phoneNumber.slice(0,4);
							const phoneNumber=bankUser.phoneNumber.slice(4,bankUser.phoneNumber.length+1);
							bankUser.phoneNumber=phoneNumber;
							bankUser['countryCode']=countryCode;
							return bankUser;
						}
						else {
							bankUser['countryCode']='';
							return bankUser;
						}
					});
					setUsers([...updatedUsers]);
				}
			} else {
				throw new Error(usersRes.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const classes = useStyles();

	const handleSearch = (event: any) => {
		const value = event.target.value;
		if (distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			if(ValueFromUserData('role') === ROLES.bankAdmin || ValueFromUserData('role') === ROLES.bankTeamMember){
				ValueFromUserData('role') === ROLES.bankAdmin ? fetchData(1,undefined, value.trim(), undefined, ValueFromUserData('_id')) : fetchData(1,undefined, value.trim(), undefined, ValueFromUserData('parentId'));
			}else{
				fetchData(1,undefined, value.trim());
			}
		}, DEBOUNCE_TIME);

	};
	const onPageChange = (e: any, currentPage: any) => {
		currentPagination = currentPage;
		setPage({ ...page, current: currentPagination });
		if (currentPage * page.pageSize > users.length) {
			// if (users.length > (currentPage - 1) * page.pageSize) {
			// 	return;
			// }
			// setFetchCount(currentPage * page.pageSize + 90);
		}
		let pId = '';
		if(ValueFromUserData('role')===9 ){
			pId = ValueFromUserData('parentId');
		} else if(ValueFromUserData('role')===8 ){
			pId = ValueFromUserData('_id');
		}
		fetchData(currentPage,undefined,querySearch,undefined,pId);
	};

	const [userDetails, setUserDetails] = useState<{ [x: string]: any; }>();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [clientIds,setClientIds] = useState<string[]>([]);
	const { errors, control, handleSubmit, reset, watch, setValue } = useForm({
		mode: 'onBlur',
		defaultValues: {
			bankId: '',
			parentId: '',
			name: '',
			email: '',
			role: 0,
		}
	});

	const handleClickOpen = () => {
		if(ValueFromUserData('role') !== 9)
			setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setEditMode(false);
		setUserDetails({});
		reset({});
	};

	const editDetails = (data: any) => {
		reset({ ...data });
		setUserDetails(data);
		setEditMode(true);
		setVerify2Fa(data.verifyStatus);
		handleClickOpen();
	};

	const watchBankId = watch('bankId');

	useEffect(() => {
		if (watchBankId || watchBankId === '') {
			setValue('parentId', watchBankId);
			setParentId(watchBankId);
			// fetchData(1, undefined, undefined, [9], watchBankId);
		}
	}, [watchBankId]);

	const showError = (_fieldName: string) => {
		// if error is there show on the screen
		const error = (errors as any)[_fieldName];
		return error ? (
			<div className="error-block">{error.message || t("common:field_require")}</div>
		) : null;
	};

	const onDelete = async () => {
		// run global try catch and save data via api
		changeApiStatus(true, '');
		try {
			const result = await DeleteData('user/delete', userDetails?._id || '');
			if (result.status === 200) {
				changeApiStatus(false, '');
				showNotification('success', result.message);
				handleClose();
				setQuery('');
				currentPagination = 1;
				setPage({ ...page, current: currentPagination });
				ValueFromUserData('role')===1 ? fetchData(1, undefined, '', undefined):fetchData(1, undefined, '', undefined, ValueFromUserData('_id'));
			} else {
				throw new Error(result.error);
			}
		}
		catch (error: any) {
			// error found
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
	};

	const onSubmit = async (data: any) => {
		// run global try catch and save data via api

		console.log(data);
		changeApiStatus(true, '');
		try {
			if(data.countryCode && data.phoneNumber) {
				data.phoneNumber = data.countryCode + data.phoneNumber;
			} else data.phoneNumber = '';
			// set id if in editMode
			// editMode && (data.id = params.id);
			// !data.password && (data.password = '');
			let pID = '';
			data.parentId? pID =data.parentId:pID = ValueFromUserData('_id');
			console.log(pID);
			const options = { ...data, role: +data.role || +[9], id: userDetails?._id || undefined, parentId: pID};
			if(clientIds){
				// const filteredClientIds = clientIds.filter(value => value !== null);
				// if(filteredClientIds){
				// 	options.client=filteredClientIds;
				// }
				options.client = clientIds;
			}
			const result = await PostData('user/save', options);
			if (result.status === 200) {
				changeApiStatus(false, '');
				handleClose();
				setQuery('');
				currentPagination = 1;
				setPage({ ...page, current: currentPagination });
				ValueFromUserData('role')===1 ? fetchData(1, undefined, '', undefined):fetchData(1, undefined, '', undefined, ValueFromUserData('_id'));
			} else {
				throw new Error(result.error);
			}
			setEditMode(false);
			setClientIds([]);
		}
		catch (error: any) {
			// error found
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}

	};

	return (
		<div className="card-main-custom project-container">
			<div className="row header-row">
				<div className="col-md-4 col-lg-4 pl">
					<h2 className="projects-name-count">
						{t("bank_members:title")}
						<span>
							{t("bank_members:total")}{`${page.totalItems}`}
						</span>
					</h2>
				</div>
				{/* <div className="col-4 col-md-3 col-lg-3" style={{ textAlign: 'end' }}>
					<Button onClick={() => { handleUpload; }} disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.addButton }}>
                        Upload
					</Button>
				</div> */}
				<div className="col-3 col-md-3 col-lg-3 search-container">
					<input className="search-bar" type="text" placeholder={t("bank_members:search")} onChange={handleSearch} />
					<i className="fas fa-search"></i>
				</div>
				{currentUserRole === 1 ? <div className="col-2 col-md-3 col-lg-3 search-container">
					<SelectCtrl
						placeholder=''
						control={control}
						showError={showError}
						name="bankId"
						className="border"
						componentName="client"
						disabled={apiStatus.inProgress}
						required={false}
						options={[{ label: t("bank_members:all"), value: '' }, ...optionsBank]}
					/>
				</div> : ''}
				<div className="col-3 col-md-3 col-lg-2" style={{ textAlign: 'end' }}>
					<Button type="submit" variant="contained" onClick={handleClickOpen} color="primary" disabled={ValueFromUserData('role') === 9} classes={{ containedPrimary: classes.addButton }} data-bs-toggle="modal" data-bs-target="#addClient">
						{t("bank_members:add")}
					</Button>
					<CustomDialog
						fullScreen={fullScreen}
						isOpen={open}
						handleClose={handleClose}
						title= {editMode ? t("bank_members:title_edit") : t("bank_members:title_add")}
						handleSubmit={handleSubmit(onSubmit)}
						actions={[<Button key={'cancel'} disabled={apiStatus.inProgress} autoFocus onClick={handleClose} className="btn btn-secondary bg-no">
							{t("bank_members:cancel")}
						</Button>,
						<Button key={'Save'} disabled={apiStatus.inProgress} type="submit" className="btn btn-primary blue-btn" autoFocus>
							{t("bank_members:save")}
						</Button>]}  >
						<div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("bank_members:name")}
									placeholder={t("bank_members:enter_name")}
									type='text'
									control={control}
									showError={showError}
									name="name"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<InputCtrl
									label={t("bank_members:email")}
									placeholder={t("bank_members:enter_email")}
									type='email'
									control={control}
									showError={showError}
									name="email"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<InputCtrl
									label={t("bank_members:password")}
									placeholder={t("bank_members:enter_password")}
									type='password'
									control={control}
									showError={showError}
									name="password"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={editMode ? false : true}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							{/* <div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<SelectCtrl
									label='Role'
									placeholder=''
									control={control}
									showError={showError}
									name="role"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									ctrlClasses="inp-err-wrap"
									showStar
									options={[
										{
											value: 0,
											label: 'Please select'
										},
										{
											value: 4,
											label: 'Auditor',
										},
										{
											value: 5,
											label: 'Clerk',
										},
									]}
								/>
							</div> */}
							{currentUserRole === 1 ? <div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<SelectCtrl
									label={t("bank_members:bank")}
									placeholder=''
									control={control}
									showError={showError}
									name="parentId"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={true}
									options={[{ label: t("bank_members:select"), value: '' }, ...optionsBank]}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div> : ''}
							<div className='d-flex align-items-center justify-content-between w-100 mb-4'>
								<SelectCtrl
									label={t("bank:country_code")}
									control={control}
									showError={showError}
									placeholder=''
									className="border"
									name="countryCode"
									required={verify2Fa}
									// className="form-control"
									disabled={apiStatus.inProgress}
									options={countries}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
								<InputCtrl
									label={t("common:phone_no")}
									placeholder='XXXXXXXXXXX'
									type='phoneNumber'
									control={control}
									showError={showError}
									name="phoneNumber"
									className="border"
									componentName="client"
									disabled={apiStatus.inProgress}
									required={verify2Fa}
									ctrlClasses="inp-err-wrap"
									showStar
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between w-100 mb-4">
								<ChekboxCtrl
									name="verifyStatus"
									control={control}
									label={t("common:enable_2fa")}
									showError={showError}
									className='border'
									required={false}
									disabled={apiStatus.inProgress}
									ctrlClasses="inp-err-wrap"
									onChange={() => setVerify2Fa(!verify2Fa)}
									// showStar
								/>
                        	</div>
						</div>
						{editMode ? <div className="d-flex align-items-center justify-content-between w-100 mb-4">
							<label className="d-flex align-items-center justify-content-between w-100 mb-4">{t("bank_members:assign_clients")}</label>
							<MultipleSelect  setClientIds={setClientIds} clientIds={clientIds} entityDetails={userDetails}/>
						</div> : '' }
						{
							editMode ? (
								<div className="col-md-1">
									<InplaceConfirm
										HTMLComponent={<img alt="img" id="del-icon" src="/images/Group 1718.png" />}
										Action={onDelete}
										target="del-icon"
									/>
								</div>
							) : null
						}
					</CustomDialog>
				</div>
			</div>
			<div className="card-main-content">
				<CustomTable columns={columns} rows={users} apiStatus={apiStatus} page={page} onRowClick={editDetails} />
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
						onChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default BankMembers;
