// Here we have added our translations

export default {
	cat_name: 'Category Name', // Category Name
	expense_count: 'Expense Count',
	amount: 'Amount',
	loading: 'Loading...',
	latest: 'Latest',
	avg_closing_balance: 'Average Closing balance',
	no_data_found: 'No Data Found',
	avg_monthly_sales: 'Average Monthly Sales',

	// Messages
	messages: {
		'Not Found': 'Not Found', 
	},

};
