// Here we have added our translations

export default {
	// Table Headers
	s_no: 'S.no', // Serial Number
	bank_name: 'Bank Name',
	bank_address: 'Bank Address',
	admin_name: 'Admin Name',
	admin_email: 'Admin Email',
	added: 'Added On',

	// title
	title: 'Banks',
	total: 'Total',
	title_add: 'Add Bank', // Title: Add Bank
	title_edit: 'Edit Bank', // Title Edit Bank
	search: 'Search',
	add: 'Add',
	country_code: 'Country Code',

	// Forms Data
	logo: 'Logo',
	upload_logo: 'Upload Logo',
	admin: 'Admin',
	first_name: 'First Name',
	last_name: 'Last Name',
	email: 'Email',
	password: 'Password',
	phone_no: 'Phone Number',
	enter_bank_name: 'Enter Bank Name',
	enter_bank_address: 'Enter Bank Address',
	enter_first_name: 'Enter First Name',
	enter_last_name: 'Enter Last Name',
	enter_email: 'Enter Email',
	enter_password: 'Enter Password',
	enter_phone_no: 'Enter Phone Number',
	assign_clients: 'Assign Clients',
	save: 'Save',
	cancel: 'Cancel',

	// common
	fetch: 'fetching...',
	data: 'No Data',
	all: 'All',

};
