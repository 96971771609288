// import { FormControl, MenuItem, OutlinedInput, Select, Theme, useTheme, } from '@material-ui/core';
// import { useTheme, } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { showNotification, ValueFromUserData, ROLES } from '../common/utility';
import { getDataWithQuery } from '../common/api';
import Multiselect from 'multiselect-react-dropdown';
import './styles.css';
import { useTranslation } from 'react-i18next';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
// 	PaperProps: {
// 		style: {
// 			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// 			width: 250,
// 		},
// 	},
// };


// function getStyles(name: string, personName: string[], theme: Theme) {
// 	return {
// 		fontWeight:
// 			personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
// 	};
// }
  interface MultipleSelectProps {
	setClientIds: (clientId: string[]) => void;
	clientIds: string[];
	entityDetails: any;
	}

const MultipleSelect:React.FC<MultipleSelectProps>=({ setClientIds, clientIds, entityDetails }) =>{
	const { t } = useTranslation();
	const [clients, setClients] = useState<{
		name: string,
		id: string;
	}[]>([]);
	const [isDataFetched, setDataFetched] = useState(false);
	const [selectedClients,setSelectedClients] = useState([]);

	const handleSelect = (selectedList: any) => {
		setSelectedClients(selectedList);
		setClientIds(selectedList.map((client: any) => client.id));
	  };

	const handleRemove = (selectedList: any, selectedItem: any) => {
		console.log('selectedItem', selectedItem, selectedList);
		setSelectedClients(selectedList);
		setClientIds(selectedList.map((client: any) => client.id));
		if(!clients.some(obj => Object.values(obj).includes(selectedItem.name))){
			console.log('inner there');
			setClients((prev) => [selectedItem,...prev]);
		}
	  };

	useEffect(() => {
		let pID = '';
		console.log('entitydetails Role:', entityDetails.role);
		if (ValueFromUserData('role') === ROLES.CPAAuditor) {
			pID = ValueFromUserData('parentId');
		} else if(ValueFromUserData('role') === ROLES.admin) {
			console.log('user Role in if-else:', ValueFromUserData('role'));
			pID = entityDetails?.parentId;
		}

		 else {
			console.log('user Role:', ValueFromUserData('role'));
			pID = ValueFromUserData('_id');
		}
		if(entityDetails.role === ROLES.bankAdmin) {
			console.log('in BankAdmin here --->');
			fetchData(1, undefined, '', undefined);
		} else if(entityDetails.role === ROLES.bankTeamMember) {
			console.log('bank - user --->', entityDetails);
			fetchData(1, undefined, '', undefined, '', entityDetails.bankId);
		}
		else {
			fetchData(1, undefined, '', undefined, pID);
		}
		console.log(clientIds);
	}, []);
	const fetchData = async (pageNumber = 1, pageSize = 1000, accountName = '', roles = [6], pId = '', bankId = '', cb?: (resData: any) => void) => {
		try {
			const usersRes = await getDataWithQuery('user/getAll', {
				pageNumber: pageNumber,
				pageSize: pageSize,
				filter: accountName,
				roles: roles,
				parentId: pId,
				bankId: bankId
			});
			if (usersRes.status === 200) {
				if (cb) {
					cb(usersRes.data);
				} else {
					console.log('usersRESDATA:', usersRes?.data?.items);
					let clientsList = [];
					if(entityDetails.role === ROLES.bankAdmin) {
						clientsList =  usersRes.data.items.map((item: any) => { return {name: item.name, id: item._id};});
					} else if(entityDetails.role === ROLES.bankTeamMember) {
						// clientsList =  usersRes.data.items.filter((value: any) => value.bankId === entityDetails.bankId) .map((item: any) => { return {name: item.name, id: item._id};});
						clientsList =  usersRes.data.items.filter((item: any) => !item.bankUserId && item.bankIds && item.bankIds.includes(entityDetails.bankId)) .map((item: any) => { return {name: item.name, id: item._id};});
					}
					else {
						clientsList =  usersRes.data.items.filter((value: any) => !value.clerkId) .map((item: any) => { return {name: item.name, id: item._id};});
					}
			        // const clientsList =  usersRes.data.items.map((item: any) => { return {name: item.name, id: item._id};});
					setClients(clientsList);
					let filteredClients = [];
					if( entityDetails.role === ROLES.bankAdmin ) {
						filteredClients=usersRes.data.items.filter((item: any) => item.bankIds && item.bankIds.includes(entityDetails._id));
					} else if( entityDetails.role === ROLES.bankTeamMember) {
						filteredClients=usersRes.data.items.filter((item: any) => item.bankUserId === entityDetails._id);
					}
					else {
						filteredClients=usersRes.data.items.filter((item: any) => item.clerkId === entityDetails._id);
					}
					const selectedData=filteredClients.map((item: any) => { return {name:item.name,id:item._id};});
					setSelectedClients(selectedData);
					let filteredIds = [];
					if (entityDetails.role === ROLES.bankAdmin) {
						console.log('filtredClients in MultiControl:', filteredClients);
						filteredIds=filteredClients.map((item: any) => item._id);
						console.log('filteredIds in MultiControl:', filteredIds);
					} else if (entityDetails.role === ROLES.bankTeamMember) {
						filteredIds=filteredClients.map((item: any) => item._id);
					} else if (entityDetails.role === ROLES.CPAClerk) {
						filteredIds=filteredClients.map((item: any) => item._id);
					}
					setClientIds(filteredIds);
					setDataFetched(true);
				}
			} else {
				throw new Error(usersRes.error);
			}
		}
		catch (err: any) {
			showNotification('error', err.message);
		}
	};

	// const theme = useTheme();
	

	// const handleChange = (event: any) => {
	// 	console.log('Clients   ', clients);
	// 	console.log('Event', event);
	// 	const {
	// 		target: { value },
	// 	} = event;
	// 	console.log('Client Ids', clientId);
	// 	setClientId(
	// 		// On autofill we get a stringified value.
	// 		typeof value === 'string' ? value.split(',') : value,
	// 	);
	// };

	return (
		<div>
		  {isDataFetched && (
				<div style={{ width: '265px' }}>
			  {clients.length > 0 ? (
						<Multiselect
				  avoidHighlightFirstOption={true}
				  options={clients}
				  selectedValues={selectedClients}
				  onSelect={handleSelect}
				  onRemove={handleRemove}
				  displayValue="name"
				  placeholder={t("common:select")}
						/>
			  ) : (
						<Multiselect
				  avoidHighlightFirstOption={true}
				  options={clients}
				  selectedValues={selectedClients}
				  onSelect={handleSelect}
				  onRemove={handleRemove}
				  displayValue="name"
				  placeholder={t("common:no_clients")}
						/>
			  )}
				</div>
		  )}
		</div>
	  );
	  
};
export default MultipleSelect;