import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import withAuth from '../hoc/withAuth';

const Landing = () => {
	const history = useHistory();
	useEffect(() => {
		gotoLoginPage();
	  }, []);

	const gotoLoginPage = () => {
		history.push('/login');
	};	
	return null;	
};

export default withAuth(Landing);


// import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { LANDING_VIDEO_URL } from '../common/utility';
// import withAuth from '../hoc/withAuth';

// const Landing = () => {
// 	const history = useHistory();
// 	const [videoStarted, setVideoStarted] = useState<boolean>(false);
// 	const [isMute, setIsMute] = useState<boolean>(true);

// 	const gotoLoginPage = () => {
// 		history.push('/login');
// 	};
	
// 	const autoPlay = () => {
//     	if (document && !videoStarted) {
// 			const video = document.querySelector('video');
// 			// console.log(video);
// 			if (video) {
// 				const playPromise = video.play();
// 				playPromise.then(() => {
// 					console.log('Video Started');
// 					setVideoStarted(true);
// 				}).catch((error) => {
// 					console.log(error);
// 					setIsMute(false);
// 				});
// 			}
// 		}
// 	};

// 	const clickedByUser = () => {
// 		setIsMute(false);
// 		autoPlay();
// 	};

	
// 	return (
// 		<>
// 			<video id='video1' style={{
// 				position: 'absolute',
// 				bottom: 0,
// 				left: 0,
// 				right: 0,
// 				top: 0,
// 				width: '100%',
// 				height: '100%'
// 			}} autoPlay onEnded={gotoLoginPage}
// 			onClick={clickedByUser}
// 			muted = {isMute}>
// 				<source src={LANDING_VIDEO_URL} type="video/mp4" />
//                 Your browser does not support the video tag.
// 			</video>
// 		</>
// 	);
// };

// export default withAuth(Landing);