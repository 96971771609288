// Here we have added our translations

export default {
	welcome: 'Welcome',
	login_note: 'Please login using your account',
	email: 'Email',
	enter_email: 'Enter Email',
	password: 'Password',
	enter_password: 'Enter Password',
	button: 'Remember Me',
	login: 'Login',
	forgot_password: 'Forgot Password',

};

  