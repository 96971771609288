import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from '@material-ui/core';
import { Pagination, Table } from 'reactstrap';
import { IApiCallStatus, IPagination } from '../../common/interfaces';
import { useHistory } from 'react-router-dom';
import InplaceConfirm from '../InplaceConfirm';
import { DEBOUNCE_TIME, showNotification, StandardDateFormat } from '../../common/utility';
import { GetData, PostData } from '../../common/api';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
	addButton: {
		border: 'none',
		width: '120px',
		height: '40px',
		background: '#243a76;',
		borderRadius: '400px',
		marginRight: '7px',
	}
});

let currentPagination = 1;
let distinctUntilChange: any = null;

export const ClientList = () => {

	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	const [clients, setClients] = useState<any[]>([]);
	const [deleteInProgress, setDeleteInProgress] = useState(false);
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
	const [page, setPage] = useState<IPagination>({
		totalItems: clients.length,
		current: 1,
		pageSize: 10,
		pageCount: 0
	});
	const [querySearch, setQuery] = useState('');
	useEffect(()=>{
		setApiStatus({...apiStatus});
		setPage({...page});
		setDeleteInProgress(false);
	},[]);
	const handleSearch = (event: any) => {
		const value = event.target.value;
		if(distinctUntilChange) {
			clearTimeout(distinctUntilChange);
			distinctUntilChange = null;
		}
		distinctUntilChange = setTimeout(() => {
			setQuery(value);
			currentPagination = 1;
			setPage({ ...page, current: currentPagination });
			fetchData(1, value.trim());
		}, DEBOUNCE_TIME);

	};
	const { errors, control, handleSubmit, reset } = useForm({
		mode: 'onBlur'
	});
	useEffect(()=>{
		fetchData(1, '');
	},[]);

	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};
	const fetchData = async (pageNumber = page.current, summary = querySearch) => {
		try {
			changeApiStatus(true, '');

			const statemnts = await GetData(`client/getAll?pageNumber=${pageNumber}&pageSize=${page.pageSize}&filter=${summary}`);
			if (statemnts.status === 200) {
				changeApiStatus(false, '');
				setPage({ ...page, totalItems: statemnts.data.totalItems, total: statemnts.data.total });
				setClients(statemnts.data.items);
			} else {
				throw new Error(statemnts.error);
			}
		}
		catch (err: any) {
			changeApiStatus(false, err.message);
			showNotification('error', err.message);
		}
	};

	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		reset({});
	};
  
	const onSubmit = async(data: any) => {
		// run global try catch and save data via api
		changeApiStatus(true, '');
		try {
			// set id if in editMode
			// editMode && (data.id = params.id);
			// !data.password && (data.password = '');
			const result = await PostData('client/save', { ...data });
			console.log('wolf', result);
			if(result.status === 200){
				changeApiStatus(false, '');
				handleClose();
				setQuery('');
				currentPagination = 1;
				setPage({ ...page, current: currentPagination });
				fetchData(1, '');
			}else{
				throw new Error(result.error);
			}
		}
		catch (error: any) {
			// error found
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}

	};

	const showError = (_fieldName: string) => {
		// if error is there show on the screen
		const error = (errors as any)[_fieldName];
		return error ? (
			<div className="error-block">{error.message || t("common:field_require")}</div>
		) : null;
	};

	return (
		<div className="card-main-custom client-container client-table-container">
			<div className="row header-row">
				<div className="col-md-6 col-lg-7 pl">
					<h2 className="projects-name-count">
						{t("client_list:title")}
					</h2>
				</div>
				<div className="col-8 col-md-3 col-lg-3 search-container" style={{ textAlign: 'end' }}>

					<input className="search-bar" type="text" placeholder={t("client_list:search")} onChange={handleSearch}/>
					<i className="fas fa-search"></i>

				</div>
				<div className="col-4 col-md-3 col-lg-2" style={{ textAlign: 'end' }}>
					<Button type="submit" variant="contained" onClick={handleClickOpen} color="primary" classes={{ containedPrimary: classes.addButton }} data-bs-toggle="modal" data-bs-target="#addClient">
						{t("client_list:add")}
					</Button>
					<Dialog
						fullScreen={fullScreen}
						open={open}
						onClose={handleClose}
						aria-labelledby="responsive-dialog-title"
						className="modal-main"
					>
						<DialogTitle id="responsive-dialog-title" className='modal-header'>
							<h5 className="modal-title" id="exampleModalLabel">{t("client_list:add_client")}</h5>
							<button type="button" className="border-0 btn-close" onClick={handleClose}  data-bs-dismiss="modal" aria-label="Close"> </button>	
						</DialogTitle>
						<form id='client-add-form' onSubmit={handleSubmit(onSubmit)}>
							<DialogContent>
								<DialogContentText>
									<div className="modal-body">
										<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
											<LabelInputWrap 
												label={t("client_list:name")}
												placeholder={t("client_list:enter_name")}
												type='text'
												control={control}
												showError={showError}
												name="name"
												className="border"
												componentName="client"
												disabled={apiStatus.inProgress}
												required={true}
											/>
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<LabelInputWrap 
												label={t("client_list:email")}
												placeholder={t("client_list:enter_email")}
												type='email'
												control={control}
												showError={showError}
												name="email"
												className="border"
												componentName="client"
												disabled={apiStatus.inProgress}
												required={true}
											/>
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<LabelInputWrap 
												label={t("client_list:contact_person")}
												placeholder={t("client_list:contact_person")}
												type='text'
												control={control}
												showError={showError}
												name="contactPerson"
												className="border"
												componentName="client"
												disabled={apiStatus.inProgress}
												required={true}
											/>
										</div>
										<div className="d-flex  justify-content-between w-100 mb-4">
											<LabelInputWrap 
												label={t("client_list:phone")}
												placeholder={t("client_list:phone_no")}
												type='text'
												control={control}
												showError={showError}
												name="phone"
												className="border"
												componentName="client"
												disabled={apiStatus.inProgress}
											/>
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<LabelInputWrap 
												label={t("client_list:client_lead")}
												placeholder={t("client_list:client_lead")}
												type='text'
												control={control}
												showError={showError}
												name="clientLead"
												className="border"
												componentName="client"
												disabled={apiStatus.inProgress}
											/>
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<LabelInputWrap 
												label={t("client_list:ref_no")}
												placeholder={t("client_list:ref_no")}
												type='text'
												control={control}
												showError={showError}
												name="referenceNumber"
												className="border"
												componentName="client"
												disabled={apiStatus.inProgress}
											/>
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<LabelInputWrap 
												label={t("client_list:services")}
												placeholder={t("client_list:services")}
												type='text'
												control={control}
												showError={showError}
												name="services"
												className="border"
												componentName="client"
												disabled={apiStatus.inProgress}
											/>
										</div>
										<div className="d-flex align-items-center justify-content-between w-100 mb-4">
											<LabelInputWrap 
												label={t("client_list:remarks")}
												placeholder={t("client_list:remarks")}
												type='text'
												control={control}
												showError={showError}
												name="remarks"
												className="border"
												componentName="client"
												disabled={apiStatus.inProgress}
											/>
										</div>
									</div>
								</DialogContentText>
							</DialogContent>
							<DialogActions className="modal-footer">
								<Button disabled={apiStatus.inProgress} autoFocus onClick={handleClose} className="btn btn-secondary bg-no">
									{t("client_list:cancel")}
								</Button>
								<Button disabled={apiStatus.inProgress} type="submit" className="btn btn-primary blue-btn" autoFocus>
									{t("client_list:save")}
								</Button>
							</DialogActions>
						</form>
					</Dialog>
				</div>

			</div>
			<div className="card-main-content">
				<TableContainer>
					<Table className={classes.table} aria-label="simple table">
						<TableHead classes={{ root: classes.tableHead }}>
							<TableRow>
								<TableCell align="left">{t("client_list:name")}</TableCell>
								<TableCell align="left">{t("client_list:service")}</TableCell>
								<TableCell align="left">{t("client_list:pf")}
									<label>{t("client_list:update")}</label>
								</TableCell>
								<TableCell align="left">{t("client_list:kyc")}
									<label>{t("client_list:update")}</label>
								</TableCell>
								<TableCell align="left">{t("client_list:industry")}</TableCell>
								<TableCell align="left">{t("client_list:risk")}</TableCell>
								<TableCell align="left">{t("client_list:jobs")}</TableCell>
								<TableCell align="left">{t("client_list:reminder")}</TableCell>
								<TableCell align="left">{t("client_list:contact")}</TableCell>
								<TableCell align="left">{t("client_list:lead")}</TableCell>
								<TableCell align="left">{t("client_list:remarks")}</TableCell>
								<TableCell align="left">{t("client_list:action")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								apiStatus.inProgress ? (
									<TableRow>
										<TableCell colSpan={12} classes={{ root: classes.tableRow }} >
											<div className="text-center">
												{t("client_list:fetch")}
											</div>
										</TableCell>
									</TableRow>
								) : (
									apiStatus.failed ? (
										<TableRow>
											<TableCell colSpan={5} classes={{ root: classes.tableRow }} >
												<div className="text-center text-danger">
													{
														apiStatus.failMessage
													}
												</div>
											</TableCell>
										</TableRow>
									) : (
										clients.length ? (
											clients.slice(((page.current - 1) * page.pageSize), page.current * page.pageSize).map((row, index) => (
												<TableRow key={index} onClick={() => { history.push('/user/clients/' + row._id); }}>
													{/* <TableCell classes={{ root: classes.tableRow }} component="th" scope="row">
                            {row.name}
                          </TableCell> */}
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.name}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.services}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{StandardDateFormat(row.createdAt)}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{StandardDateFormat(row.createdAt)}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{'NA'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{'NA'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{'NA'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{'NA'}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.contactPerson}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.clientLead}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left">{row.remarks}</TableCell>
													<TableCell classes={{ root: classes.tableRow }} align="left" onClick={e => e.stopPropagation()}>
														<InplaceConfirm
															target={'stateDel' + index}
															Action={() => {return undefined;}}
															HTMLComponent={<i className={`${deleteInProgress ? 'fa fa-spinner' : 'fas fa-trash-alt'}`}></i>}
														/>

													</TableCell>
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={12} classes={{ root: classes.tableRow }}>
													<div className="text-center">
														{t("client_list:data")}
													</div>
												</TableCell>
											</TableRow>
										)


									)
								)
							}

						</TableBody>
					</Table>
				</TableContainer>
				<div className="card-pagination">
					<Pagination
						count={(Math.floor(page.totalItems / page.pageSize)) + (page.totalItems % page.pageSize ? 1 : 0)}
						// count={page.pageCount}
					/>
				</div>
			</div>
		</div>
	);
};

const LabelInputWrap = (props:any) => {
	const { t } = useTranslation();
	return <>
		<label htmlFor={props.id}>
			{props.required?<i className="fas fa-asterisk red-start"></i>:null}
			{props.label}
		</label>
		<div className='inp-err-wrap'>
			<Controller
				render={({ onChange, onBlur, value }) => (
					<>
						<input
							id={props.id}
							onBlur={onBlur}
							name={props.name}
							onChange={onChange}
							value={value}
							type={props.type}
							placeholder={props.placeholder}
							className={props.className}
							disabled={props.disabled}
						/>
					</>
				)}
				name={props.name}
				control={props.control}
				rules={{
					required: props.required,
					pattern: {
						value: /[A-Za-z0-9]{1,20}/,
						message: t("common:empty"),
					},
					maxLength: { value: 30, message: t("common:max_length") },
					minLength: { value: 3, message: t("common:min_length") },
				}}
			/>
			{props.showError(props.name)}
		</div>
	</>;
};