import React from 'react';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
const SalesBarChart = (props:any) => {
	const labels=props.items.map(({monthValue}:any)=>monthValue);
	const data=props.activeTab==='1'? props.items.map(({monthlyIncome}:any)=>monthlyIncome):props.items.map(({closingBalance}:any)=>closingBalance);
	// const bgColor=props.countChart.backgroundColor;
	const dataLabel=props.activeTab==='1'? props.items.map(({monthlyIncomeValue}:any)=>monthlyIncomeValue):props.items.map(({closingBalanceValue}:any)=>closingBalanceValue);


	const barData = {
		labels:labels,
		datasets: [
			{
				// label: '',
				backgroundColor: 'rgba(255,99,132,0.2)',
				borderColor: 'rgba(255,99,132,1)',
				borderWidth: 1,
				hoverBackgroundColor: 'rgba(255,99,132,0.4)',
				hoverBorderColor: 'rgba(255,99,132,1)',
				data: data
			}
		]
	};
	// console.log(props,labels,data);
	// props.items.map(({monthValue}:any)=>monthValue)
	return (
		<div style={{height:'30%', width:'70%', marginLeft:'15%', marginTop:'3%'}}>
			<Bar 
				data={barData}
				options={{
					layout:{
						padding:{
							top:30
						}
					},
					
					plugins: {
						
						legend: {
							display: false,
							
						},
						datalabels: {
							anchor: 'end',
							align: 'top',
							clamp:true,
							// clip:true,
							formatter: function(value, context) {
								return dataLabel[context.dataIndex];
							  }
						}
						
						
					},
					scales: {
						y: {
						  beginAtZero: true
						}
					  },
					responsive: true,
					
					
				}}
			/>
		</div>
		
	);
};

export default SalesBarChart;
