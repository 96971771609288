import React from 'react';

const Shareholder = () => {
	return (
		<>
			<div className=" client-table-container tabs-content-main shareholder-container">
				<h1 className="client-content-heading mb-3 mb-md-5">Shareholders</h1>
				<table>
					<thead>
						<tr>
							<th>Full Name</th>
							<th>Shares</th>
							<th>Share Type</th>
							<th>Currency</th>
							<th>Email</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="nodata" colSpan={6}> 

								<i className="fas fa-inbox"></i>
									NO Data
							</td>
						</tr>
					</tbody>
				</table>
				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#addShareholder">
						<i className="fas fa-plus"></i>
                                    Add Shareholder
					</button>
					<div className="modal fade" id="addShareholder"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">Add shareholder</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label><i className="fas fa-asterisk red-start"></i>Shareholder Name:</label>
										<input type="text" placeholder='Enter Shareholder' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Share Type:</label>
										<select name="" id="" className='border'>
											<option value="1"> 1</option>
										</select>
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>email:</label>
										<input type="text" placeholder='Enter Email' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>phone:</label>
										<input type="text" placeholder='Enter Phone' className='border' />
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Shares:</label>
										<input type="text" placeholder='Enter Shares' className='border' />
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Currency:</label>
										<input type="text" placeholder='Enter Phone' className='border' />
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Amount:</label>
										<input type="text" placeholder='Enter Amount' className='border' />
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary blue-btn">Add</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*  */}
			<div className=" client-table-container tabs-content-main shareholder-container  benificial-table">
				<h1 className="client-content-heading mb-3 mb-md-5">Ultimate Beneficial Owners</h1>
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Full Name</th>
							<th>Email</th>
							<th>Nationality</th>
							<th>Address</th>
							<th>Percentage</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="nodata" colSpan={7}> 

								<i className="fas fa-inbox"></i>
									NO Data
							</td>
						</tr>
					</tbody>
				</table>
				<div className="add-btn"> 
					<button type="button" className="border-0" data-bs-toggle="modal" data-bs-target="#beneficialOwner">
						<i className="fas fa-plus"></i>
                                    Add Beneficial Owner
					</button>
					<div className="modal fade" id="beneficialOwner"  aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">Add Beneficial Owner</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i className="fas fa-times"></i></button>
								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center justify-content-between w-100 mb-4 ">
										<label><i className="fas fa-asterisk red-start"></i>Owner Name:</label>
										<input type="text" placeholder='Enter Beneficial Owner' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>email:</label>
										<input type="text" placeholder='Enter Email' className='border' />
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>phone:</label>
										<input type="text" placeholder='Enter Phone' className='border' />
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Id No:</label>
										<input type="text" placeholder='Enter Id' className='border' />
									</div><div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Nationality:</label>
										<input type="text" placeholder='Enter Nationality' className='border' />
									</div>
									<div className="d-flex  justify-content-between w-100 mb-4">
										<label>Address:</label>
										<div className="textarea">
											<textarea placeholder='Enter messages' className='border' ></textarea>
										</div>
									</div>
									<div className="d-flex align-items-center justify-content-between w-100 mb-4">
										<label>Beneficial Ownership:</label>
										<input type="text" placeholder='Enter Ownership percentage' className='border' />
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary bg-no" data-bs-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary blue-btn">Add</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Shareholder;