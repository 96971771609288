import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

interface IState {
	popoverOpen: boolean
	Text: string
  }

class InplaceConfirm extends React.Component<any, IState> {
	constructor (props: any) {
		super(props);
		this.state = {
			Text: this.props.t("common:are_you_sure"),
			popoverOpen: false
		};
	}
  

  toggle = () => {
  	this.setState({
  		...this.state,
  		Text:this.props.t("common:are_you_sure"),
  		popoverOpen: !this.state.popoverOpen
  	});
  }

  render () {
  	return (
  		<>
  			<div id={this.props.target ? this.props.target : 'confirm-popover'} className='popover-confirm'>
  				{this.props.HTMLComponent}
  			</div>
  			<Popover
  				placement='auto'
  				isOpen={this.state.popoverOpen}
  				target={this.props.target ? this.props.target : 'confirm-popover'}
  				style={{zIndex: 999999999}}
  				toggle={this.toggle}
  			>
  				<PopoverBody>
  					<PopoverHeader style={{ background: 'rgb(36, 58, 118)' }}>{this.state.Text}</PopoverHeader>
  					<PopoverBody>
  						<button
  							onClick={() => {
  								this.toggle();
  								this.props.Action();
  							}}
  							className="btn btn-danger"
  						>
  							{this.props.t("common:yes")}
  						</button>
  						<button className="btn btn-secondary" onClick={this.toggle}>{this.props.t("common:no")}</button>
  					</PopoverBody>
  				</PopoverBody>
  			</Popover>
  		</>
  	);
  }
}

export default withTranslation()(InplaceConfirm);
