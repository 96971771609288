import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

interface ICustomDialog {
    isOpen: boolean
    fullScreen: boolean
    handleClose: () => void
    title: string
    children: React.ReactNode,
    actions: React.ReactNode,
    handleSubmit?: (e:any) => void
}

const CustomDialog = ({isOpen, fullScreen, handleClose, title, children, actions, handleSubmit}: ICustomDialog) => {
	const formSubmit = (e:any) => {
		if(handleSubmit){
			handleSubmit(e);
		}
		return false;
	};
	return (
		<Dialog
			fullScreen={fullScreen}
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
			className="modal-main m-0 p-0"
		>
			<DialogTitle id="responsive-dialog-title" className='modal-header'>
				<h5 className="modal-title" id="exampleModalLabel">{title}</h5>
				<button type="button" className="border-0 btn-close" onClick={handleClose}  data-bs-dismiss="modal" aria-label="Close"> </button>	
			</DialogTitle>
			<form id='client-add-form' onSubmit={formSubmit}>
				<DialogContent>
					<DialogContentText>
						<div className="modal-body m-0 p-0">
							{children}
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions className="modal-footer">
					{actions}
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default CustomDialog;