// Here we have added our translations

export default {
	// Table Headers
	particular: '細節',
	cdd: 'CDD &38 KYC',
	shareholders: '股東',
	director: '董事和高階主管',
	key: '關鍵關係',
	docs: '重要文件',

	// Cdd KYC
	kyc: 'KYC 篩選',
	s_no: '編號',
	name: '姓名',
	position: '所持的立場',
	date: '篩選日期',
	output: '輸出',
	observations: '觀察結果',
	pep: 'PEP',
	santions_list: '制裁名單',
	action: '行動',
	client: '客戶',
	add_kyc: '添加 KYC 篩選',
	add_screening: '新增篩選',
	screening_on: '放映於：',
	enter_observations: '輸入觀察結果',
	diligence: '客戶盡職調查',

	// Director Executives
	title: '董事和高階主管',
	id: 'ID',
	full_name: '全名',
	nationality: '國籍',
	address: '地址',
	percent: '百分',
	position_: '位置',
	date_of_app: '預約的日期',
	date_of_res: '辭職日期',
	add_director: '新增導演',
	id_no: '證件號碼:',
	enter_message: '輸入留言',
	select_pos: '選擇職位',
	pos_one: '位置一',
	ownership: '所有權',
	appointed_on: '任命於',
	resigned_on: '辭職日期',

	// common
	cancel: '取消',
	save: '節省',
	data: '沒有數據',
	fetch: '正在獲取...',

};
