import React from 'react';

export const StepFour = () => {
	return (
		<div className="stepper-content">
			<p className="heading">Select the applicable</p>
			<ul>
				<li>
					<label>
						<input type="checkbox"/>
						The Customer set-up or purchase companies or business entities that have no obvious commercial purpose.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						The customer uses Private and correspondent banking.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Unusual/Unclear source of Income.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Non face to face business relationship or transactions.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Customer given any instruction to perform a transaction (which may include cash) anonymously.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						The customer has transferred any funds without the provision of underlying services or transactions.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Large cash payments in which disbursement would have been normally made by other modes of payment like bank transfer, cheque, etc.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						Unaccounted payments received from unknown or un-associated third parties for services and/or transactions provided by the customer/client.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox" />
						Transactions required by the customer is inconsistent with the professional intermediaries’ knowledge on the customer/client’s risk profile and nature of business.
					</label>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
							None
					</label>
				</li>
				<li>
					<button>Add an item</button>
				</li>
				<li>
					<label>
						<input type="checkbox"/>
						<strong>Enhanched DD applicable</strong>
					</label>
				</li>
			</ul>
		</div>
	);
};
