// Define an interface for the language object
interface Language {
    code: string;
    label: string;
  }

export const LANGUAGES: Language[] = [
	{ code: 'en', label: 'English' },
	{ code: 'ch', label: 'Chinese' }
];
