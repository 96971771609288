import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { IApiCallStatus } from '../../common/interfaces';
import InputCtrl from '../../controllers/InpuCtrl';
import { makeStyles } from '@material-ui/core/styles';
import InplaceConfirm from '../../components/InplaceConfirm';
import { useParams, useHistory } from 'react-router-dom';
import { showNotification } from '../../common/utility';
import { DeleteData, GetData, PostData } from '../../common/api';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	track: {
		background: '#fff',
		border: '2px solid #AAA',
		transform: 'scaleY(1.7) scaleX(1.3)',
		opacity: 1
	},
	cancelButton: {
		width: '151px',
		height: '64px',
		background: '#fff',
		borderRadius: '400px',
		color: '#000',
		border: '1px solid #263238',
		marginLeft: '20px',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			backgroundColor: '#FFF!important'
		}
	},
	saveButton: {
		width: '151px',
		height: '64px',
		background: '#263238',
		borderRadius: '400px'
	}
});

const TemplateTypesDetails = () => {

	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	// controls at which state api is
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});

	// for changing api statuses
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

	// react hook form destructing
	const { errors, control, handleSubmit, reset } = useForm({
		mode: 'onBlur'
	});
	const { append, fields, remove } = useFieldArray({ name: 'fields', control });

	const params: any = useParams();
	const [error, setError] = useState<string | boolean>(false);
	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				// check for the id
				if (params.id !== '0') {
					// set edit mode to true
					changeApiStatus(true, '');
					setEditMode(true);
					// run a global try catch and implement the functioning
					const getResult = await GetData('templateTypes/get?id=' + params.id);
					if (getResult.status === 200) {
						const { title, fields } = getResult.data;
						fields.forEach((element: string) => {
							append({ name: element });
						});
						setTimeout(() => {

							reset({ title, fields: fields.map((val: string) => { return { name: val }; }) });
						}, 1000);
						changeApiStatus(false, '');
						// setSelectedTemplates(templates.map((val: any) => { return { label: val.templateName, value: val._id }; }));
					} else {
						throw new Error(getResult.error);
					}
				}
			}
			// check for the errors
			catch (error: any) {
				setError(error.message);
				changeApiStatus(false, error.message);
				showNotification('error', error.message);
			}
		})();
	}, []);
	const showError = (_fieldName: string) => {
		// if error is there show on the screen
		const error = (errors as any)[_fieldName];
		return error ? (
			<div className="error-block">{error.message || t("common:field_require")}</div>
		) : null;
	};
	const onSubmit = async (data: any) => {
		// run global try catch and save data via api
		changeApiStatus(true, '');
		try {
			// set id if in editMode
			editMode && (data.id = params.id);
			if(fields.length === 0) {
				throw new Error('Please enter atleast one field');
			}
			const supplierSave = await PostData('templateTypes/save', { ...data, fields: data.fields.map((val: { name: string }) => val.name) });
			if (supplierSave.status === 200) {
				goBack();
				showNotification('success', supplierSave.message);
			} else {
				throw new Error(supplierSave.error);
			}
		}
		catch (error: any) {
			// error found
			setError(error.message);
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}

	};
	const goBack = () => {
		history.push('/user/template-types');
	};
	const onDelete = async () => {
		// run global try catch and delte resource via api
		try {
			changeApiStatus(true, '');
			const supplierDelete = await DeleteData('templateTypes/delete', params.id);
			if (supplierDelete.status === 200) {
				showNotification('success', supplierDelete.message);
				goBack();
			} else {
				throw new Error(supplierDelete.error);
			}
		}
		catch (error: any) {
			// error found
			setError(error.message);
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
	};
	return <>
		<div className="details-component container">
			<div className="details-header mb-5">
				<h2>
                    Template Types Details
				</h2>
			</div>
			<div className="detail-controls">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-md-6">
							<InputCtrl
								control={control}
								showError={showError}
								type="text"
								placeholder="Enter title"
								name="title"
								required={true}
								className="form-control"
								componentName="login"
								disabled={apiStatus.inProgress}
								float={true}
							/>
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-md-6">
							<label className="mx-4">Fields</label>
							{fields.map((val, i) => {
								return <div key={val.id} className="row">
									{/* <input name={`fields.${i}.name`}
										defaultValue={val.name}
										ref={register({ required: true })} type="text" /> */}
									<div className="col-md-5">
										<InputCtrl
											control={control}
											showError={showError}
											type="text"
											placeholder={`Enter Field ${i + 1}`}
											name={`fields.${i}.name`}
											required={true}
											className="form-control"
											componentName="login"
											disabled={apiStatus.inProgress}
											float={true}
										/>
									</div>
									<i className="fa fa-times col-md-1 text-danger hand" onClick={() => remove(i)}></i>

								</div>;
							})}

							<Button className="mx-4" onClick={() => append({ name: '' })}>Add New Field</Button>
						</div>
					</div>

					{
						error ? (
							<div className="text-danger mb-2">
								{error}
							</div>
						) : null
					}

					<div className="row">
						<div className="col-md-11">
							<Button disabled={apiStatus.inProgress} type="submit" variant="contained" color="primary" classes={{ containedPrimary: classes.saveButton }}>
                                Save
							</Button>
							<Button disabled={apiStatus.inProgress} type="button" onClick={() => history.goBack()} variant="contained" color="secondary" classes={{ containedSecondary: classes.cancelButton }}>
                                Cancel
							</Button>
						</div>
						{
							editMode ? (
								<div className="col-md-1">

									<InplaceConfirm
										HTMLComponent={<img alt="img" id="del-icon" src="/images/Group 1718.png" />}
										Action={onDelete}
										target="del-icon"
									/>
								</div>


							) : null
						}



					</div>

				</form>
			</div>
		</div>
	</>;
};

export default TemplateTypesDetails;
