import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getRoutes } from '../routes';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { RemoveFromLocalStorage, ROLES, UserDataKey, ValueFromUserData } from '../common/utility';
import { useHistory } from 'react-router-dom';
import { Version } from '../common/metadata.json';
import { t } from 'i18next';

const drawerWidth = 245;

const routes = getRoutes();
const adminRoutes = routes.adminRoutes;
const userRoutes = routes.userRoutes;
const cpaAdminRoutes = routes.cpaAdminRoutes;
const bankAdminRoutes = routes.bankAdminRoutes;

const useStyles = makeStyles(() => ({
	drawer: {
		width: drawerWidth,
	},
	drawerPaper: {
		// height: '100vh',
		position: 'absolute',
		top: '75px',
		width: drawerWidth,
		boxShadow: '0px 4px 35px 0px rgb(0 0 0 / 10%)',
		borderRight:'0',
	},
	sideBar: {

	}
}));
//   root: {
//     display: "flex",
//   },
//   appBar: {
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   appBarShift: {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: drawerWidth,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   hide: {
//     display: "none",
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
//   drawerHeader: {
//     display: "flex",
//     alignItems: "center",
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: "flex-end",
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: -drawerWidth,
//   },
//   contentShift: {
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   },


export default function SideBar(props: any) {
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();

	const logout = () => {
		RemoveFromLocalStorage(UserDataKey);
		localStorage.clear();
		history.push('/login');
	};

	const getCurrentRoutes = () => {
		let tempArr:Array<any> = userRoutes;
		const role = ValueFromUserData('role');

		if(role === ROLES.admin) {
			tempArr = adminRoutes;
		} else if([ROLES.CPAAdmin, ROLES.clientAdmin, ROLES.CPAAuditor, ROLES.CPAClerk].includes(role)) {
			tempArr = cpaAdminRoutes;
		} else if([ROLES.bankAdmin,ROLES.bankTeamMember].includes(role)) {
			tempArr = bankAdminRoutes;
		} else {
			tempArr = userRoutes;  
		}

		return tempArr;
	};

	return (
		<div className="projects">

			<div className="sideNavBar">

				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="left"
					open={props.open}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					{/* <Divider /> */}
					{/* <ListTextAndIcons /> */}
					{/* <Divider /> */}
					<List>
						{(getCurrentRoutes()).filter(val => !val.hideInSidebar).map((adminRoute, index) => {
							return (
								<ListItem
									button
									key={index}
									component={Link}
									to={adminRoute.path}
									selected={location.pathname.includes(adminRoute.path) && !adminRoute.disabled}
									classes={{ selected: 'list-item-selected' }}
									disabled={adminRoute.disabled}
								>
									{/* <ListItemIcon></ListItemIcon> */}
									{/* <img alt="img" src={adminRoute.icon} /> */}
									{!adminRoute.disabled?<div className="icon"><i className={adminRoute.icon}></i></div>:null}  <ListItemText primary={adminRoute.name} />
								</ListItem>
							);
						})}

						<ListItem
							component={Button}
							onClick={logout}
						>
							{/* <ListItemIcon></ListItemIcon> */}
							<img alt="img" src="/images/Vector.svg" />
							<ListItemText primary={t("common:logout")} style={{width:'auto', whiteSpace:'nowrap' }} />
						</ListItem>
					</List>
				</Drawer>
				<div className="sidebar-bottom">

					<div className="app-version">
						{t("common:version")}: {Version}
					</div>
				</div>
			</div>

		</div>
	);
}
