import React from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { IColumn, ICustomTable } from '../common/interfaces';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	table: {
	    minWidth: 650,
		whiteSpace: 'nowrap'
	},
	tableHead: {
		fontWeight: 'bold',
		fontSize: '15px'
	},
	tableRow: {
		fontSize: '14px',
		padding: '16px',
		color: '#444444',
		cursor: 'pointer'
	},
	tableRowRed: {
		fontSize: '14px',
		padding: '16px',
		color: '#FF0000',
		cursor: 'pointer'
	},
});

const CustomTable = ({ apiStatus, columns, rows, page, emptyMessage, onRowClick }: ICustomTable) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<TableContainer>
			<Table className={classes.table} aria-label="simple table">
				<TableHead classes={{ root: classes.tableHead }}>
					<TableRow>
						{columns.map((col: IColumn, idx: number) => {
							return <TableCell key={idx} align={idx ? 'center' : 'left'}>{col.header}</TableCell>;
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						apiStatus.inProgress ?
							(<TableRow>
								<TableCell colSpan={columns.length} classes={{ root: classes.tableRow }} >
									<div className="text-center">
										{t("common:fetch")}
									</div>
								</TableCell>
							</TableRow>
							) : (
								apiStatus.failed ? (
									<TableRow>
										<TableCell colSpan={columns.length} classes={{ root: classes.tableRow }} >
											<div className="text-center text-danger">
												{
													apiStatus.failMessage
												}
											</div>
										</TableCell>
									</TableRow>
								) : (
									rows.length ? (
										rows.slice(((page.current - 1) * page.pageSize), page.current * page.pageSize).map((row, index) => (
											<TableRow key={index} className={onRowClick ? 'hand' : ''} onClick={() => {
												if (onRowClick) {
													onRowClick(row);
												}
											}} >
												{columns.map((col: IColumn, idx: number) => {
													return <TableCell key={idx} classes={{ root: classes.tableRow }} align={idx ? 'center' : 'left'}>{col.isIndex && index + 1} {col.render ? col.render(row, col.accessor) : row[col.accessor]}</TableCell>;
												})}
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell colSpan={columns.length} classes={{ root: classes.tableRow }}>
												<div className="text-center">
													{emptyMessage ? emptyMessage : t("common:no_data")}
												</div>
											</TableCell>
										</TableRow>
									)
								)
							)
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CustomTable;