import React from 'react';
import { useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
interface TTableProps {
    columns: Array<any>;
    data: Array<any>;
    onRow?: (id: string, data: any, colName: string) => void;
    marginPaddingClass?: string;
    emptyMessage?: string | React.ReactNode;

}

const Table = ({ columns, data, onRow, marginPaddingClass = 'my-4 py-4', emptyMessage = 'Not Found' }: TTableProps) => {
	const { t } = useTranslation(); 
	// Use the useTable Hook to send the columns and data to build the table
	const {
		getTableProps, // table props from react-table
		getTableBodyProps, // table body props from react-table
		headerGroups, // headerGroups, if your table has groupings
		rows, // rows for the table based on the data passed
		prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
	} = useTable({
		columns,
		data
	});

	return (
		<div className="table-responsive">
			<table className='table table-hover align-middle text-nowrap' {...getTableProps()}>
				<thead className='table-light'>
					{headerGroups.map((headerGroup: any, idx: number) => (
						<tr key={idx} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column: any, idx2: number) => (
								<th key={idx2} {...column.getHeaderProps()} style={{ width: '150px' }}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row: any, i) => {
						prepareRow(row);
						return (
							<tr key={i} className={`d-table-row ${onRow ? 'hand' : ''} `} {...row.getRowProps()}>
								{row.cells.map((cell: any, idx: number) => {
									const tempKey = cell.getCellProps().key || '';
									let key = '';
									if(tempKey) {
										key = tempKey.substring(tempKey.lastIndexOf('_') + 1);
									}
									return <td key={idx} onClick={() => {
										if(onRow) {
											onRow(row.original._id || row.original.id, row.original, key);
										}
									}} className={`${marginPaddingClass}`} {...cell.getCellProps()} style={{ width: '150px' }}>{cell.render('Cell')}</td>;
								})}
							</tr>
						);
					})}
					{!rows.length && <tr className={`${marginPaddingClass} text-center`}>
						<td colSpan={columns.length} >
							{t(`tabs_view:messages.${emptyMessage}`)}
						</td>
					</tr>}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
