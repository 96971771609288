// Here we have added our translations

export default {
	// Table Headers
	name: 'Name',
	service: 'Service',
	pf: 'PF',
	update: 'Last update on',
	kyc: 'KYC/CDD',
	industry: 'Industry',
	risk: 'Risk',
	jobs: 'Recent Jobs',
	reminder: 'Reminder',
	contact: 'Contact',
	lead: 'Lead', 
	remarks: 'Remarks',
	action: 'Action',
	add: 'Add',
	add_client: 'Add Clients',

	// title
	title: 'Clients',
	total: 'Total',
	title_add: 'Add Client',
	title_edit: 'Edit Client',
	search: 'Search',

	// Forms Data
	email: 'Email',
	contact_person: 'Contact Person',
	phone: 'Phone',
	client_lead: 'Client Lead',
	ref_no: 'Reference Number',
	services: 'Services',
	enter_name: 'Enter Name',
	enter_email: 'Enter Email',
	phone_no: 'Phone Number',
	password: 'Password',
	enter_password: 'Enter Password',
	save: 'Save',
	cancel: 'Cancel',

	// common
	fetch: 'fetching...',
	data: 'No Data',
	all: 'All',

};
