import React from 'react';

export const StepFive = () => {
	return (
		<div className="stepper-content step-five import-doc-container">
			<p className="heading">Select the applicable</p>
			<ul>
				<li>
					<p>Based on the factors identified, rate the client risk level.</p>
					<span>
						<label>
							<input type="checkbox"/>
						High
						</label>
						<label>
							<input type="checkbox"/>
							Medium
						</label>
						<label>
							<input type="checkbox"/>
						Low
						</label>
					</span>
				</li>
				<li>
					<p>Based on the initial understanding, decide the nature of the client.</p>
					<span>
						<label>
							<input type="checkbox"/>
						Complex
						</label>
						<label>
							<input type="checkbox"/>
							Non-Complex
						</label>
					</span>
				</li>
				<li className='no-flex'>
					<p>
						Justification
						<label>
							<input type="text" className="text-type"/>
						</label>

					</p>
				</li>
				<li className='no-flex'>
					<div className="doc-main">
						<h2>Attachments</h2>
						<div className="file-bg">
							<input type="file" className="hand" />
							<p>
								<i className="fas fa-plus"></i>
						    Upload File
							</p>
						</div>
					</div>
				</li>
			</ul>
			<div>
			</div>
		</div>
	);
};
