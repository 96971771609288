import { Button, Input } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { GetData, PostData } from '../common/api';
import { IApiCallStatus, IMappedFields } from '../common/interfaces';
import { dismissNotification, showNotification } from '../common/utility';
import { useHistory } from 'react-router-dom';

const ApTransactionSidebar: React.FC<{ file: any, templateTypeId: string, fields: IMappedFields[], newField: boolean, onChange: any, onDelete: any, supplierId: string, anchorChange: any }> = ({ fields, newField, onChange, supplierId, templateTypeId, anchorChange, file }) => {
	const [custom, setCustom] = useState(false);
	const [name, setName] = useState('');

	const [templateName, setTemplateName] = useState('');
	const [templateTypeKeys, setTemplateTypeKeys] = useState<{
		title: string,
		_id: string,
		fields: {
			selected: boolean,
			value: string
		}[]
	}>({
		title: '',
		_id: templateTypeId,
		fields: []
	});
	const [choosen, setChoosen] = useState<number | null>(null);
	const commit = (proceed: boolean, name: string) => {
		if (proceed) {

			if (name.length < 3) {
				return showNotification('error', 'Mapped field Name should be atleast 3 chars long');
			}
			if (proceed && name.length < 3) {
				return;
			}
		}
		setCustom(false);
		onChange(proceed, { ...fields[fields.length - 1] }, name);
		setName('');
		setChoosen(null);
	};
	useEffect(() => {
		if (choosen !== null) {
			const cloned = { ...templateTypeKeys };
			const name = cloned.fields[choosen].value;
			cloned.fields[choosen].selected = true;
			setTemplateTypeKeys(cloned);
			commit(true, name);
		}
	}, [choosen]);
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});

	// for changing api statuses
	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};
	const onAnchorChange = async (index: number) => {
		if (anchorValue.length < 3) {
			return setError({ ...error, anchor: 'Anchor name should be atleast 3 chars long' });
		} else if (anchorValue.includes(' ')) {
			return setError({ ...error, anchor: 'Spaces aren\'t allowed in anchor point' });
		} else {
			setError({ ...error, anchor: '' });
		}
		// check if anchor exists
		const notId = 'anchNot';
		try {
			const form = new FormData();
			form.append('file', file);
			form.append('anchorName', anchorValue);
			showNotification('info', 'Processing your request', notId);
			const dataRes = await PostData('utility/vision/imageOCR', form);
			if (dataRes.status === 200) {
				dismissNotification(notId);
				anchorChange(anchorValue, dataRes.data, index);
				setAnchorValue('');
			} else {
				throw new Error(dataRes.error);
			}
		}
		catch (error: any) {
			dismissNotification(notId);
			showNotification('error', error.message);
		}
	};
	const [anchorValue, setAnchorValue] = useState('');
	useEffect(() => {
		(async () => {
			try {
				const templateTypeResponse = await GetData(`templateTypes/get?id=${templateTypeId}`);
				if (templateTypeResponse.status === 200) {
					setTemplateTypeKeys({ ...templateTypeResponse.data, fields: templateTypeResponse.data.fields.map((val: string) => { return { selected: false, value: val }; }) });
					console.log(templateTypeKeys);

				} else {
					throw new Error(templateTypeResponse.error);
				}
			}
			catch (err: any) {
				showNotification('error', err.message);
			}
		})();
	}, []);
	const history = useHistory();
	const saveTemplate = async () => {
		const templatesaveUID = 'saveTemplate';
		setError({ ...error, name: '' });
		try {
			if (templateName.length < 3) {
				return setError({ ...error, name: 'Template name should be atleast 3 chars long' });
			}
			// check if all templateType fields are choosen atleast
			if (templateTypeKeys.fields.some(val => !val.selected)) {
				throw new Error(`Please select all the fields from your choosen template: ${templateTypeKeys.title}`);
			}
			changeApiStatus(true, '');
			showNotification('info', 'Processing request...', templatesaveUID);

			// get canvas width n height
			const canvas = document.querySelector('canvas.react-pdf__Page__canvas');
			const templateRes = await PostData('templates/save', {
				templateName, coordinates: fields,
				supplierId,
				templateTypeId,
				containerWidth: canvas?.scrollWidth,
				containerHeight: canvas?.scrollHeight
			});
			if (templateRes.status === 200) {
				changeApiStatus(false, '');
				dismissNotification(templatesaveUID);
				showNotification('success', templateRes.message);
				history.push('/user/suppliers/' + supplierId);
			} else {
				throw new Error(templateRes.error);
			}
		}
		catch (error: any) {
			dismissNotification(templatesaveUID);
			changeApiStatus(false, error.message);
			showNotification('error', error.message);
		}
	};
	const [error, setError] = useState({ name: '', anchor: '' });
	return <div className="transaction-sidebar">
		<div className="template-main">
			<h4>Template Details</h4>
			{/* <input  /> */}
			<Input disabled={apiStatus.inProgress} onChange={(e) => { setTemplateName(e.target.value); setError({ ...error, name: '' }); }} type="text" placeholder="Template name" />
			{error.name && <div className="text-danger">
				{error.name}
			</div>}
		</div>
		<hr />
		<h4>Selected area field properties</h4>
		{fields.length === 0 && 'No Fields available'}
		<div className="transaction-fields">
			{newField ? (custom ? <div className="transaction-field">
				<Input disabled={apiStatus.inProgress} value={name} onChange={e => setName(e.target.value)} type="text" placeholder="Enter Mapped field name" />
				<div className="transaction-field-coordinate">
					<b>X</b>:
					<span>{fields[fields.length - 1].positionX}</span>
					<b>Y</b>:
					<span>{fields[fields.length - 1].positionY}</span>
					<b>Width</b>:
					<span>{fields[fields.length - 1].width}</span>
					<b>Height</b>:
					<span>{fields[fields.length - 1].height}</span>
					<b>Page</b>:
					<span>{fields[fields.length - 1].page}</span>
				</div>
				<div className="form-actions">
					<Button variant="contained" onClick={() => commit(true, name)}>Save</Button>
					<Button variant="contained" onClick={() => commit(false, '')}>Cancel</Button>
				</div>
			</div> : <div>
				{/* choose from a list of available type */}
				<ul className="ng-ml-3">
					<p>Please choose a name for field</p>
					{
						templateTypeKeys.fields.map((val, index) => {
							return val.selected ? null : <li key={index} onClick={() => setChoosen(index)}>
								{val.value}
							</li>;
						})
						// <div>
						// 	No fields left to choose from. We can save the template now.
						// 	<Button variant="contained" onClick={() => commit(false, '')}>Okay</Button>
						// </div>
					}
					<li onClick={() => setCustom(true)}>Custom</li>
				</ul>
			</div>)
				: fields.map((val, index) => {
					return <div key={index} className="transaction-field">
						<Input disabled={apiStatus.inProgress} value={val.mappedFieldName} readOnly={true} type="text" placeholder="mapped field name" />
						<div className="transaction-field-coordinate">
							<b>X</b>: <span>{val.positionX}</span>
							<b>Y</b>: <span>{val.positionY}</span>
							<b>Width</b>: <span>{val.width}</span>
							<b>Height</b>: <span>{val.height}</span>
							{
								val.anchorName ? <><b>Anchor Name</b>: <span>{val.anchorName}</span>
									<b>Anchor X</b>: <span>{val.anchorCoordinates ? val.anchorCoordinates.positionX : 'N/A'}</span>
									<b>Anchor Y</b>: <span>{val.anchorCoordinates ? val.anchorCoordinates.positionY: 'N/A'}</span>
								</> : <>
									<b>Anchor Point</b>
									<div className="row">
										<div className="col-md-8">
											<input placeholder="Enter anchor" type="text" onChange={(e) => setAnchorValue(e.target.value)} />
										</div>
										<div className="col-md-3">
											<Button onClick={() => onAnchorChange(index)}>Add</Button></div>
									</div>
								</>
							}
						</div>
					</div>;
				})
			}
		</div>
		{error.anchor && <div className="text-danger">
			{error.anchor}
		</div>}
		<div className="sidebar-action">
			<Button disabled={apiStatus.inProgress || !!error.name} onClick={saveTemplate} variant="contained">Save Template</Button>
		</div>
	</div>;
};
export default ApTransactionSidebar;