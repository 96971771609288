// Here we have added our translations

export default {
	// Table Headers
	s_no: '序號', // Serial Number
	name: '名稱',
	email: '電子郵件',
	added: '添加於', // Added On --- Google Translate: 'Added to'

	// title
	title: '銀行用戶',
	total: '總計',
	title_add: '新增銀行用戶', // Title to add Bank User --- Google Translate: 'Add new bank user'
	title_edit: '編輯銀行用戶', // Title to edit Bank User
	search: '搜尋',
	add: '新增', // Add --- Google Translate: 'New'
	all: '全部',

	// Forms Data
	password: '密碼',
	bank: '銀行',
	enter_name: '輸入名稱',
	enter_email: '輸入電子郵件',
	enter_password: '輸入密碼',
	select: '請選擇',
	assign_clients: '分配客戶',
	save: '保存', // Save --- Google Translate: 'Keep'
	cancel: '取消',

	// common
	fetch: '獲取中...', // fetching... --- Google Translate: 'Retrieving...'
	data: '無數據',

};
